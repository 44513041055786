import React from 'react'
import NavDropdown from 'react-bootstrap/NavDropdown'

export const NavDropdowns = (props) => {
  const { children } = props
  return(
    <NavDropdown {...props}>
      {children}
    </NavDropdown >
  )
}

export const NavDropdownItems = (props) => {
  const { children } = props
  return(
    <NavDropdown.Item {...props}>
      {children}
    </NavDropdown.Item >
  )
}

// export default NavDropdowns
