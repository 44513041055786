const eng_lang = {
  journeyTemplateWidget:{
    journeyTemplateLabel: 'Journey Template',
    getWidgetConfigurationError: 'Failed to retrieve widget configuration',
    problemStatement: 'Kindly answer few questions about the problem you are facing',
    serviceRequestProgress: 'Service Request Submission in Progress...',
    uploading: 'Uploading...',
  },
  service: {
    viewCommunication:"Messages",
    newService:"New Service Request",
    cancelledServiceError:"Service Request is cancelled.",
    searchServiceError: 'Failed to search services.',
    searchService: 'Search a service:',
    selectedService: 'Selected service: ',
    serviceRequestNo: 'Service Request No: ',
    serviceArea:"Service Area",
    serviceOffer:"Service Offered by Shops",
    matchServices:"Matching Services :",
    serviceName:"Service Name",
    action:"Action",
  },
  chat: {
    chatLabel: 'Chat',
    completeChatFailure: 'Failed to complete Chat.',
    completeChatSuccess: 'Chat completed Successfully!',
    updateCustomerChatSuccess: 'Customer Chat Info updated Successfully!',
    updateCustomerChatFailure: 'Failed to update Customer Chat Info.',
    userHeartbitLost: 'Failed to connect to the user.',
    getFailed: 'Failed to retrieve Customer Chat record',
    updateHeartbitFailed: 'Failed to update Customer Heartbit',
    tryAgain:"Try again",
    attachmentUploadInProgress : 'Upload is in Progress',
    videoNotSupported: "Video Upload Not Supported",
    blankMessage:"Enter Text To Send Message",
  },
  emailOptInPreferance: {
    changeEmailOptInPreferanceFailure: 'Please contact the Shop to Unsubscribe or customize your communication Preferences',
    optInTitleText: 'Thank you for resubscribing.',
    optInDetailText: " Further communication will be sent to ",
    optOutTitleText: "We’re sorry to see you go. You have successfully been unsubscribed.",
    optOutDetailText: 'has been successfully Unsubscribed from BidClips.',
    infoForMistake: "Have you come here by mistake? Continue to",
    subscribeAgain: "Subscribe Again",
    subscribed: "Subscribed",
    unsubscribed: "Unsubscribed",
  },
  common:{
    seeHowitWorks : 'See how it works',
	  breadcrumbList: 'List',
    addButtonLabel: 'Add',
    cancelButtonLabel: 'Cancel',
    helpLabel: 'Help',
    tryNowLabel: 'Try Now',
    backToListPageLabel: 'Back To List Page',
    isRequired : '{0} is required',
    networkError : 'Network Error',
    deleteConfirmationMessage: 'Are you sure you want to delete?',
    saveButtonLabel: 'Save',
    helpSectionLabel : 'Help Section',
    submitButtonLabel: 'Submit',
    changeButtonLabel: 'Change',
    poweredBy: 'Powered by:',
    lessThan2CharError: 'Enter atleast 2 characters',
    noMatchError: "No services found.",
    noImagePresent : "Image not Present",
    total : "Total",
    available : "Available",
    serviceRequestFor: "Service Request for",
    viewServiceRequest: "View Service Request",
    messages: "Messages",
    popularService :"Our Popular Services",
    moreFromShop : "More from our Partners",
    noQuoteAvailable:'There are no active bids available at this time. Please contact the shop for further information.'
  },
  job:{
    getJobConfigurationError:'Faild to create job',
    getBidConfigurationError:'Faild to get responseData',
    btnReserved: 'Job Has Been Reserved',
    linkCommunication: 'View All Communication',
    linkViewBid: 'View Bid Request',
    btnToReserve: 'Reserve',
    btnToReservePay: 'Reserve and Pay',
    btnToReserveNoSlot: 'Select Slot To Reserve',
    reserveNow: "Reserve Now",
  },
  shop:{
    btnSelect:"Select",
    providerNotFound:"No provider found in zip code offering this service.",
    selectService: "Please Select Service"
  },
  quote:{
    noLongerAvailable:"This bid is no longer available.",
    additionalBid:"Additional Bids",
    retrivingYourBid:"Retrieving Your Bid",
    laborChargesTitle:"Labor Charges",
    partAndLaborsTitle:"Parts, Labor & Other",
    partAndLaborsSubtitle: "Review the items required for your service",
    pleaseReviewYourServiceDetails: "Please review your service details",
    serviceBreakdown: "Service Breakdown",
    partsDataValidation: "Parts Data Not Available",
    laborDataValidation:"Labor Data Not Available",
    addonTitle: "Addons & Upgrades",
    addonSubTitle: "Choose additional options and upgrades for your service",
    customerDetailsTitle: "Customer Details",
    customerDetailsSubTitle: "Please review your details for this service",
    termsConditionTitle: "Terms & Conditions",
    termsConditionSubTitle: "Review applicable terms and conditions",
    aboutServiceTitle: "About this Service",
    aboutServiceSubTitle: "Learn more about what to expect during your service",
    aboutShopTitle: "About Our Shop",
    aboutShopSubTitle: "Learn more about our shop",
    slotCapacityFull:"Full",
    installedPrice:"Service Price",
    price:"Price",
    otherCharges:"Other Charges",
    restockCautionPart1: "Special Order Items: in case of cancellation restocking fees of",
    restockCautionPart2: "will be charged",
    serviceLocationShop: "At Shop",
    serviceLocationCustomer: "At My Location",
    jobReserved:"Job has been reserved",
    schedulingMessage : "Schedule Message",
    availableStartDate:"Available Slot From Date:",
    Schedule : "Schedule",
    scheduleAson: "Schedule for:",
    scheduleAt: "Schedule at:",
    noSchedule:"Not Scheduled",
    paymentStatus: "Payment Status:",
    paidAmount: "Paid Amount:",
    outstandingAmount: "Outstanding Amount:",
    totalAmount: "Total Amount:",
    serviceRequest: "Service Request:",
    locationAt: "Location at:",
    Phone: "Phone:",
    serviceLocation: "Service Location:",
    serviceWait: "Wait",
    serviceDropOff: "Drop-Off",
    scheduled : "Scheduled",
    serviceTotal: "Service Total",
    reservedTotal: "Reserved Total",
    partLaborSubtotal: 'Subtotal',
    calculatingTax : "Calculating Tax",
    items: "Items",
    summarizedPrice: "summarized Price" 
  },
  billingsummary:{
    billingsummary:"Billing Summary",
    breakDown:"Breakdown",
    InsuranceAmount:"Insurance BID Amount",
    partCharges:"Part Charges",
    discounts:"Discounts",
    addons:"Add-ons",
    travelSurcharge: "Travel Surcharge",
    tax:"Taxes",
    taxIncluded:"Tax Included",
    total:"Total",
  },
  insurance:{
     basePrice:"Select Insurance",
     withOutIns:"Without Insurance",
     withIns:"With Insurance",
     chooseOption:"Select one of above option to reserve slot",
     InsurancClaim:"*With insurance claim",
  },
  payment : {
    error : "Something went wrong",
    skiplink : "Skip Payment",
    downPayment: "Down Payment of ",
    fullPayment: "Full Payment of ",
    zip: "Zipcode",
    state: "State",
    city: "City",
    address: "Address",
    serviceAddress: "Same as service address",
    billingAddress: "Billing Address",
    country: "Country",
    ammountPaid: "Amount Previously Paid",
  },
  schedule : {
    locationSelection: "Location Selection",
  },
  job_fail_slotNotAvailable:"Slot Not available",
  job_success_slotsAvailable: "Available Slots",
  job_fail_unknown:"Oops... Something went wrong",
};

export default eng_lang;
