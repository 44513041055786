import bcApi from '../../lib/bidclips';
import {
    SET_CONTACT_LINK,
    SET_CONTACT_ALLOW_2_EDIT
} from '../action-types';

const addContactLink = data => ({
    type: SET_CONTACT_LINK,
    data
});

export const setAllow2Edit = (isAllow) => ({
    type: SET_CONTACT_ALLOW_2_EDIT,
    isAllow
})

export const getContactLinkById = (contactLinkId) => dispatch => {
    return bcApi.bidclips.contact_link
        .getById(contactLinkId)
        .then(({ data }) => {
            dispatch(addContactLink(data));
            return data;
        })
}