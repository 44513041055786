import { combineReducers, createReducer } from '../utils';
import {
    JOURNEYTEMPLATE_GET_ERRORED,
    JOURNEYTEMPLATE_GET_SUCCESS,
  } from '../action-types';

  const data = createReducer( [], {
    [JOURNEYTEMPLATE_GET_ERRORED ]: ( state, action  ) => [],
    [JOURNEYTEMPLATE_GET_SUCCESS]:(state,{journeyResponse}) => journeyResponse
    
  });

  const record = combineReducers({
    data,
  });
  
  export default combineReducers( {
    record,
  } );