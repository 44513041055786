/**
 * Internal dependencies
 */
import { combineReducers, createReducer } from '../utils';
import {
  COMMUNICATIONS_GET_SUCCESS,
  COMMUNICATIONS_GET_ERRORED,
  COMMUNICATIONS_PATCH_ERRORED,
  COMMUNICATIONS_MSG_GET_SUCCESS,
 } from '../action-types';

// import { concat as _concat } from "lodash";

const items = createReducer( [], {
  [ COMMUNICATIONS_GET_ERRORED ]: ( state, action  ) => [],
  [ COMMUNICATIONS_GET_SUCCESS ]: ( state, {items}  ) => {
    return items
  },
} ); 

//bid_customer_message

const Msg = createReducer( [], {
  [ COMMUNICATIONS_GET_ERRORED ]: ( state, action  ) => [],
  [ COMMUNICATIONS_MSG_GET_SUCCESS ]: ( state, {Msg}  ) => {
    return Msg
  },
} ); 

const recordErrorMessage = createReducer(null, {
  [COMMUNICATIONS_PATCH_ERRORED]: (state, { errorMessage }) => errorMessage,
});

const list = combineReducers({
  items,
  Msg,
  errorMessage: recordErrorMessage,
});

export default combineReducers( {
  list,
});