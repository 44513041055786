import { dataURItoBlob } from "@bidclips/react-jsonschema-form/lib/utils";
import axios from "axios";
import { push } from "connected-react-router";
import qs from "qs";
import { formatPhoneNumber } from "../../containers/phone_change_formate";
/**
 * Internal dependencies
 */
import {
  SET_HEADER_VISIBLE,
  SET_FOOTER_VISIBLE,
  SET_MENU_VISIBLE,
  JTWIDGET_CLEAR_RECORD,
  JTWIDGET_GET_SUCCESS,
  JTWIDGET_GET_ERRORED,
  WINDOW_STATE_CHANGE,
  WIDGET_OPERATION,
  WIDGET_ID_CHANGE,
  SERVICE_NAME_CHANGE,
  SERVICE_ID_CHANGE,
  FORM_DATA_CHANGED,
  FORM_SET_STEP_INDEX,
  FORM_SET_SAVE_ERROR,
  FORM_SET_BID_REQUEST_REFERENCE,
  FORM_ERRORS_CHANGED,
  SEARCH_SERVICE_SUCCESS,
  PROMOTE_SERVICE_SUCCESS,
  WIDGET_CRUD_ERRORED,
  JOURNEY_TEMPLATE_GET_SUCCESS,
  //URL_PARAMETERS_CHANGE,
  BID_CREATED_SUCCESS,
  BID_UPDATE_S3_UPLOAD_SUCCESS,
  BID_UPDATE_SUCCESS,
  BID_UPLOAD_PROGRESS,
  BID_PENDING_PROMISES,
  BID_UPLOAD_PROGRESSES_DATA,
  BID_PATCH_PROMISES,
  DEV_MODE_CHANGE,
  SET_SHOW_WINDOW_SIZE_BUTTON,
  SET_NATIVE_MODE, 
  SET_CUSTOMER_GEO_LOCATION,
  SET_MULTIPLE_SERVICES,
  SET_CALL_HREF,
  STORE_DEVICE_PLATFORM,
  WIDGET_ENABLE_UI,
  CLEAR_JT_ELEMENTS,
  GET_JOURNEY_TEMPLATE_UI_SCHEMA_ELEMENTS_SUCCESS,
  AWSS3_UPLOAD_ERROR,
  STORE_AUTH_TOKEN,
  SET_LOCALSTORAGE_READ_4_PROPERTY,
  CLEAR_WIDGET_ELEMENTS,
  SHOP_SERVICE_DATA,
  SERVICE_DATA,
  HANDLE_NAVIGATION_INDEX,
  SET_SHOW_HOW_TO,
  SET_SHOW_SERVICE_AREA_SEARCH,
  SET_MAIN_SEARCH_AREA,
  SET_PROMOTED_SERVICE_AREA,
  SET_WIDGET_CUSTOM_MESSAGE_VISIBLE,
  SET_WIDGET_CUSTOM_MESSAGE,
  SET_WIDGET_PROMOTED_SERVICE_VIEW,
  SET_WIDGET_PROMOTED_SERVICE_TITLE_VISIBLE,
  SET_WIDGET_MAIN_SEARCH_TITLE_VISIBLE,
  SET_WIDGET_ON_OPEN_OPERATIONS,
  SET_WIDGET_PROMOTED_SHOP_AREA_VISIBLE,
  SET_WIDGET_PROMOTED_SHOP_TITLE_VISIBLE,
  SET_WIDGET_CAROSEL_SPEED_IN_MILLIS,
  SET_SERVICE_REQUEST_IMAGE_URL_AND_KEY,
  CLEAR_SERVICE_REQUEST_IMAGE_URL_AND_KEY,
} from "../action-types";
import history from "../../history";

import bcApi from "../../lib/bidclips/index";
import { uploadBidAttachmentToS3PresignedUrl } from "../../lib/aws/s3.upload";
import localString from "../../lang/lang";
import {
  cloneDeep as _cloneDeep,
  set as _set,
  find as _find,
  get as _get,
  map as _map,
  findIndex as _findIndex,
  forEach as _forEach,
  isEmpty as _isEmpty,
  each as _each,
  keys as _keys,
  isString as _isString,
  isObject as _isObject,
  startsWith as _startsWith,
  omit as _omit,
  isEqual as _isEqual,
  isUndefined as _isUndefined,
  size as _size,
  sortBy as _sortBy,
  isBoolean as _isBoolean,
  // uniqBy as _uniqBy,
   filter as _filter,
   assign as _assign,
   pick as _pick,
   trim as _trim,
   includes as _includes,
   split as _split,
   slice as _slice,
   join as _join,
} from "lodash";
import mime from "mime-types";
import { BID_REF_LEAD_SOURCE, WIDGET_VERSION } from "../../const/dbValue";
import randomGenerateServiceRequestRefNo from "@bidclips/service_request_ref_generator";
import { getSuccess as getBidSuccess, updateBidPatchStatus } from "../bid/actions";
import { trimWithLowerCharacter } from "../utils";

const setLocalStoragePendingReadMap = (localStoragePendingReadMap) => ({
  type: SET_LOCALSTORAGE_READ_4_PROPERTY,
  localStoragePendingReadMap
});

const setCustomMessage = (customMessage) => ({
  type: SET_WIDGET_CUSTOM_MESSAGE,
  customMessage
});

const setPromotedServiceView = (promotedServiceView) => ({
  type: SET_WIDGET_PROMOTED_SERVICE_VIEW,
  promotedServiceView
});

const isCustomMessageVisible = (isCustomMessageVisible) => ({
  type: SET_WIDGET_CUSTOM_MESSAGE_VISIBLE,
  isCustomMessageVisible
});

const isPromotedServiceTitleVisible = (isPromotedServiceTitleVisible) => ({
  type: SET_WIDGET_PROMOTED_SERVICE_TITLE_VISIBLE,
  isPromotedServiceTitleVisible
});

const isMainSearchAreaTitleVisible = (isMainSearchAreaTitleVisible) => ({
  type: SET_WIDGET_MAIN_SEARCH_TITLE_VISIBLE,
  isMainSearchAreaTitleVisible
});

const setPromotedShopAreaVisible = (isPromotedShopAreaVisible) => ({
  type: SET_WIDGET_PROMOTED_SHOP_AREA_VISIBLE,
  isPromotedShopAreaVisible
});

const setPromotedShopTitleVisible = (isPromotedShopTitleVisible) => ({
  type: SET_WIDGET_PROMOTED_SHOP_TITLE_VISIBLE,
  isPromotedShopTitleVisible
});

const setCaroselSpeedInMillis = (caroselSpeedInMillis) => ({
  type: SET_WIDGET_CAROSEL_SPEED_IN_MILLIS,
  caroselSpeedInMillis
})

export const onOpenWidgetOperation = (onOpenWidgetOperation) => ({
  type: SET_WIDGET_ON_OPEN_OPERATIONS,
  onOpenWidgetOperation
});



const setHeaderVisible = (isHeaderVisible) => ({
  type: SET_HEADER_VISIBLE,
  isHeaderVisible
});
const setFooterVisible = (isFooterVisible) => ({
  type: SET_FOOTER_VISIBLE,
  isFooterVisible
});
const setMenuVisible = (isMenuVisible) => ({
  type: SET_MENU_VISIBLE,
  isMenuVisible
});

const doClearRecord = () => ({
  type: JTWIDGET_CLEAR_RECORD
});

const jtWidgetgetError = errorMessage => ({
  type: JTWIDGET_GET_ERRORED,
  errorMessage
});

const jtWidgetgetSuccess = response => ({
  type: JTWIDGET_GET_SUCCESS,
  items: response,
  data: response
});

const bidPatchPromises = patchPromises => ({
  type : BID_PATCH_PROMISES,
  patchPromises
})

export const clearReduxStateImageAndKeyURL = response => ({
  type : CLEAR_SERVICE_REQUEST_IMAGE_URL_AND_KEY,
})

const setImageAndKeyURL = response => ({
  type : SET_SERVICE_REQUEST_IMAGE_URL_AND_KEY,
  response
})

const removeWithAddClassName = (removeClassName, addClassName) => {
  const element = document.querySelector(`.${removeClassName}`);
  if (!_isEmpty(element)) {
    const { classList } = element;
    classList.add(addClassName);
    classList.remove(removeClassName);
  }
}

let widgetAutoResizerId, lastScrollHeight;
const autoResizer = (isStart = true) => {
  console.log("widget autoResizer start :", isStart, widgetAutoResizerId);
  if (isStart) {
    if (widgetAutoResizerId === null || widgetAutoResizerId === undefined) {
      widgetAutoResizerId = setInterval(() => {
        const currScrollHeight = document.body.offsetHeight + 20;
        if (lastScrollHeight !== currScrollHeight) {
          console.log('scroll height changed - curr : ', currScrollHeight, " past : ", lastScrollHeight);
          lastScrollHeight = currScrollHeight;
          removeWithAddClassName("communication-scroller", "communication");
          removeWithAddClassName("annotation-media", "anotation");
          removeWithAddClassName("quote-wrapper", "quote");
          postWidgetEvent("onHeightChange", `${lastScrollHeight}px`);
        }
      }, 500);
    }
  } else {
    if (widgetAutoResizerId !== null || widgetAutoResizerId !== undefined) clearInterval(widgetAutoResizerId);
    widgetAutoResizerId = null;
  }

}

export const setViewOnExpand = (configs = [], isShow) => dispatch => _forEach(configs, config => {
  switch (config) {
    case "windowSizeButton":
      dispatch(setShowWindowSizeButton(isShow));
      break;
    case "header":
      dispatch(setHeaderVisible(isShow));
      break;
    case "footer":
      dispatch(setFooterVisible(isShow));
      break;
    case "menu":
      dispatch(setMenuVisible(isShow));
      break;
    case "howTo":
      dispatch(setShowHowTo(isShow));
      break;
    case "serviceAreaSearch":
      dispatch(setShowServiceAreaSearch(isShow));
      break;
    case "mainSearchArea":
      dispatch(setShowMainSearchArea(isShow));
      break;
    case "mainSearchTitle":
      dispatch(isMainSearchAreaTitleVisible(isShow));
      break;
    case "promotedServiceArea":
      dispatch(setShowPromotedServiceArea(isShow));
      break;
    case "promotedServiceTitle":
      dispatch(isPromotedServiceTitleVisible(isShow));
      break;
    case "customMessage":
      dispatch(isCustomMessageVisible(isShow));
      break;
    case "partnerPromotedShopArea":
      dispatch(setPromotedShopAreaVisible(isShow));
      break;
    case "partnerPromotedShopTitle":
      dispatch(setPromotedShopTitleVisible(isShow));
      break;
    default:
      break;
  }
})

export const doWidgetOperation = config => {
  return (dispatch, getStore) => {
    console.log("doWidgetOperation :: ", config);
    if(config === undefined || config === null) return;
    let response = null;

    dispatch(saveWidgetOperation(config));
    switch (config.operation) {
      case "onHeightChange" : 
        autoResizer(config.config);
        break;
      case "searchShopService":
        const searchKey = config.config;
        const reduxStore = getStore();
        let widgetId = _get(reduxStore, "widget.widgetId", null);
        response = searchForServiceWithoutDispatch(widgetId, searchKey);
        break;
      case "widget":
        // check and maintaint window custom size if it is provide
        response = dispatch(doOperationOnWidget(config.config));
        break;

      case "hashChangeReloadEvent":        
        console.log("hashChangeReloadEvent", window, window.origin);
        window.location.reload();
        break;

      case "navigation":
        response = dispatch(
          doNavigationOnWidget(config.config, config.callbackMethodName)
        );
        break;

      case "setShowWindowSizeButton":
          if(_isBoolean(config.config.setShowWindowSizeButton)){
            dispatch(setShowWindowSizeButton(config.config.setShowWindowSizeButton));
            response= "success";
          }
          else{
            response= "failed";
          }
          break;

      case "setNativeMode":
          if(_isBoolean(config.config.isNativeMode)){
            dispatch(setNativeMode(config.config.isNativeMode));
            dispatch(setShowWindowSizeButton(!config.config.isNativeMode));
            response= "success";
          }
          else{
            response= "failed";
          }
          break;

      case "setCustomerGeoLocation" : 
          dispatch(setCustomerGeoLocation(config.config));
          response= "success";
          break;

      case "enableUI" :
          response = dispatch(setOperationForEnableUI(true));
          break;

      case "setHeader":
        if(_isBoolean(config.config.setHeader)) {
          dispatch(setHeaderVisible(config.config.setHeader));
          response= "success";
        } else {
          response= "failed";
        }
        break;
      case "setFooter":
        if(_isBoolean(config.config.setFooter)) {
          dispatch(setFooterVisible(config.config.setFooter));
          response= "success";
        } else {
          response= "failed";
        }
        break;
      case "setMenu":
        if(_isBoolean(config.config.setMenu)) {
          dispatch(setMenuVisible(config.config.setMenu));
          response= "success";
        } else {
          response= "failed";
        }
        break;
      case "setHowTo":
        if(_isBoolean(config.config.setHowTo)){
          dispatch(setShowHowTo(config.config.setHowTo));
          response= "success";
        } else{
          response= "failed";
        }
        break;
      case "setServiceAreaSearch":
        if(_isBoolean(config.config.setServiceAreaSearch)){
          dispatch(setShowServiceAreaSearch(config.config.setServiceAreaSearch));
          response= "success";
        } else{
          response= "failed";
        }
        break;
      case "setMainSearchArea" :
        if(_isBoolean(config.config.setMainSearchArea)){
          dispatch(setShowMainSearchArea(config.config.setMainSearchArea));
          response= "success";
        } else{
          response= "failed";
        }
        break;
      case "setPromotedServiceArea" :
        if(_isBoolean(config.config.setPromotedServiceArea)){
          dispatch(setShowPromotedServiceArea(config.config.setPromotedServiceArea));
          response= "success";
        } else{
          response= "failed";
        }
        break;
      case 'shop_gtm' : 
        const gtmConfigurationElement = document.createElement("script");
        gtmConfigurationElement.async = true;
        gtmConfigurationElement.src = `https://www.googletagmanager.com/gtm.js?id=${_get(config,"config.gtm_id","")}`
        console.log('gtmConfigurationElement :: checking :', gtmConfigurationElement);
        document.head.append(gtmConfigurationElement); 
        
        const gtmConfigurationElementNoScript = document.createElement("noscript");
        const gtmConfigurationElementIframe = document.createElement("iframe");
        gtmConfigurationElementIframe.async = true;
        gtmConfigurationElementIframe.src = `https://www.googletagmanager.com/gtm.js?id=${_get(config,"config.gtm_id","")}`
        gtmConfigurationElementIframe.height = '0'
        gtmConfigurationElementIframe.width = '0'
        gtmConfigurationElementIframe.style = 'display:none;visibility:hidden'
        gtmConfigurationElementNoScript.appendChild(gtmConfigurationElementIframe)
        document.body.append(gtmConfigurationElementNoScript)
        break;
      default:
        break;
    }
    pushWidgetMessage("bc_operationCallaback", ({
      callbackMethodName: config.callbackMethodName,
      response
    }));
  };
};

export const postWidgetEvent = (eventName, eventData) => 
    pushWidgetMessage("bc_operationCallaback", ({
      callbackMethodName: "_bc_callback_" + eventName,
      response: eventData
    }));

export const doNavigationOnWidget = (config) => {
  console.group("doNavigationOnWidget");
  console.log("config :", config);

  let route;
  switch (config.event) {
    case "service":
      // implement from routes.js
      route = "/";
      break;

    default:
      route = config.event;
      break;
  }
  history.push(route);

  console.groupEnd("doNavigationOnWidget");

  return {
    type: "WIDGET_NAVIGATION",
    config
  };
};

export const handleCustomWidgetArgs = (args = {}) => dispatch => {
  const { promotedServiceView, customMessage, hide = [], show = [], caroselSpeedInMillis } = args;
  hide && dispatch(setViewOnExpand(hide, false));
  show && dispatch(setViewOnExpand(show, true));
  promotedServiceView && dispatch(setPromotedServiceView(promotedServiceView));
  customMessage && dispatch(setCustomMessage(customMessage));
  caroselSpeedInMillis && dispatch(setCaroselSpeedInMillis(caroselSpeedInMillis));
}

export const doOperationOnWidget = (config) => {
  console.log("doOperationOnWidget :", config);
  return (dispatch) => {
    switch (config.event) {
      case "open":
        return dispatch(setWindowState("open"));
      case "expand":
        return dispatch(setWindowState("expand"));
      case "hide":
        return dispatch(setWindowState("hide"));
      case "customStyle":
        return dispatch(setWindowState("customStyle"));
      case "customHeight":

        const { initial, event, style, onOpen } = config;
        dispatch(handleCustomWidgetArgs(initial));
        dispatch(onOpenWidgetOperation(onOpen));

        return dispatch(setWindowState(event, style));
      default:
        return dispatch(setWindowState("minimize"));
    }
  };
};

export const setOperationForEnableUI = (value) => {
  return dispatch => {
    dispatch(setEnableUISuccess(value));
  }
}

export const saveWidgetOperation = config => {
  return {
    type: WIDGET_OPERATION,
    config
  };
};

export const pushWidgetMessage = (type, value) => {
  console.log("pushWidgetMessage : type :: ", type, " :: value ::", value);

  if(value.response != null && (value.response instanceof Promise)){
    console.log("Waiting for promise to be resolved....");
    value.response.then((returnValue) => {
      console.log("promise to be resolved, posting message....");
      value.response = returnValue;
      window.parent && window.parent.postMessage({ type, value }, '*');
    });
  }
  else{
    console.log("posting message directly....");
    window.parent && window.parent.postMessage({ type, value }, '*');
  }
};

export const setDevMode = args => {
  console.log(
    "setDevMode ::  ",
    args
  );

  return {
    type: DEV_MODE_CHANGE,
    args 
  };
};

export const setWindowState = (windowState, windowStyle) => {
  console.log("sending window state message to parent");
  window.parent && window.parent.postMessage(
    {
      type: "bci_setWindowState",
      value: windowState,
      style: windowStyle
    }, '*'
  );
  return {
    type: WINDOW_STATE_CHANGE,
    windowState
  };
};

export const setEnableUISuccess = value => ({
  type: WIDGET_ENABLE_UI,
  value
});

export const setWidgetId = widgetId => ({
  type: WIDGET_ID_CHANGE,
  widgetId
});

export const setSelectedServiceName = serviceName => ({
  type: SERVICE_NAME_CHANGE,
  serviceName
});

export const setServiceDetails = serviceDetails => ({
  type: SERVICE_ID_CHANGE,
  serviceDetails
});

export const shopServiceData = shopServiceData => ({
  type: SHOP_SERVICE_DATA,
  shopServiceData
})

export const setFormData = formData => ({
  type: FORM_DATA_CHANGED,
  formData
});

export const setFormErrors = formErrors => ({
  type: FORM_ERRORS_CHANGED,
  formErrors
});

export const setStepIndex = stepIndex => ({
  type: FORM_SET_STEP_INDEX,
  stepIndex
});

export const setFormSaveError = errorMessage => ({
  type: FORM_SET_SAVE_ERROR,
  errorMessage
});

export const serviceInfo = serviceInfo => ({
  type: SERVICE_DATA,
  serviceInfo
})

export const setBidRequestReferenceNumber = bidRequestReferenceNumber => ({
  type: FORM_SET_BID_REQUEST_REFERENCE,
  bidRequestReferenceNumber
});

// export const setURLParameters = (urlParameters) =>({
//   type : URL_PARAMETERS_CHANGE,
//   urlParameters,
// });

const crudError = errorMessage => ({
  type: WIDGET_CRUD_ERRORED,
  errorMessage
});

const searchServiceSuccess = matchedServices => ({
  type: SEARCH_SERVICE_SUCCESS,
  matchedServices
});

const searchPromotedServiceSuccess = promotedServices =>({
  type: PROMOTE_SERVICE_SUCCESS,
  promotedServices
});

export const getJourneyTemplateSuccess = (uiSchema, id, version) => ({
  type: JOURNEY_TEMPLATE_GET_SUCCESS,
  id,
  version,
  uiSchema
});

export const getJourneyTemplateUISchemaElementsSuccess = journeyTemplateUISchemaElements => ({
    type: GET_JOURNEY_TEMPLATE_UI_SCHEMA_ELEMENTS_SUCCESS,
    journeyTemplateUISchemaElements,
});

export const bidCreateSuccess = bidId => ({
  type: BID_CREATED_SUCCESS,
  bidId: bidId
});

export const bidUpdateS3UploadSuccess = bidData => ({
  type: BID_UPDATE_S3_UPLOAD_SUCCESS,
  bidData
});

export const bidUpdateSuccess = bidData => ({
  type: BID_UPDATE_SUCCESS,
  bidData
});

export const bidProgressCount = ({ values, reset = false }) => ({
  type: BID_UPLOAD_PROGRESS,
  values,
  reset
});

export const bidPendingPromises = promises => ({
  type: BID_PENDING_PROMISES,
  promises
});

export const s3UploadProgresses = (name, value) => ({
  type: BID_UPLOAD_PROGRESSES_DATA,
  name,
  value
});

export const setShowWindowSizeButton = (isShowButton) => ({
  type: SET_SHOW_WINDOW_SIZE_BUTTON,
  isShowButton
});

export const setShowHowTo = (isShowHowTo) => ({
  type: SET_SHOW_HOW_TO,
  isShowHowTo
});

export const setShowServiceAreaSearch = (showServiceAreaSearch) => ({
  type: SET_SHOW_SERVICE_AREA_SEARCH,
  showServiceAreaSearch
});

export const setShowMainSearchArea = (showMainSearchArea) => ({
  type: SET_MAIN_SEARCH_AREA,
  showMainSearchArea
});

export const setShowPromotedServiceArea = (showPromotedServiceArea) => ({
  type: SET_PROMOTED_SERVICE_AREA,
  showPromotedServiceArea
});


export const setNativeMode = (isNativeModeOn) => ({
  type: SET_NATIVE_MODE,
  isNativeModeOn
});

export const storeCallHref = callHref => ({
  type: SET_CALL_HREF,
  callHref
})

export const storeDevicePlatform = platform =>({
  type: STORE_DEVICE_PLATFORM,
  platform
})

export const setCustomerGeoLocation = (geoLocation) => ({
  type: SET_CUSTOMER_GEO_LOCATION,
  geoLocation
});

export const duplicateServices = services => ({
  type: SET_MULTIPLE_SERVICES,
  services
});

export const awsS3UploadError = (uploadError) => ({
  type : AWSS3_UPLOAD_ERROR,
  uploadError
});


const saveAuthenticationToken = authToken => ({
  type: STORE_AUTH_TOKEN,
  authToken
})


export const updateEtagOfBid = (eTag) => (dispatch, getStore) => {
  let bidData = _get(getStore(),"bid.record.data",{});
  bidData = _assign(bidData,{_etag : {$oid : eTag}});
  dispatch(getBidSuccess(bidData));
  dispatch(updateBidPatchStatus(false));
}
export const updateBid = (bidId, bidObj = {}, afterBidUpdateActions = [], prevFormData) => {
  return async (dispatch, getStore) => {
    const store = getStore();
    const isPatchInProgress = _get(store,"bid.record.bidPatchInProgress",false);
    delete bidObj.contact_created;
    
    console.log('store :: updateBid :',isPatchInProgress,store,bidId,bidObj);

    if(!_isEmpty(_get(bidObj,"form_data.bcCustomerPhone"))) {
      bidObj.form_data.bcCustomerPhone = formatPhoneNumber(_get(bidObj,"form_data.bcCustomerPhone"));
    }

    if(isPatchInProgress){
      return new Promise((resolve,reject) =>  setTimeout( () => {
        return dispatch(updateBid(bidId, bidObj,afterBidUpdateActions, prevFormData)).then(res=>resolve(res)).catch(err=>reject(err));
      },500));
    }else {
      dispatch(updateBidPatchStatus(true));
      console.log("check updateBid bidObj prevFormDataprevFormData", bidObj, prevFormData, _isEqual(bidObj.form_data, prevFormData));
      if(!_isEqual(bidObj.form_data, prevFormData) && (bidObj.status === "incomplete" || bidObj.status === "draft")) {
        bidObj["customer_form_edit_datetime"] = {"$date": new Date()}
      }
      
      if(!_isEmpty(_get(bidObj,"form_data.bcCustomerEmail"))) {
        bidObj.form_data.bcCustomerEmail = trimWithLowerCharacter(_get(bidObj,"form_data.bcCustomerEmail"));
      }
      if(!_isEmpty(_get(store,"widget.setImageAndKeyURL",[]))) {
        _assign(bidObj.form_data,setS3UrlToDataImage(bidObj.form_data ,_get(store,"widget.setImageAndKeyURL",[])))
      }
      bidObj.update_from = 'normal patch';
      bidObj.previous_etag = bidObj._etag;
      // console.log('updateBid before patch data : ', bidId,bidObj,afterBidUpdateActions);
      return  bcApi.bidclips.bid.patch(bidId,bidObj).then(res => {
        // console.log('res :: after patch data res', res)
        dispatch(updateEtagOfBid(_get(res,"headers.etag",null)));
        _forEach(afterBidUpdateActions, action => {
           dispatch(action);
        });
        return res;
      }).catch(err => {
        console.log('err in bid patch ', err);
      });
    }
  }
}

export const getPromotedShop = (widgetId) => {
  return dispatch =>{
    return bcApi.bidclips.widget_configuration
      .getPromotedShopByWidgetId(widgetId)
      .then(response=>{
        console.log("response From getPromotedShop ::",response);
        const data=_get(response,"data._embedded",[]);
        let promotedShops=setPromotedService(data)
        return promotedShops
      })
      .catch(error => {
        console.warn("Error at searchForService : ", error);
        dispatch(crudError(localString.service.searchServiceError));
      });
  }
}
export const getPromotedService = (widgetId) => {
  return dispatch =>{
    return bcApi.bidclips.widget_configuration
      .getPromotedServiceByWidgetId(widgetId)
      .then(response=>{
        console.log("Response from getByPromotedSerivce ::",response);
        const data = _get(response, "data._embedded", []);
        let promotedServices=setPromotedService(data);
        dispatch(searchPromotedServiceSuccess(promotedServices));

        return promotedServices
      })
      .catch(error => {
        console.warn("Error at searchForService : ", error);
        dispatch(crudError(localString.service.searchServiceError));
      });
  }
}
const setPromotedService = (data) =>{
    let promotedServices = [{}];
    let sname = "";
    let simage = "";
    let shopServiceId = "";
    let serviceId = "";
    if (_size(data) >= 1) {
      for (let i = 0; i < data.length; i++) {
        promotedServices[i] = {};
        sname = _get(data[i], "service.name", "");
        simage= _get(data[i], "service.service_image", "");
        if(_isEmpty(simage)){
          simage=_get(data[i],"shop_detail.upload_image","")
          if(_isEmpty(simage)){
            simage=_get(data[i],"provider.organization_logo","")
          }
        }
        serviceId = _get(data[i], "service._id", "");
        shopServiceId = _get(data[i], "shop_services._id", "");
        promotedServices[i]["service_name"] = sname;
        promotedServices[i]["service_image"] = simage;
        promotedServices[i]["service_id"] = serviceId;
        promotedServices[i]["shop_service_id"] = shopServiceId;
        promotedServices[i]["display_service_name"] = sname;
        promotedServices[i]["priority"] = 'high';
        promotedServices[i]["promoted_order"] = _get(data[i], "shop_services.promoted_order");

        const addressComponents = _get(data[i],'shop_detail.shop_address.gmaps.address_components');
        const postalCode = _filter(addressComponents,item => _get(item,'types.0') === 'postal_code')
        console.log('postalCode::',postalCode,'addressComponents',addressComponents)
        promotedServices[i]["shop_detail"] = {};
        promotedServices[i].shop_detail.shop_name = _get(data[i],'shop_detail.shop_name');
        promotedServices[i].shop_detail.shop_id = _get(data[i],'shop_detail._id');
        promotedServices[i].shop_detail.default_service_area = _get(data[i],'shop_detail.default_service_area');
        promotedServices[i].shop_detail.upload_image = _get(data[i],'shop_detail.upload_image');
        promotedServices[i].shop_detail.postal_code = _get(postalCode,'0.short_name');
        promotedServices[i].shop_detail.shop_address = _get(data[i],'shop_detail.shop_address.gmaps.formatted_address')
        promotedServices[i].shop_detail.default_widget_url = _get(data[i],'shop_detail.default_widget_url','')
        promotedServices[i]["journey_template"] = _get(
          data[i],
          "journeyTemplate.nonEmergencyJourneyTemplate[0]",
          {}
        );
      }
      console.log('Updated promotedServices ',promotedServices);
    }
    return promotedServices
}

export const searchForServiceWithoutDispatch = (widgetId, searchTerm) => {
  if (_isUndefined(widgetId) || _isEmpty(widgetId)) {
    return;
  }
  let partsOfStr = searchTerm.split(" ");
  partsOfStr = _filter(partsOfStr, (str) => str.length > 1);
  return bcApi.bidclips.widget_configuration
    .searchService(widgetId, searchTerm, partsOfStr)
    .then(response => {
      console.log(
        "DAta we are fatching in a ::response Object::  ",
        response
      );
      const data = _get(response, "data._embedded", []);
      let matchedServices = [{}];
      let sname = "";
      let shopServiceId = "";
      let serviceId = "";
      let keywords = [];
      if (_size(data) >= 1) {
        console.log("The value of :: data In action ::", data);
        console.log(
          "The value of :: data's length In action ::",
          data.length
        );
        for (let i = 0; i < data.length; i++) {
          matchedServices[i] = {};
          sname = _get(data[i], "service.name", "");
          serviceId = _get(data[i], "service._id", "");
          shopServiceId = _get(data[i], "shop_services._id", "");
          console.log("The value of Sname at the time of defination..", sname);
          matchedServices[i]["service_name"] = sname;
          matchedServices[i]["service_id"] = serviceId;
          matchedServices[i]["shop_service_id"] = shopServiceId;
          if (sname.search(new RegExp(searchTerm, "i")) !== -1) {
            console.log("" + searchTerm + " : matched with Service Name1 : ", sname);
            matchedServices[i]["display_service_name"] = sname;
            matchedServices[i]["priority"] = 'high';
          } else {
            keywords = data[i].service.keywords;
            console.log("The length of keyword", keywords.length);
            console.log(
              "The value of KeyWOrds in side:::ACTION::FOR::",
              keywords,
              "of sERVICE NAME",
              sname
            );
            /* eslint-disable array-callback-return, no-loop-func */

            partsOfStr.map(part => {
              if (part.search(new RegExp(sname, "i"))) {
                matchedServices[i]["display_service_name"] = sname;
                console.log(
                  "" + part + " :: matched with Service Name2 : ",
                  sname
                );
              }
            });

            partsOfStr.map((partFStrcurrentvalue, index1) => {
              keywords.map((keyWcurrentvalue, index) => {
                const newpartFStrcurrentvalue = partFStrcurrentvalue + ".*";
                if (
                  keyWcurrentvalue.search(
                    new RegExp(newpartFStrcurrentvalue, "i")
                  ) !== -1 &&
                  newpartFStrcurrentvalue !== ".*"
                ) {
                  console.log(
                    "INSIDE MAP  " +
                    partFStrcurrentvalue +
                    " : matched with Keyword : ",
                    keyWcurrentvalue
                  );
                  matchedServices[i]["display_service_name"] =sname;
                    // `${sname} (${keyWcurrentvalue})`;
                  matchedServices[i]["shop_service_id"] = shopServiceId;
                  matchedServices[i]["priority"] = 'low';
                }
              });
            });
            console.log(
              "The valur of search result BEFOR:: shorting::",
              matchedServices
            );
          }
          const addressComponents = _get(data[i], 'shop_detail.shop_address.gmaps.address_components');
          const postalCode = _filter(addressComponents, item => _get(item, 'types.0') === 'postal_code')
          console.log('postalCode::', postalCode, 'addressComponents', addressComponents)
          matchedServices[i]["shop_detail"] = {};
          matchedServices[i].shop_detail.shop_name = _get(data[i], 'shop_detail.shop_name');
          matchedServices[i].shop_detail.shop_id = _get(data[i], 'shop_detail._id');
          matchedServices[i].shop_detail.default_service_area = _get(data[i], 'shop_detail.default_service_area');
          matchedServices[i].shop_detail.upload_image = _get(data[i], 'shop_detail.upload_image');
          matchedServices[i].shop_detail.postal_code = _get(postalCode, '0.short_name');
          matchedServices[i].shop_detail.shop_address = _get(data[i], 'shop_detail.shop_address.gmaps.formatted_address')
          matchedServices[i]["journey_template"] = _get(
            data[i],
            "journeyTemplate.nonEmergencyJourneyTemplate[0]",
            {}
          );
          matchedServices = _sortBy(matchedServices, ['priority']);

          console.log('matchedServices at last::', matchedServices);

        }
      }
      return JSON.parse(JSON.stringify(matchedServices));
    })

}

export const searchForService = (widgetId, searchTerm) => {
  if (_isUndefined(widgetId) || _isEmpty(widgetId)) {
    return;
  }
  return dispatch => {
    return searchForServiceWithoutDispatch(widgetId, searchTerm)
      .then((respose) => {
        dispatch(searchServiceSuccess(respose));
        return respose;
      })
      .catch(error => {
        console.warn("Error at searchForService : ", error);
        dispatch(crudError(localString.service.searchServiceError));
      });
  };
};
export const widgetActionTracking = async (widgetActionObj) => {
  try {
      const response = await bcApi.bidclips.widgetActionTracking.post(widgetActionObj);
      return response;
  } catch (error) {
      console.error("Error in widgetActionTracking:", error);
      throw error; 
  }
};

export const getById = widgetid => {
  return dispatch => {
    if (widgetid !== "" && undefined !== widgetid) {
      return bcApi.bidclips.widget_configuration
        .getById(widgetid)
        .then(response => {
          dispatch(jtWidgetgetSuccess(response.data)); // either data._embedded[0] is returned or null
          console.log("response.data::", response);
        })
        .catch(httpError => {
          console.log("httpError : ", httpError);
          dispatch(
            jtWidgetgetError(
              localString.journeyTemplateWidget.getWidgetConfigurationError
            )
          );
        });
    }
  };
};

export const getShopServiceById = shopServiceId => {
  console.log("getByShopService calling");
  return dispatch => {
    return bcApi.bidclips.shop_service
      .getShopServiceById(shopServiceId)
      .then(resp => {
        console.log("getByShopService response", resp);
        dispatch(setServiceDetails(resp.data._embedded[0]));
        return resp.data;
      }).catch(httpError => {
        console.log("httpError at getShopServiceById : ", httpError);
        dispatch(setServiceDetails(null));
        return httpError;
      });
  };
};

export const getShopServiceDetails = shopServiceId => {
  console.log('ShopServiceDeatils for About Service');
  return dispatch => {
    return bcApi.bidclips.shop_service.getById(shopServiceId).then(response => {
      console.log('About-service response', response.data);
      dispatch(shopServiceData(response.data))
      return response.data
    })
  }
}

export const getServiceByName = (widgetId, servicename) => {
  console.log("In getServiceByName ...", widgetId, servicename);
  if (
    widgetId !== "" &&
    undefined !== widgetId &&
    servicename !== "" &&
    undefined !== servicename
  ) {
    return dispatch => {
      return bcApi.bidclips.widget_configuration
        .getByServiceName(widgetId, servicename)
        .then(response => {
          let responseVar = _get(response, "data._embedded", null);
          console.log("getByServiceName response length: ", responseVar.length);
          console.log("getByServiceName response: ", responseVar);
          let responseValue = {};
          if (responseVar.length == null || responseVar.length === 0) {
            responseValue = { response: null, message: "No service found" };
          } else if (responseVar.length > 1) {
            responseValue = {
              response: null,
              message:
                "More than 1 service found, please enter exact service name"
            };
          } else if (responseVar.length === 1) {
            let journeyTemplate = _get(
              responseVar,
              "[0].shop_services.nonEmergencyJourneyTemplate.detail[0]",
              null
            );
            console.log("journey templates: ", journeyTemplate);
            responseValue = {
              message:
                "Success: 1 service found, returning service_id for this service name",
              widget_configuration_id: _get(responseVar, "[0]._id", null),
              shop_id: _get(responseVar, "[0].associated_shops._id", null),
              shop_name: _get(
                responseVar,
                "[0].associated_shops.shop_name",
                null
              ),
              shop_service_id: _get(responseVar, "[0].shop_services._id", null),
              service_id: _get(
                responseVar,
                "[0].shop_services.service_id",
                null
              ),
              service_name: _get(
                responseVar,
                "[0].shop_services.service_name",
                null
              ),
              provider_id: _get(responseVar, "[0].provider._id", null),
              journeyTemplate_ui_schema: journeyTemplate
            };
          }
          dispatch(setServiceDetails(responseValue));
          return Promise.resolve(responseValue);
        })
        .catch(httpError => {
          console.log("httpError : ", httpError);
          dispatch(setServiceDetails(null));
          return Promise.reject(httpError);
        });
    };
  }
};

/**
 * Clear Record state
 */
export const clearRecord = () => {
  return dispatch => {
    console.log("clearRecord...");
    dispatch(doClearRecord());
  };
};

const performPostUploadUpdateToBid = (uploadStatus, bidId, requestFormData) => {
  return dispatch => {
    //see if all uploadStatus are done...
   if (_findIndex(uploadStatus, { uploadDone: false }) === -1) {
     console.log(
       "all uploads are done... (or failed with 3 retries) ",
       uploadStatus
     );
 
     //This is last upload success - go ahead and patch the record...
     return dispatch(updateBid(bidId, { form_data: requestFormData }));
   }
  } 
};

const uploadImage = (
  widgetId,
  bidId,
  requestFormData,
  uploadStatus,
  uploadIndex,
  bidData,
  key
) => {
  bcApi.bidclips.aws.s3
    .bidAttachment(widgetId, bidId, uploadStatus[uploadIndex].formData)
    .then(response => {
      // patch file path in mongodb
      console.log("uploadhotspotImages to s3 response: ", response);

      //update bidData of given element
      bidData[key] = _get(
        response,
        "data[0].metadata.userMetadata.fullFilePath",
        null
      );

      //update uploadIndex to true (for done)
      uploadStatus[uploadIndex].uploadDone = true;
      performPostUploadUpdateToBid(uploadStatus, bidId, requestFormData);
    })
    .catch(httpError => {
      //try again until 3 try and then give up...
      console.log("httpError: ", httpError);
      if (uploadStatus[uploadIndex].retryCount <= 3) {
        uploadStatus[uploadIndex].retryCount++;
        uploadImage(
          widgetId,
          bidId,
          requestFormData,
          uploadStatus,
          uploadIndex,
          bidData,
          key
        );
      } else {
        //give up
        uploadStatus[uploadIndex].uploadDone = true;
        performPostUploadUpdateToBid(uploadStatus, bidId, requestFormData);
      }
    });
};

/**
 * Convert all the format : data-image content to  URL
 * @param [] uploadStatus objects perserving upload Status { id, promiseStatus, retryCount }
 * @param String widgetId
 * @param String bidId
 * @param {*} schemaDef JSON Schema
 * @param {*} formData Data
 */
const uploadDataImageFormData = (
  uploadStatus,
  widgetId,
  bidId,
  requestFormData,
  schemaDef,
  bidData
) => {
  //if bidData is undefined or null no point in processing schema
  if (bidData === undefined || bidData == null) {
    return;
  }
  console.log("uploadDataImageFormData : Will process ", schemaDef, bidData);

  //iterate through all properties
  _forEach(schemaDef.properties, (value, key) => {
    //see if this property is of "type": "string" && "format": "data-image"
    if (
      _get(value, "type", null) === "string" &&
      _get(value, "format", null) === "data-image"
    ) {
      //upload image
      let imageData = _get(bidData, key);
      console.log(
        "Checking to upload image for : ",
        key,
        " with value : ",
        imageData
      );

      if (imageData === undefined || imageData === null) {
        //nothing to do just move forward...
        return;
      } else {
        console.log(
          "uploadhotspotImages: ",
          " widgetId: ",
          widgetId,
          " bidId: ",
          bidId
        );
        let fileBlob = dataURItoBlob(imageData);
        let formData = new FormData();
        let imgName = fileBlob.name;
        if (imgName === "unknown") {
          imgName = "photoCapture_" + new Date().getTime() + ".png";
        }
        imgName = key + "_" + imgName;
        formData.append("file", fileBlob.blob, imgName);

        //keep track of upload Index to update status....
        let uploadIndex = uploadStatus.push({
          uploadDone: false,
          formData,
          retryCount: 0
        });
        uploadIndex--;
        console.log("Added uploadIndex : ", uploadIndex, uploadStatus);
        uploadImage(
          widgetId,
          bidId,
          requestFormData,
          uploadStatus,
          uploadIndex,
          bidData,
          key
        );
      }
    }

    //see if this object type - in that case nest again...
    if (_get(value, "type", null) === "object") {
      //evaluate nested properties
      uploadDataImageFormData(
        uploadStatus,
        widgetId,
        bidId,
        requestFormData,
        value,
        _get(bidData, key)
      );
    }
  });
};

// create mapping of props and value, who matches the criteria of hotspot property for image and video type values
// image: "data:image" of type string
// video : { videoUrl: "stringBlobUri" }
export const fetchPropByValue = (obj, regexOfValue, root, acc = []) => {
  _each(_keys(obj), k => {
    const v = obj[k];
    const currentKey = _isEmpty(root) ? k : `${root}.${k}`;
    // type of string and contains regex
    if (_isString(v)) {
      if (new RegExp(regexOfValue).test(v)) {
        acc.push({ key: currentKey, value: v });
      }
    }
    if (_isObject(v)) {
      fetchPropByValue(v, regexOfValue, currentKey, acc);
    }
  });
  console.log("fetchPropByValue() returning :: ", acc);
  return acc;
};

// setBlankValueToDataImage() : migrating data:image with "" blank value to create bid only with hotspot-image-properties with blank values
// const setBlankValueToDataImage = (keyValues, formData) => {
//   let clonedFormData = _cloneDeep(formData);
//   _each(keyValues, v => {
//     clonedFormData = _set(clonedFormData, v.key, "");
//   });
//   console.log("setBlankValueToDataImage formData :: ", clonedFormData);
//   return clonedFormData;
// };

// setS3UrlToDataImage() : migrating data:image with s3-uploaded url
const setS3UrlToDataImage = (formData, s3Responses) => {
  let clonedFormData = _cloneDeep(formData);
  _each(s3Responses, v => {
    console.log('v :: setS3UrlToDataImage : ', v);
    if(_includes(v.key,"spot_imageVideo")){
      const { keyToSet,objectToSet } = extractImageVideoDetailsFromS3Url(v.key,v.url);
      console.log('spot_imageVideo :: setS3UrlToDataImage : ', keyToSet,objectToSet)
      clonedFormData = _set(clonedFormData,keyToSet,objectToSet);
    } else {
      clonedFormData = _set(
        clonedFormData,
        v.key,
        decodeURIComponent(_get(_find(s3Responses, { key: v.key }), "url"))
      );
    }
  });
  console.log("setS3UrlToDataImage formData :: ", clonedFormData);
  return clonedFormData;
};

const getBlobFileForVideo = v => {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: v.value, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
      responseType: "blob"
    }).then(response=> {
      resolve(response.data);
    })
    .catch(error => {
      console.log("error on fetching url :: ",error);
    })
  });
};

const getServiceRequestByBRRN = (brrnNo) => {
  return bcApi.bidclips.bid.getByBRRN(brrnNo)
    .then(response => {
      return response;
    });
}

export const checkBidRefAndCreateBid = (widgetData, shopService, jtVersion, dataBack,browserUrl,devicePlatform) => {
  return async dispatch => {
    let brrn = "";
    if(_isUndefined(dataBack.brrn) || _isEmpty(dataBack.brrn)){
        brrn = randomGenerateServiceRequestRefNo("ZXYXYZ"); // Used Key XZYZYX
      const serviceRequestDataUsingRef = await getServiceRequestByBRRN(brrn);
      if(!_isEmpty(_get(serviceRequestDataUsingRef,"data._embedded",[]))){
        return dispatch(checkBidRefAndCreateBid(widgetData, shopService, jtVersion, dataBack,browserUrl,devicePlatform));
      }else {
       return dispatch(createBid(widgetData, shopService, jtVersion, dataBack,browserUrl,devicePlatform, brrn));
      }
    } else {      
      brrn = _get(dataBack,"brrn","");
      return dispatch(createBid(widgetData, shopService, jtVersion, dataBack,browserUrl,devicePlatform, brrn));
    }
  }
} 

export const createBid = (widgetData, shopService, jtVersion, dataBack,browserUrl,devicePlatform,brrn) => {
  return async dispatch => {
    const shopDataResponse = await bcApi.bidclips.shop.getById(_get(shopService,"shop_id.$oid"));
    console.log("shopDataResponse :: in chreateBid : ", shopDataResponse);       
    const dataBidId = _cloneDeep(dataBack.bidId);
    let leadValue;
    if(devicePlatform === "ios"){
      leadValue = _get(BID_REF_LEAD_SOURCE,'LEAD_SOURCE_VALUE_IOS');
    }else if(devicePlatform === "android"){
      leadValue = _get(BID_REF_LEAD_SOURCE,'LEAD_SOURCE_VALUE_ANDROID');
    }else {
      leadValue = _get(BID_REF_LEAD_SOURCE,'LEAD_SOURCE_VALUE_WEB');
    }
    const userAgent = window.navigator.userAgent;
    let requestModel = {
      schema_version: _get(shopDataResponse, "data.use_pricebook", false) ? 11 : 3,
      request_ref: brrn,
      request_date: {"$date": new Date().getTime()},
      internal_priority: "warm",
      lead_source: leadValue,
      browser_url: browserUrl,
      user_agent: userAgent,
      provider:{
        _ref: "provider",
        _id:_get(shopService, "provider._id",{})
      },
      shop: {
        _ref: "shop",
        _id: _get(shopService, "shop_id",{}),
        name: _get(shopService, "shop_name",{}),
      },
      journey_template: {
        _ref: "journey_template",
        _id: _get(shopService, 'journey_template._id'),
        template_version: jtVersion
      },
      shop_service: {
        _ref: "shop_service",
        _id: _get(shopService,"_id",{})
      },
      status: "incomplete",
      status_change_date:{"$date": new Date().getTime()},
      service: {
        _ref: "service",
        _id: _get(shopService,'service_id',{}),
        name: _get(shopService,'service_name',"")
      },
      widget_configuration: {
        _ref: "widget_configuration",
        _id: _get(widgetData,'_id',{})
      },
      form_data: {
      },
      contact_created : false,
    };
    // this is change as per update data 
    if(_isUndefined(dataBack.brrn)) {    
      return bcApi.bidclips.bid.create(requestModel).then(createResponse => {        
        let bidId = createResponse.headers.location.substring(
          createResponse.headers.location.lastIndexOf("/") + 1
        );
        dispatch(bidCreateSuccess(bidId));
        return bidId;
      });
    } else {                  
      const afterBidAtions = [bidCreateSuccess(dataBidId)];
      dispatch(updateBid(dataBidId,requestModel,afterBidAtions));
      return dataBidId;
    }
  };
};


const getBidObject = (
  bidData,
  formData,
  serviceDetails,
  lastStepSubmit,
  journeyTemplateId,
  journeyTemplateVersion,
  bidStatus
) => {
  let bidObj = Object.assign(bidData, {
    form_data: formData
  });
  if (lastStepSubmit && !_isEqual(bidStatus, "draft")) {
    bidObj = Object.assign(bidObj, {
      status: "new",
      status_change_date:{"$date": new Date().getTime()},
      additional_journey_template: [
        {
          _ref: "journey_template",
          _id: journeyTemplateId,
          template_version: journeyTemplateVersion
        }
      ],
     
    });
  } else if (lastStepSubmit && _isEqual(bidStatus, "draft")) {
    let jtAdded = {
      _ref: "journey_template",
      _id: journeyTemplateId,
      template_version: journeyTemplateVersion
    };

    if (bidData.additional_journey_template === undefined) {
      bidData.additional_journey_template = [];
    }
    bidData.additional_journey_template.push(jtAdded);

    bidObj = Object.assign(
      bidData,
      { ..._omit(bidData, ["_id", "_etag"]) },
    );
  }
  console.log("bidObj getBidObject :: ", bidObj);

  return bidObj;
};

const uploadAllMediaToS3 = (
  dispatch,
  mediaKeyValues,
  currentFormData,
  tempBidObj,
  widgetId,
  bidId,
  getStore,
) => {
  return new Promise((resolve, reject) => {
    Promise.all(s3ForPromises(mediaKeyValues, widgetId, bidId, dispatch,getStore)).then(
      s3Responses => {
        console.log("s3Responses uploadAllMediaToS3 ::",s3Responses );
        resolve(s3Responses);
        dispatch(setImageAndKeyURL(s3Responses))
      }
    );
  });
};

const performLastSubmitOfBidRequest = (bidId, tempBidObj, bidData) => (
  dispatch,
  getStore
) => {
  const reduxStore = getStore();
  let pendingPromises = _get(reduxStore, "widget.pendingPromises", []);
  let tempFormData = _get(tempBidObj, "form_data", {});
  
  Promise.all(pendingPromises).then(values => {
    // console.log("responses lenght ", values.length, "....", values);
    for (let idx = 0; idx < values.length; idx++) {
      let response = values[idx];
      tempFormData = setS3UrlToDataImage(tempFormData, response);
    }
    tempBidObj = Object.assign(tempBidObj, {
      form_data: _cloneDeep(tempFormData),
      status: "new",
      status_change_date:{"$date": new Date().getTime()},
      
    });
    console.log("last step submit save :: formData ::", tempBidObj);
    const afterBidAtions = [
      setBidRequestReferenceNumber(_get(bidData, "request_ref", "")),
      bidProgressCount({ reset: true }),
      s3UploadProgresses(),
      push("/bid/bid-success"),
    ];
    return dispatch(updateBid(bidId, tempBidObj,afterBidAtions));
  });
};
  export const updateBidByBidId = (
    currentFormData,
    bidData,
    widgetId,
    serviceDetails,
    lastStepSubmit,
    journeyTemplateId,
    jourenyTemplateVersion,
    bidStatus,
    currentFormDataMediaKeys,
    hotspotQuestionKeys,
    prevFormData
    ) =>{
      return  (dispatch, getStore) => {
        console.log('bidData :: updateBidById : ', bidData)
        if (_isEmpty(_get(bidData, "_id.$oid", ""))){
          return Promise.resolve(bidData);
        }
      let reduxStore = getStore();
      let pendingPromises = _get(reduxStore, "widget.pendingPromises", []);
      const dataImageRegEx = /^(blob)/;
      const allFormData = Object.assign(
        _cloneDeep(_get(bidData, "form_data", {})),
        currentFormData
        );
        let mediaKeyValues = {}
        if(hotspotQuestionKeys.length > 0){
          const hotspotFormData = _pick(currentFormData,hotspotQuestionKeys);
          mediaKeyValues = fetchPropByValue(hotspotFormData, dataImageRegEx);
        }

        console.log("current schema value :: ",currentFormData , mediaKeyValues, currentFormDataMediaKeys, lastStepSubmit);

        const bidId = _get(bidData, "_id.$oid", "");
        const tempBidObj = getBidObject(
          bidData,
          allFormData,
          serviceDetails,
          lastStepSubmit,
          journeyTemplateId,
          jourenyTemplateVersion, 
          bidStatus
          );     

          if(!_isUndefined(currentFormDataMediaKeys)) {
            mediaKeyValues = currentFormDataMediaKeys; 
          }

          try {
            let clonedTempObject = _cloneDeep(tempBidObj);
            if (!_isEmpty(mediaKeyValues)) {
              let p = uploadAllMediaToS3(
                dispatch,
                mediaKeyValues,
                currentFormData,
                clonedTempObject,
                widgetId,
                bidId,
                getStore
              );
              pendingPromises = [...pendingPromises, p];
              dispatch(bidPendingPromises(pendingPromises));
              dispatch(bidProgressCount({ values: mediaKeyValues }));
          }
    const tempFormData = _isEmpty(_get(clonedTempObject, "form_data"))
      ? currentFormData
      : Object.assign(_get(clonedTempObject, "form_data"), currentFormData);
    const bidObj = Object.assign(clonedTempObject, {
      form_data: _cloneDeep(tempFormData)
    });
    return bcApi.bidclips.bid.getBidStatus(bidId).then( bidResponse => {
      console.log('check bid status is draft before patch', bidResponse);
      console.log('condition :: to go for update page or need to patch : ', _isEqual(_get(bidResponse, "data.status"), "draft") || _isEqual(_get(bidResponse, "data.status"), "incomplete"));
      if(_isEqual(_get(bidResponse, "data.status"), "draft") || _isEqual(_get(bidResponse, "data.status"), "incomplete")) {
    if (!lastStepSubmit && _isEmpty(mediaKeyValues)) {
      if(!_isEmpty(_get(currentFormData,"bcCustomerPhone",""))){
      let phonNumber=_get(currentFormData,"bcCustomerPhone","");
      phonNumber = formatPhoneNumber(phonNumber);
      Object.assign(bidObj.form_data,{bcCustomerPhone:phonNumber});
    }

    if(!_isEmpty(_get(currentFormData,"bcCustomerName.first"))) {
      bidObj.form_data.bcCustomerName.first = _trim(currentFormData.bcCustomerName.first);
    }

    if(!_isEmpty(_get(currentFormData,"bcCustomerName.last"))) {
      bidObj.form_data.bcCustomerName.last = _trim(currentFormData.bcCustomerName.last);
    }


    if(!_isEmpty(_get(prevFormData,"bcCustomerPhone",""))){
      let phonNumber=_get(prevFormData,"bcCustomerPhone","");
      phonNumber = formatPhoneNumber(phonNumber);
      Object.assign(prevFormData,{bcCustomerPhone:phonNumber});
    }

    if(!_isEmpty(_get(prevFormData,"bcCustomerName.first"))) {
      prevFormData.bcCustomerName.first = _trim(prevFormData.bcCustomerName.first);
    }

    if(!_isEmpty(_get(prevFormData,"bcCustomerName.last"))) {
      prevFormData.bcCustomerName.last = _trim(prevFormData.bcCustomerName.last);
    }

    const bidPatchPromiss =_get(reduxStore,"widget.bidPatchPromises",[]).concat( dispatch(updateBid(bidId, bidObj, [], prevFormData)))
    dispatch(bidPatchPromises(bidPatchPromiss))
      return reduxStore = getStore();
      // dispatch(setStepIndex(stepIndex + 1));
    }
    // if(!lastStepSubmit && !_isEmpty(mediaKeyValues)){
    //   dispatch(setStepIndex(stepIndex + 1));
    // }

    if (lastStepSubmit) {
      Promise.all(_get(reduxStore, "widget.bidPatchPromises", [])).then(() =>{
        dispatch(bidPatchPromises([]))
        if(!_isEmpty(_get(currentFormData,"bcCustomerPhone",""))){

          let phonNumber=_get(currentFormData,"bcCustomerPhone","");
          phonNumber = formatPhoneNumber(phonNumber);
          Object.assign(bidObj.form_data,{bcCustomerPhone:phonNumber})
          Object.assign(bidData.form_data,{bcCustomerPhone:phonNumber})
          
        }

        if(!_isEmpty(_get(currentFormData,"bcCustomerName.first"))) {
          bidData.form_data.bcCustomerName.first = _trim(bidData.form_data.bcCustomerName.first);
          bidObj.form_data.bcCustomerName.first = _trim(bidObj.form_data.bcCustomerName.first);
        }

        if(!_isEmpty(_get(currentFormData,"bcCustomerName.last"))) {
          bidData.form_data.bcCustomerName.last = _trim(bidData.form_data.bcCustomerName.last);
          bidObj.form_data.bcCustomerName.last = _trim(bidObj.form_data.bcCustomerName.last);
        }
        dispatch(performLastSubmitOfBidRequest(bidId, bidObj, bidData));
      }).catch(error => {
        console.log("error to get token", error);
        return error;
      });
    }
  } else {
    dispatch(push("/bid/bid-success"));
  }
});
  } catch (Exception) {
    console.log("error: ", Exception);
    dispatch(setFormSaveError("Error"));
    dispatch(push("/bid/bid-fail"));
  }
  }
};

const uploadProgress = (bidId, name, value, dispatch) => {
  dispatch(s3UploadProgresses(name, value));
};

const extractImageVideoDetailsFromS3Url = (s3Key,s3Url) => {
  const splitKeys = _split(s3Key,".");
  const takeKeys = _slice(splitKeys, 0, splitKeys.length - 1);
  const newKey = _join(takeKeys,".");
  console.log('gethering details :: check : ', splitKeys,takeKeys,newKey);
  const decodedUrl = decodeURIComponent(s3Url);
  const splitForExtention = _split(decodedUrl,".");
  const splitForFileName = _split(decodedUrl,"/");
  return {
    "keyToSet" : newKey,
    "objectToSet" : {
      "fileName" : splitForFileName[splitForFileName.length - 1],
      "mimeType" : !_includes(["quicktime"],splitForExtention[splitForExtention.length - 1]) ? mime.lookup(splitForExtention[splitForExtention.length - 1]) : mime.lookup("mov"),
      "url" : decodedUrl,
      "metadata" : {}
    }
  }
}

const s3ForPromises = (dataImageKeyValues, widgetId, bidId, dispatch,getStore) => {
  console.log("dataImageKeyValues s3ForPromises :: ", dataImageKeyValues);
  return _map(dataImageKeyValues, v => {
    if (_startsWith(v.value, "blob:")) {
      const promise = new Promise(resolve => {
        getBlobFileForVideo(v).then(blobFile => {
          uploadBidAttachmentToS3PresignedUrl(
            (bidId, name, value) =>
              uploadProgress(bidId, name, value, dispatch),
            blobFile,
            v.key,
            widgetId,
            bidId,
            resolve,
            dispatch,
            getStore,
          );
        });
      });
      return Promise.resolve(promise).then(response => {
        const store = getStore();
        const bidObj = _get(store,"bid.record.data",{});
        const formData = _get(store, "widget.formData",{});
        let patchObject = {
          'update_from' : "s3-upload",
          "previous_etag" : _get(bidObj,"_etag",""),
        };
        if(_includes(response.key,"spot_imageVideo")){
          const { keyToSet, objectToSet } = extractImageVideoDetailsFromS3Url(response.key, response.url);
          console.log('imageVideo blot in s3Promise : ', keyToSet, objectToSet);
          _assign(patchObject,{[`form_data.${keyToSet}`] : objectToSet});
        } else {
          _assign(patchObject,{[`form_data.${response.key}`] : decodeURIComponent(_get(response,"url",""))});
        }
        console.log('patchObject :: checking in : ', patchObject)
        const bidPatchSetObject = {
          "$set" : patchObject
        }
        const tempFormData = setS3UrlToDataImage(formData, [response]);
        dispatch(setFormData(_cloneDeep(tempFormData)));
        dispatch(updateBidPatchStatus(true));
        console.log('bidPatchSetObject :: checking : ', bidPatchSetObject);
        bcApi.bidclips.bid.patch(bidId,bidPatchSetObject).then(res => {
          dispatch(updateBidPatchStatus(false));
          dispatch(updateEtagOfBid(_get(res,"headers.etag",null)));
        });
        return response;
      });
    }
  });
};

const jourenyTemplateById = async (response,formContext,dispatch, getForExistingService) => {
  console.log("jourenyTemplateById starting", response, formContext, dispatch, getForExistingService);
  let journeyTemplate;
  if(getForExistingService) {

    journeyTemplate = _get(response, "data._embedded[0]", {});
  } else {
    journeyTemplate = _get(response, "data", {});
  }
        let jtElements = _get(journeyTemplate,"ui_schema.elements",[]);
        if(!_isEmpty(formContext)){
          const repeatQuestions = _filter(jtElements, qus => qus.type === "Repeat");
          console.log('repeatQuestions ::', repeatQuestions)
          const repeatQuestionAssociatedIds = _map(repeatQuestions, question => {
            return _get(question, "raw_input.selected_journey_template._id", {});
          });
          console.log('repeatQuestionAssociatedIds ::', repeatQuestionAssociatedIds);
          if (!_isEmpty(repeatQuestionAssociatedIds)) {
            const filter = {
              _id : { $in : repeatQuestionAssociatedIds },
            }
            const queryString ={};
            queryString.filter = JSON.stringify(filter);
            console.log('queryString :: ', queryString)
            const response = await loadJourneyTemplates(queryString);
            console.log('response :: ', response);
            _forEach(repeatQuestions, question => {
              const associatedJourneyTemplateData = _find(response, val => val._id.$oid === question.raw_input.selected_journey_template._id.$oid);
              console.log('associatedJourneyTemplateData ::', associatedJourneyTemplateData)
              let filteredAssociatedJtElements = _filter(
                _get(associatedJourneyTemplateData,"ui_schema.elements",[]),
                o =>
                  !("answered_by" in o) ||
                  o.answered_by === "both" ||
                  o.answered_by === formContext.businessContext,
              );
              console.log('associatedJtElements :: :: ', filteredAssociatedJtElements);
              console.log('repeatQuestions,jtTemplate :: ', repeatQuestions);
              console.log('mainJTRepeatQuestion :: ', question)
              if(question.raw_input.do_not_ask_for_basic_cust_info_each_repeat){
                filteredAssociatedJtElements = _filter(filteredAssociatedJtElements, element => element.type !== "Customer Question");
              }
              if (filteredAssociatedJtElements.length === 0) {
                jtElements = _filter(jtElements, element => element._id !== question._id);
              }
              console.log('jtElements :: ', jtElements)
            });
          }
          const repeatV2Questions = _filter(jtElements, qus => qus.type === "RepeatV2");
          _forEach(repeatV2Questions,question => {
            const filteredAssociatedJtElements = _filter(
              _get(question, "raw_input.associatedJTElements", []),
              o =>
                !("answered_by" in o) ||
                o.answered_by === "both" ||
                o.answered_by === formContext.businessContext,
            );
            if(filteredAssociatedJtElements.length === 0){
              jtElements = _filter(jtElements, element => element._id !== question._id);
            }
          });
        }
        dispatch(
          getJourneyTemplateSuccess(
            _get(journeyTemplate, "ui_schema", null),
            _get(journeyTemplate, "journey_template_id", _get(journeyTemplate,"_id",null)),
            _get(journeyTemplate, "template_version", 1)
          )
        );
        setTimeout(() => {
          dispatch(
            getJourneyTemplateUISchemaElementsSuccess(
              jtElements,
            ),
          );
        }, 700)
        return response;
      
}

export const getJourneyTemplateById = (journeyTemplateId, formContext , jtVersion) => {
  return dispatch => {
    console.log("journey template id::", journeyTemplateId);
    if(jtVersion) {
      return bcApi.bidclips.journey_template_version
      .findByVersion({$oid : journeyTemplateId}, jtVersion)
      .then(response => { 
        console.log("response from jt ::: ", response);
        return jourenyTemplateById(response, formContext , dispatch , true);
      }
      )
    } else {
      return bcApi.bidclips.journey_template
        .getById(journeyTemplateId)
        .then( async response => {
          console.log("response from jt ::: ", response);
          console.log("getJourneyTemplateById response", response);        
          return jourenyTemplateById(response, formContext , dispatch , false);
        })
      }
    }
};

const loadJourneyTemplates = (queryString) => {
  return bcApi.bidclips.journey_template.getJourneyTemplates(qs.stringify(queryString, { indices: false })).then(response => {
    console.log('response :: loadJourneyTemplate :: ', response)
    return _get(response,"data._embedded",[]);
  });          
}
export const getByJourneyTemplateIdAndVersion = (id, version, formContext) => {
  return dispatch => {
    return bcApi.bidclips.journey_template_version
      .findByVersion(id, version)
      .then(response => {
        console.log("getByJourneyTemplateIdAndVersion response", response , id ,version,formContext);
        let jtElements = _get(response, "data._embedded[0].ui_schema.elements", []);
        if(formContext && !_isEmpty(formContext)){
          const repeatV2Questions = _filter(jtElements, qus => qus.type === "RepeatV2");
          _forEach(repeatV2Questions,question => {
            const filteredAssociatedJtElements = _filter(
              _get(question, "raw_input.associatedJTElements", []),
              o =>
                !("answered_by" in o) ||
                o.answered_by === "both" ||
                o.answered_by === formContext.businessContext,
            );
            if(filteredAssociatedJtElements.length === 0){
              jtElements = _filter(jtElements, element => element._id !== question._id);
            }
          });
        }
        dispatch(
          getJourneyTemplateSuccess(
            _get(response, "data._embedded[0].ui_schema", null),
            _get(response, "data.journey_template_id", _get(response, "data._embedded[0].journey_template_id", null)),
            _get(response, "data.template_version", _get(response, "data._embedded[0].template_version", 1))
          ),
        );
        setTimeout(() => {
          dispatch(
            getJourneyTemplateUISchemaElementsSuccess(
              jtElements,
            ),
          );
        },500)
        return response;
      });
  };
};

// export const update = (
//   formData,
//   bidData,
//   widgetId,
//   jourenyTemplateId,
//   jourenyTemplateVersion
// ) => {
//   return dispatch => {
//     console.log("bidData", bidData);
//     console.log("formData: ", formData);

//     let jtAdded = {
//       _ref: "journey_template",
//       _id: jourenyTemplateId,
//       template_version: jourenyTemplateVersion
//     };

//     if (bidData.additional_journey_template === undefined) {
//       bidData.additional_journey_template = [];
//     }
//     bidData.additional_journey_template.push(jtAdded);

//     if (!_isEmpty(formData)) {
//       const dataImageRegEx = /^(blob)/;
//       const dataImageKeyValues = fetchPropByValue(formData, dataImageRegEx);
//       const todaysDateTime = formatDateTime();

//       let bidObj = Object.assign(
//         bidData,
//         { ..._omit(bidData, ["_id", "_etag"]) },
//         {
//           request_date: todaysDateTime,
//           status: "new",
//           form_data: setBlankValueToDataImage(dataImageKeyValues, formData),
//           browser_url: document.referrer
//         }
//       );

//       return bcApi.bidclips.bid
//         .patch(bidData._id.$oid, bidObj)
//         .then(createResponse => {
//           console.log("createResponse of bid.create: ", createResponse);
//           let bidId = bidData._id.$oid;
//           console.log("createResponse of bid.create bidId: ", bidId);

//           Promise.all(
//             s3ForPromises(dataImageKeyValues, widgetId, bidId,dispatch)
//           ).then(s3Responses => {
//             bcApi.bidclips.bid
//               .patch(bidId, {
//                 form_data: setS3UrlToDataImage(
//                   dataImageKeyValues,
//                   formData,
//                   s3Responses
//                 )
//               })
//               .then(patchResponse => {
//                 console.log("bid :: patch :: response :: ", patchResponse);
//                 return Promise.resolve(_get(patchResponse, "data", null));
//               });
//           });
//         });
//       }
//   }
// };

export const widgetClearRecord = () => ({
  type : CLEAR_WIDGET_ELEMENTS
})
export const changeNavIndex = (navigationIndex) => ({
  type : HANDLE_NAVIGATION_INDEX,
  navigationIndex,
})

export const doClearJTElements = () => ({
  type : CLEAR_JT_ELEMENTS,
})

export const clearJTElements = () => {
  return dispatch => {
    dispatch(doClearJTElements());
  }  
}  

export const getAuthenticationToken = widgetId => {
  return dispatch => {
    const tokenObj = {
      widgetId: widgetId,
      widgetVersion: _get(WIDGET_VERSION,'WIDGET_RELEASE_VERSION'),
      user: "anonymous"
    };
    return bcApi.bidclips.authentication
      .getJwt(tokenObj)
      .then(authToken => {
        return dispatch(saveAuthenticationToken(_get(authToken,'data.id_token','')));
      })
      .catch(error => {
        console.log("error to get token", error);
        return error;
      });
  };
};


/**
 * One way write operation
 * 
 * @param {String} propName property name to write
 * @param {String} propValue value to write
 */
export const writeLocalStorage = (propName, propValue = null) => {
  window.parent && window.parent.postMessage({
    type:"bci_set_localstorage",
    propName,
    propValue}, "*");
}

/**
 * This should create object like below in redux store
 * {
 *    propName1 : [ resolutionFunc1, resolutionFunc2 ],
 *    propName2 : [ resolutionFunc3, resolutionFunc4 ]
 * }
 * 
 * @param {String} propName 
 */
export const readLocalStorage = (propName) => (dispatch, getStore) => {
  const reduxStore = getStore();
  return new Promise( (resolutionFunc,rejectionFunc) => {
    //get current pending read map
    const pendingReadMap = _get(reduxStore, 'widget.localStoragePendingReadMap', {});
    
    //retrive and append resolution function against property name for pendingread
    pendingReadMap[propName] = _get(pendingReadMap, propName, []);
    pendingReadMap[propName].push(resolutionFunc);
    
    //update same to redux
    dispatch(setLocalStoragePendingReadMap(pendingReadMap));
    
    window.parent && window.parent.postMessage({
      type:"bci_read_localstorage",
      propName}, "*");
  });
}
  

export const localStorageReadResponse = (readResponseObj)  => (dispatch, getStore) => {
  const reduxStore = getStore();

  const propName = _get(readResponseObj,'data.propName',null);
  const propValue = _get(readResponseObj,'data.propValue',null);

  //get current pending read map
  const pendingReadMap = _get(reduxStore, 'widget.localStoragePendingReadMap', {});
  
  //retrive resolution function list against property name for pendingread
  const pendingRead4Property = _get(pendingReadMap, propName, []);

  //remove resolution function against property name 
  delete pendingReadMap[propName];
    
  //update same to redux
  dispatch(setLocalStoragePendingReadMap(pendingReadMap));

  //resolve pending read promise resolution functions 
  pendingRead4Property.map( resolutionFunc => resolutionFunc(propValue) );
}

export const getServiceInfo = serviceId => {
  return dispatch => {
    return bcApi.bidclips.service.getById(serviceId).then(response => {
      dispatch(serviceInfo(response.data))
      return response.data
    })
  }
}
