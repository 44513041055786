import React from 'react'

import Form from 'react-bootstrap/Form'

export const FormControls = (props) => {
  const { children } = props
  return (
    <Form.Control {...props}>
      {children}
    </Form.Control>
  )
}

export default FormControls
