import { BID_GET_SUCCESS, 
  BID_RECORD_DELET, 
  BID_PATCH_STATUS ,
  BID_GET_CONTACT,
  BID_GET_ADDITIONAL_CONTACT} from "../action-types";

import qs from 'qs'; 

import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _map from "lodash/map"
import _isEqual from "lodash/isEqual"
import _concat from "lodash/concat"
import _findIndex from "lodash/findIndex"
import _compact from "lodash/compact"
import history from "../../history";

import bcApi from "../../lib/bidclips/index";

import { getById as getShopById } from "../shop/actions";

export const getSuccess = data => ({
  type: BID_GET_SUCCESS,
  data
});
const bidContact = contact => ({
  type : BID_GET_CONTACT,
  contact
})
export const bidAdditionalContacts = additional_contacts => ({
  type : BID_GET_ADDITIONAL_CONTACT,
  additional_contacts
})
const changeBidPatchStatus = status => ({
  type : BID_PATCH_STATUS,
  status
});
export const getBidContact =  (id) => {
  return async (dispatch) => {
    try{
      const contactResp = await bcApi.bidclips.contact.getbyId(id)
      console.log("service request Contact::",contactResp)
      return dispatch(bidContact(contactResp.data))
  
  }catch(e){
      console.error("service request Contact error",e)
      return Promise.reject(e)
  }
  }
}

export const getBidAdditionalContact =  () => {
  return async (dispatch, getState) => {
    try{
      const additionalContactsRefs = _get(getState(), "bid.record.data.additional_contacts", {})
      const additionalContacts = await Promise.all(_map(additionalContactsRefs,async ({_id}) =>_get(await bcApi.bidclips.contact.getbyId(_id.$oid),"data")))
      console.log("service request Contact additional::",additionalContacts)
      return dispatch(bidAdditionalContacts(additionalContacts))
  
  }catch(e){
      console.error("service request Contact error",e)
      return Promise.reject(e)
  }
  }
}

export const  AddAdditionalContact = (contact) =>{
  return async (dispatch) => {

    try{
        const contactResp = await bcApi.bidclips.contact.add(contact)
        // Safari doesn't support (?<=\/)
        // https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
        const headerLocation = _get(contactResp,"headers.location","");
        const contactID = headerLocation.substring(headerLocation.lastIndexOf("/") + 1);
        console.log("service request Contact add::",contactID)
        if(!_isEmpty(contactID)){
          return contactID
        }else{
         return Promise.reject("No contactID returned")
        }
  
      }catch(e){
        console.error("service request Contact add error",e)
        return Promise.reject(e)
      }
  }
}

const noAccessibleContactRoutChange = (reduxState, bidData, routeHistory) => {
  
  // remove access from everywhere

  const isAllow2Edit = _get(reduxState, "contactLink.preference.allow2Edit", false);
  const contactFromContactLink = _get(reduxState, "contactLink.data.contact", {});
  let isAccessibleContact = true;
  const isProviderContact = _isEqual(_get(contactFromContactLink, "_ref"), "provider");

  if (!isAllow2Edit && !_isEmpty(contactFromContactLink) && !isProviderContact) {
    const bidContact = _get(bidData, "contact", {});
    const bidAdditionalContact = _get(bidData, "additional_contacts", []);
    const bidAssociatedContacts = _compact(_concat(bidAdditionalContact, bidContact));
    console.log("noAccessibleContactRoutChange 1", bidContact, bidAdditionalContact, bidAssociatedContacts);
    isAccessibleContact = !_isEqual(_findIndex(bidAssociatedContacts, contact => _isEqual(_get(contact, "_id.$oid", false), _get(contactFromContactLink, "_id.$oid", true))), -1);
  }

  console.log("noAccessibleContactRoutChange 2", isAllow2Edit, contactFromContactLink, isProviderContact, isAccessibleContact);

  if (!isAccessibleContact) {
    routeHistory.push("/contact/notfoundcontact");
  }
}

export const getBidInfo = bidId => {
  return (dispatch, getState) => {
    console.log("Bid Info::", bidId);
    return bcApi.bidclips.bid.getById(bidId).then(response => {
      console.log("Bid Information", response);
      const reduxState = getState();
      noAccessibleContactRoutChange(reduxState, response.data, history);
      const shopId = _get(response,"data.shop._id.$oid");
      if(!_isEmpty(shopId)){
        dispatch(getShopById(shopId));
      }
      dispatch(getSuccess(response.data));
      return response;
    });
  };
};

export const getAllQuotesByBidIdV2 =(bidId)=>{
  console.log("check getAllQuotesByBidIdV2 getAllQuotesByBidIdV2",bidId);
  const filter = { $and: [{ "bid._id": {"$oid":bidId} }, { "status" : { $in : ["sent","accepted","expired"] } }] };
  const keys = {
    quoteSummary: 1, 
    status: 1,
    quote_expiry:1,
    quote_expiry_date:1,
    service_location_shop:1, 
    service_location_mobile:1, 
    grand_total_at_shop:1, 
    grand_total_at_mobile:1,
    grand_total:1,
    schema_version : 1,
    bc_insu_is_insurance_service_requested:1,
    grand_total_with_insurance:1,
    grand_total_with_insurance_at_shop:1,
    grand_total_with_insurance_at_mobile:1,
    grand_total_with_travel_surcharge:1,
  };
  return (dispatch)=>{
      let queryString = {
        count: true,
        time: new Date().toISOString()
      }; 
     if (filter !== null) {
         queryString.filter = JSON.stringify(filter);
         queryString.keys = JSON.stringify(keys) 
     }
      return bcApi.bidclips.quote.getAllQuoteByBidRequestId(qs.stringify(queryString, { indices: false })).then(response =>{
        console.log("Check Respons BY Service Id",response,queryString)
        return _get(response.data,"_embedded",[])
      })
  }
}
export const updateBidPatchStatus = patchStatus => dispatch => {
  dispatch(changeBidPatchStatus(patchStatus));
} 

const doClearRecord = () => ({
  type: BID_RECORD_DELET
});

/**
 * Clear Record state
 */
export const clearRecord = () => {
  return dispatch => {
    console.log("clearRecord... Bid");
    dispatch(doClearRecord());
  };
};
