import { get as _get, has as _has } from 'lodash';
import localString from "../../lang/lang";
import bcApi from '../../lib/bidclips';
import { 
  ADD_CHAT_MESSAGE_SUCCESS, 
  AWSS3_UPLOAD_CHAT_API_ERRORED, 
  AWSS3_UPLOAD_CHAT_API_SUCCESS, 
  CHAT_CRUD_ERRORED, 
  CHAT_CRUD_REQUESTED, 
  CHAT_STATUS_MESSAGE, 
  CHAT_TOKEN_ERRORED, 
  CHAT_TOKEN_REQUESTED, 
  CHAT_TOKEN_SUCCESS, 
  COMPLETE_CHAT_SUCCESS, 
  CREATE_USER_SUCCESS, 
  FIND_ONLINE_PROVIDER_SUCCESS, 
  PROVIDER_USER_HEARTBIT_LOST, 
  PROVIDER_USER_HEARTBIT_SUCCESS, 
  SET_CHANNEL_ID, SET_CHAT_CLIENT, 
  SET_CURRENT_CHANNEL, 
  SET_MESSAGE_INPUT_SUCCESS, 
  UPDATE_CUSTOMER_CHAT_SUCCESS,
} from '../action-types';
import { readLocalStorage, writeLocalStorage } from '../widget/actions';
import {
getChatMessages as getChatMessagesFromRedux, //getFromIdentity as getFromIdentityFromRedux,
  getToIdentity as getToIdentityFromRedux
} from './selectors';

  

  
  const chatTokenRequested = () => ({ type: CHAT_TOKEN_REQUESTED })
  
  const chatTokenErrored = (errorMessage) => ({ type: CHAT_TOKEN_ERRORED, errorMessage })
  
  const chatTokenSuccess = (token, ttl) => (
    { 
      type: CHAT_TOKEN_SUCCESS, 
      token, 
      ttl
    }
  )
  
  const createUserSuccess = (fromIdentity) => (
    {
      type: CREATE_USER_SUCCESS,
      fromIdentity
    }
  )

  const findOnlineProviderSuccess = (toIdentity, toUser) => (
    {
      type: FIND_ONLINE_PROVIDER_SUCCESS,
      toIdentity,
      toUser
    }
  )

  const addChatMessageSuccess = (chatMessages) => (
    {
      type: ADD_CHAT_MESSAGE_SUCCESS,
      chatMessages
    }
  )

  const addChatawsS3UploadChatApieSuccess = (path) =>(
    {type : AWSS3_UPLOAD_CHAT_API_SUCCESS,
      path}
  )

  export const completeChatSuccess = () => (
    {
      type: COMPLETE_CHAT_SUCCESS
    }
  )
  
  const updateCustomerChatSuccess = () => (
    {
      type: UPDATE_CUSTOMER_CHAT_SUCCESS
    }
  )

  const userHeartbitLost = (heartbitFailMsg) => (
    {
      type: PROVIDER_USER_HEARTBIT_LOST,
      heartbitFailMsg
    }
  )

  export const setChatStatusMessage = (statusMessage) => (
    {
      type: CHAT_STATUS_MESSAGE,
      statusMessage
    }
  )
  
const chatClient = (chatClient)=>{
  console.log("c hat client in action.js :: ", chatClient)
 return {
    type : SET_CHAT_CLIENT,
    chatClient
  }
}
const setInputMessage = (input) => {
  return {
    type: SET_MESSAGE_INPUT_SUCCESS,
    input
  }
}

const userHeartbitSuccess = () => ({ type: PROVIDER_USER_HEARTBIT_SUCCESS })

const currentchannelsuccess  = (currentChannel) => {
 console.log("channel suucess action", currentChannel);
 return  {type : SET_CURRENT_CHANNEL, currentChannel}
};

const setChannelId = (channelId) => ({
    type: SET_CHANNEL_ID,
    channelId
});

const crudRequested = () => ({ type: CHAT_CRUD_REQUESTED })

const crudErrored = (errorMessage) => ({ type: CHAT_CRUD_ERRORED, errorMessage })

export const setChatClientProps = (chatClientobj)=> (dispatch) => {
  dispatch(chatClient(chatClientobj));
}

  export const addChatMessage = (messageObject) => (dispatch, getState) => {
      let existingMessages = getChatMessagesFromRedux(getState());
      console.log ("inside action add chat message :: existing redux message::",existingMessages)
      let cloneMessages = existingMessages.concat();
      cloneMessages.push(messageObject);

      dispatch(addChatMessageSuccess(cloneMessages));
  }

  const awsS3UploadChatApiError = (errorMessage) =>({type: AWSS3_UPLOAD_CHAT_API_ERRORED, errorMessage})

  // export const awsS3UploadChatApiSuccess = (path) => (dispatch, getState) => {

  //     let existingMessages = getChatMessagesFromRedux(getState());
  //     let cloneMessages = existingMessages.concat();
  //     cloneMessages.push(path);

  //     dispatch(addChatawsS3UploadChatApieSuccess(cloneMessages));
  // }
  /**
   * 
   */
//
export const uploadChatImage = (fileBinary, customerChatId, messageInput) => (
  dispatch,
  getState
) => {
  return bcApi.bidclips.aws.s3
    .uploadChatImage(fileBinary, customerChatId)
    .then(response => {
      dispatch(
        addChatawsS3UploadChatApieSuccess(
          response.data[0].metadata.userMetadata.fullFilePath
        )
      );
      dispatch(setInputMessage(messageInput));
      console.log('chat-image annotation :: upload :: s3-path :: ', response.data[0].metadata.userMetadata.fullFilePath);
     
      let s3FileKey; 
       
        const Key = _get(response,"data[0].metadata.userMetadata.fullFilePath").replace("//","").match(/\/(.*)/g);

        if(Key !== null){
          s3FileKey = Key[0].replace("/","")
        }
      console.log("s3FileKey ", s3FileKey);
      return new Promise((resolve, reject) => {
        bcApi.bidclips.aws.lambda
          .rotateImage(s3FileKey)
          .then(rotateResponse => {
            console.info("bidAttachment :: rotateResponse", rotateResponse);
            resolve({
              s3ImageUrl: _get(
                response,
                "data[0].metadata.userMetadata.fullFilePath"
              )
            });
          })
          .catch(rotateError => {
            console.error("bidAttachment :: rotateError", rotateError);
            resolve({
              s3ImageUrl: _get(
                response,
                "data[0].metadata.userMetadata.fullFilePath"
              ),
              rotateError
            });
          });
      });
    })
    .catch(error => {
      dispatch(awsS3UploadChatApiError(error.response));
      return Promise.reject(error.response);
    });
};

export const setCurrentChannel = (currentChannel) => (dispatch) =>{
  console.log("currentChannel in chat/action : ",currentChannel)
  dispatch(currentchannelsuccess(currentChannel));
}


  export const getChatToken = (widgetId, identity) => (dispatch, getState) => {
      console.log("Requesting New token for : ", identity);
      dispatch(chatTokenRequested);
      return bcApi.bidclips.chat.getToken(widgetId, identity)
      .then( (response) => {

        const token = _get(response.data, 'access_token', null);
        const ttl = _get(response.data, 'ttl', null);

        dispatch(chatTokenSuccess(token, ttl));
        return Promise.resolve(token);
      })
      .catch( (error) => {
        console.log("Failed to get chat token : ", error);
        dispatch(chatTokenErrored(error.response));
        return Promise.reject(error.response);
      });
  }

  export const checkAndLoadExistingChatInProgress = (checkCount) => (dispatch,getState) => {
    const widgetId = _get(getState(),"widget.widgetId");
    return dispatch(readLocalStorage(`${widgetId}fromIdentity`))
    .then( fromIdentity => 
      dispatch(checkAndLoadExistingChatInProgressPart2(fromIdentity, checkCount))
    );
  }
  
  const checkAndLoadExistingChatInProgressPart2 = (currentFromIdentity, checkCount) => (dispatch,getState) => {  
    if( currentFromIdentity != null ) {
      return bcApi.bidclips.customer_chat.getById(currentFromIdentity)
      .then( response =>{
        console.log("chat_ checkAndLoadExistingChatInProgress getById ", currentFromIdentity, response);
        const channelId = _get(response, 'data.channel_id', null);
        // const chatStatus = _get(response, 'data.chat_status', null);
        const closeReason = _get(response, 'data.close_reason', null);
        const userInvited = _get(response, 'data.user', []);
        if(channelId !== null && closeReason === null && userInvited.length > 0){

          //This channel is good to go forward with...
          dispatch(createUserSuccess(currentFromIdentity));
          dispatch(setChannelId(channelId));
          
          //Set the name from DB as well...
          const toIdentity = _get(response, 'data.user[0]._id.$oid', null);
          const toUser = _get(response, 'data.user[0].name', '');
          dispatch(findOnlineProviderSuccess(toIdentity, toUser));
          console.log("chat_ provider user found - check heartbit.... ",toIdentity, toUser);
          //TODO: Checkhearbit...
        }
        else{
          //chat may be closed already check it out
          if( closeReason !== null ){
            console.log("chat_ closed already - closeReason", closeReason);
            
            //this chat is closed destroy token
            dispatch(completeChatWithReason(currentFromIdentity, closeReason));
            return Promise.resolve(false);        
          }
          else{
            console.log("chat_ may be initialising on another tab ??", checkCount);

            //looks like we may be in initalization phase
            if(checkCount === undefined){
              checkCount = 1;
            }
            if(checkCount < 4){
              checkCount = checkCount + 1;
              return new Promise(function(resolve, reject) {
                setTimeout(function() {
                  resolve(dispatch(checkAndLoadExistingChatInProgressPart2(currentFromIdentity, checkCount)));
                }, 2000);
              });
            }
            else{
              //give up
              console.log("chat_ giving up after", checkCount, response);
              dispatch(completeChatWithReason(currentFromIdentity, 'missing_provider'));
              return Promise.resolve(false);
            }
          }
        }

        //existing chat in progress loaded...
        return Promise.resolve(true);
      });
    }
    //no existing Chat in progress...
    return Promise.resolve(false);
  }

  export const createUser = (customerChatObj, widgetId) => (dispatch, getState) => {

    //let currentFromIdentity = getFromIdentityFromRedux(getState());
    //localStorage.getItem(`${getWidgetId(getState)}fromIdentity`);
    const propName = `${widgetId}fromIdentity`;
    return dispatch(readLocalStorage(propName))
    .then( currentFromIdentity => {
      if( currentFromIdentity != null ) {
        console.log("Returning Existing fromIdentity : User already created.");
              dispatch(createUserSuccess(currentFromIdentity));
        return Promise.resolve(currentFromIdentity);
      }
      else {
          console.log("Requesting new fromIdentity : Going to create new User.",customerChatObj);
          console.log("widgetId from chat action", widgetId);
          dispatch(crudRequested);
          return bcApi.bidclips.customer_chat.post(customerChatObj, widgetId)
          .then( response => {
              writeLocalStorage(propName, response.data.id);
              dispatch(createUserSuccess(response.data.id));
              return Promise.resolve(response.data.id);
          })
          .catch( error => {
              console.warn("Error in creating user for chat : ", error.response);
              dispatch(crudErrored(error.response));
              return Promise.reject(error.response);
          })
      }
    });
  }

  export const findOnlineProvider = (widgetId) => (dispatch, getState) => {

    let currentToIdentity = getToIdentityFromRedux(getState());
    
    if( currentToIdentity != null ) {
      console.log("Returning Existing toIdentity : online Provider already found.");
      return Promise.resolve(currentToIdentity);
    }
    else {
        console.log("Requesting new toIdentity : Going to find online Provider.");
        dispatch(crudRequested);

        // Temporary for development
        let development = false;
        if(development){
          dispatch(findOnlineProviderSuccess("5b692e09ae5f3d0ce92481c2", "munjal"));
          return Promise.resolve("5b692e09ae5f3d0ce92481c2");

          // dispatch(findOnlineProviderSuccess("5bc089a506185d60c9f5507d", "dj"));
          // return Promise.resolve("5bc089a506185d60c9f5507d");
        }
        else{
          return bcApi.bidclips.user_chat_info.findOnlineProvider(widgetId)
          .then( response => {
              console.log('response of online provider api : ', response);
              dispatch(findOnlineProviderSuccess(response.data.id, response.data.name));
              return Promise.resolve(response.data.id);
          })
          .catch( error => {
              console.warn("Error in finding online provider for chat : ", error.response);
              dispatch(crudErrored(error.response));
              return Promise.reject(error.response);
          })
        }
    }
  }

  const deriveCustomerName = (form_data) => {
    let name = '';
    if (_has(form_data["bcCustomerName"], 'first')) {
        name = _get(form_data["bcCustomerName"], 'first', '');
    }
    if (_has(form_data["bcCustomerName"], 'last')) {
        if (name.length >= 1) {
            name += ' ';
        }
        name += _get(form_data["bcCustomerName"], 'last', '');
    }
    if (name.trim().length === 0) {
        name = 'Unknown';
    }
    return name;
  }

  const completeChatWithReason = (fromIdentity, reason) => (dispatch, getState) => {
    const reduxState = getState();
    const formData = _get(reduxState, 'widget.formData', {});
    const name = deriveCustomerName(formData);
    const channel =  _get(reduxState, "chat.currentChannel", null);
    console.log("channelForChat : ", channel);
    const chatMessages = _get(reduxState, 'chat.chatMessages',[]);
    const customerChatObj = {
        "form_data": formData,
        "customer_name": name,
        "chat_content": chatMessages,
        "chat_status": "closed",
        "channel_id": channel !== null ? channel.sid : null,
        "close_reason": reason
    };
    console.log("completing chat for fromIdentity : ", fromIdentity);
    if(channel !== null ){
      channel.leave();
    }
    dispatch(completeChat(fromIdentity, customerChatObj))
    .then(response => console.log('completeChatWithReason ', reason, fromIdentity))
    .catch(error => console.warn("Error in completing chat : ", error));
  }

  export const completeChat = (id, customerChatObj) => {
      if(id === null) {
        return;
      }
      //that chat is completed and clear state only...
      return (dispatch, getState) => {
        const widgetId = _get(getState(),"widget.widgetId");
        writeLocalStorage(`${widgetId}fromIdentity`, null);
        return bcApi.bidclips.customer_chat.patch(id, customerChatObj)
        .then( response => {
          dispatch(completeChatSuccess());
          return Promise.resolve(localString.chat.completeChatSuccess);
        })
        .catch( error => {
          console.warn("Failed to complete Chat : ", error.response);
          dispatch(crudErrored(localString.chat.completeChatFailure));
          return Promise.reject(localString.chat.completeChatFailure);
        })
      }     
  }

  export const updateCustomerChat = (id, customerChatObj) => {
    if(id === null) {
      return;
    }
    return dispatch => {
      return bcApi.bidclips.customer_chat.patch(id, customerChatObj)
      .then( response => {
        dispatch(updateCustomerChatSuccess());
        return Promise.resolve(localString.chat.updateCustomerChatSuccess);
      })
      .catch( error => {
        console.warn("Failed to update customer chat info : ", error.response);
        dispatch(crudErrored(localString.chat.updateCustomerChatFailure));
        return Promise.reject(localString.chat.updateCustomerChatFailure);
      })
    }     
  }

  export const updateCustomerHeartbit = () => (dispatch, getState) => {
    const widgetId = _get(getState(),"widget.widgetId");
    dispatch(readLocalStorage(`${widgetId}fromIdentity`))
    .then( fromIdentity => {
      if(fromIdentity === null) {
        return;
      }
      else{
        dispatch(updateCustomerHeartbitPart2(fromIdentity));
      }
    })
  }
    
  const updateCustomerHeartbitPart2 = (fromIdentity) => (dispatch, getState) => {
    const reduxState = getState();
    const channel =  _get(reduxState, "chat.currentChannel", null);
    if(channel === null){
      return;
    }

    const customerChatObj = {"$currentDate": {"customer_heartbit": true}}
    bcApi.bidclips.customer_chat.patch(fromIdentity, customerChatObj)
    .then( response => {
          bcApi.bidclips.customer_chat.getById(fromIdentity)
          .then( res => {
            console.log("chat_ updateCustomerHeartbit getById : ", res);
            const currentTimeInMillis = _get(res.data,'customer_heartbit.$date');
            //This must exists as we just patched...
            const users = _get(res.data, 'user', []);
            //if invitation is not accepted in due time search user again...
            if(users !== null && users.length === 0){
              // const currentTimeInMillis = _get(res.data,'customer_heartbit.$date');
              console.warn('no provider user has joined...');
            }
            const currentToIdentity = getToIdentityFromRedux(reduxState);
            if(currentToIdentity === null ){
              console.warn('no to identity in hearbit');  
              dispatch(userHeartbitLost("No Provider Selected"));
              dispatch(completeChatWithReason(fromIdentity, 'missing_to_identity'));
            }
            else{
              console.log("chat_ currentToIdentity : ", currentToIdentity);
              //Get toIdentity time 
              bcApi.bidclips.user_chat_info.getById(currentToIdentity)
              .then(response => {
                console.log('chat_ currentToIdentity getById', response);
                let user_heartbit = _get(response,'data.heartbit.$date',0);
                if(user_heartbit === 0){
                  //try old data $numberLong for backward competibility
                  console.warn('working with old client... ', currentToIdentity, response);
                  user_heartbit = _get(response,'data.heartbit.$numberLong',0);
                }
                const diff = currentTimeInMillis - user_heartbit;
                console.log('chat_  heartbit check: ', currentTimeInMillis, user_heartbit, diff);

                  if(diff > 30000) {
                    console.log('chat_ userHeartbitLost');
                    //good time to update provider time stamp as well
                    //for trouble shooting purpose
                    dispatch(completeChatWithReason(fromIdentity, 'provider_heart_bit_lost'));
                    dispatch(userHeartbitLost("Connection Lost... Reconnecting"));
                  }
                  dispatch(userHeartbitSuccess());
              });
            }
          })
          .catch( err => {
              console.warn("Failed to retrieve Customer Chat record : ", err);
              dispatch(crudErrored(localString.chat.getFailed));
          })
    })
    .catch( error => {
      console.warn("Failed to update Customer Heartbit : ", error.response);
      dispatch(crudErrored(localString.chat.updateHeartbitFailed));
    })
  }