import { combineReducers, createReducer } from '../utils';
import {
    SET_CONTACT_LINK,
    SET_CONTACT_ALLOW_2_EDIT,
} from '../action-types';

const data = createReducer({}, {
    [SET_CONTACT_LINK] :(state,{data}) => data,
}) 

const allow2Edit = createReducer(false, {
    [SET_CONTACT_ALLOW_2_EDIT] :(state, { isAllow }) => isAllow,
})

const preference = combineReducers({
    allow2Edit,
}) 
  
export default combineReducers({
    data,
    preference,
})