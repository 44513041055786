import { combineReducers, createReducer } from "../utils";

import {
    DISCOUNT_LIST,
    DISCOUNT_TOTAL,
} from "../action-types";

const discountList = createReducer([],{
    [DISCOUNT_LIST] : (state, {discountList}) => 
    {
      console.log("discountList :: ",discountList);
      return discountList;
    }
  })
  
  const discountTotal = createReducer(0,{
    [DISCOUNT_TOTAL] : (state,{discountTotal}) => discountTotal
  })
  
  export default combineReducers({
    discountList,
    discountTotal,
  });
  