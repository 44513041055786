import React from 'react'
import styled from "styled-components";

import ButtonBase from '../../ui-component/buttonbase/baseButton'
import Box from '../../ui-component/box/box'
import Icon from '../../ui-component/icon/icon'

const Button = styled(ButtonBase)({
  opacity: 0.7,
  margin: 3,
  '&:hover' : {opacity: 1}
})

export const MinButton = (props) =>  {
  const { onClick, icon, } = props
  return(
    <Button onClick={onClick}>
      <Box w={24} h={24} bgColor="#fff" fontSize={12} borderRadius={12} display="flex" alignItems="center" justifyContent="center">
        <Icon variant="fas" icon={icon} />
      </Box>
    </Button>
  )
}

export default MinButton
