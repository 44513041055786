// import React from 'react'

export const getYIQColor = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if(result) {
    const red = parseInt(result[1], 16);
    const green = parseInt(result[2], 16);
    const blue = parseInt(result[3], 16)
    const yiq = ((red*299)+(green*587)+(blue*114))/1000;

    if (yiq >= 128) {
      return "#000000";
    }
    else {
      return "#FFFFFF";
    }
  }
  // else {
  //  return "#f00"
  // }
}