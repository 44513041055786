import _size from "lodash/size";
import _includes from "lodash/includes";
import _get from "lodash/get";
import _map from "lodash/map";
import _keys from "lodash/keys";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";


export const PhoneNumberPosition =(target)=>{
  

  let position = target.selectionStart; // Capture initial position

  let temp=target.value;

  target.value = changePhoneNumberFormat(target.value);  // This triggers the cursor to move.

  if(temp.length < target.value.length){
    target.selectionEnd = position+(target.value.length-temp.length);    // Set the cursor back to the initial position.
  }else if(temp.length > target.value.length){
    target.selectionEnd = position-(temp.length-target.value.length);;
  }else{
    target.selectionEnd = position;
  }
}

export const changePhoneNumberFormat =(values)=>{
    console.log("::::Phone Number are ::;;",values)
    if (!values) return values;
    let length=_size(values)
    
    let intlCode = _includes(values.slice(length-length,1),'+') ? "+":'';
    let phoneNumber = values.replace(/[+()-]/g,'');
    phoneNumber=phoneNumber.replace(/ /g, "")
    let size=_size(phoneNumber);
    console.log("phone number are ::::",phoneNumber);  
    if(!_includes(values,'+')){
      if (_size(phoneNumber)<= 4) return phoneNumber;
      if (_size(phoneNumber)===5) return `${phoneNumber.slice(size-size,1)}-${phoneNumber.slice(size-4,size)}`;
      // if (_size(phoneNumber) <= 6) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      if (_size(phoneNumber) <= 9 ) return `${phoneNumber.slice(size-size,size-4)}-${phoneNumber.slice(size-4,size)}`;
      if (_size(phoneNumber) === 10 ) return `(${phoneNumber.slice(size-size,size-7)}) ${phoneNumber.slice(size-7,size-4)}-${phoneNumber.slice(size-4,size)}`;

      if (_size(phoneNumber) > 10) return `${phoneNumber.slice(size-size,size-10)} (${phoneNumber.slice(size-10,(size-10)+3)}) ${phoneNumber.slice((size-10)+3,size-4)}-${phoneNumber.slice(size-4,size)}`;

    }else{

      if (_size(phoneNumber)<= 4) return `${intlCode}${phoneNumber}`;
      if (_size(phoneNumber)===5) return `${intlCode}${phoneNumber.slice(size-size,1)}-${phoneNumber.slice(size-4,size)}`;
      // if (_size(phoneNumber) <= 6) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      if (_size(phoneNumber) <= 9 ) return `${intlCode}${phoneNumber.slice(size-size,size-4)}-${phoneNumber.slice(size-4,size)}`;
      if (_size(phoneNumber) === 10 ) return `${intlCode} (${phoneNumber.slice(size-size,size-7)}) ${phoneNumber.slice(size-7,size-4)}-${phoneNumber.slice(size-4,size)}`;

      if (_size(phoneNumber) > 10) return `${intlCode}${phoneNumber.slice(size-size,size-10)} (${phoneNumber.slice(size-10,(size-10)+3)}) ${phoneNumber.slice((size-10)+3,size-4)}-${phoneNumber.slice(size-4,size)}`;
      
    }
}
export const formatPhoneNumber =(values)=>{
  let intlCode = _includes(values,'+') ? "+":'';
  let phoneNumber = values.replace(/[&\\#,+()$~%.'":*?<>{}-]/g, '');
  phoneNumber=phoneNumber.replace(/ /g, "")
  let phone =`${intlCode}${phoneNumber}`;  
  return phone;
}

export const repeatPhoneNumber =(jtElements,formData)=>{
  console.log("current jt DAta ::::",jtElements,formData)
  let getKey;
  let repeatPhone;
  let response=[];
  _map(jtElements, (JtElement,index)=>{
    if(_get(JtElement,"type","")==="Repeat"){
      getKey= _keys(_get(JtElement,"data_schema.properties",{}));
      console.log("repeatPhoneNumber ::: 1",getKey)
      _map(getKey, (val,index) => {
        if(!_isEmpty(_get(formData,val))) {
          if(_isArray(_get(formData,val))){
            if(_get(formData,val).length > 0){
              _map(_get(formData,val), (AnsweredQuestion) => {
                const NestedArrayObjectKeys = _keys(AnsweredQuestion);
                console.log("NestedArrayObjectKeys2 ::::",NestedArrayObjectKeys,_get(AnsweredQuestion,"bcCustomerPhone"))
                  if(!_isEmpty(_get(AnsweredQuestion,"bcCustomerPhone"))){
                    repeatPhone=_get(AnsweredQuestion,"bcCustomerPhone")
                    response.push({
                      getKey,
                      repeatPhone
                    });
                  }
              });
            }
          }
        }
      });
    }
  })

  return response;
}

