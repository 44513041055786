import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
 import { withRouter } from "react-router-dom";
 import Box from '../src/components/ui-component/box/box'
 import Loader from '../src/components/ui-component/loader/loader'

const LoaderAnimation = props => { return <Box p={15}><Loader>Loading</Loader></Box>; };

const Chat = lazy(() => import("./components/chat/form"));
const CommunicationList = lazy(() => import("./components/communication/list"));
const Quote = lazy(() => import("./components/quote/form"));
// const QuoteV2 = lazy(() => import("./components/quoteV2/form"));
const Quotev2 = lazy(() => import("./containers/quoteV2/quote/form"));
const PdfPage = lazy(() => import("./components/quote/action/pdfPage"));
const ServiceSearch = lazy(() => import("./components/journey-template-widget/form-service-search"));
const JobSuccess = lazy(() => import("./components/quote/job/sucessJob"));
const JobPresent = lazy(() => import("./components/quote/job/already_done"));
const JobFail = lazy(() => import("./components/quote/job/failJob"));
const BidView = lazy(() => import("./components/journey-template-widget/form.v2"));
const EditContact = lazy(() => import("./components/contact/EditContact"))
const ViewContact = lazy(() => import("./components/contact/ViewContact"))
const AddBidAdditionalContact = lazy(() => import("./components/contact/AddBidAdditionalContact"))

const NotFoundContact = lazy(() => import("./components/contact/NotFoundContact"));
const BidSuccess = lazy(() => import("./components/journey-template-widget/bid-success"));
const BidFail = lazy(() => import("./components/journey-template-widget/bid-fail"));
const QuoteView = lazy(() => import("./components/quote/job/viewQuoteReservation"));
const PaymentStep1 = lazy(() => import("./components/payments/step1/form"));
const ChatImage = lazy(() => import("./components/chatimage/editor"));
const Payment = lazy(() => import("./components/payments/step1/form"));
const Paymentsuccess = lazy(() => import("./components/payments/step1/paymentsuccess"));
const Paymentfail = lazy(() => import("./components/payments/step1/paymentfailed"));
const OptIn = lazy(() => import("./components/email-opt-in-preferance/opt-in-form"));
const OptOut = lazy(() => import("./components/email-opt-in-preferance/opt-out-form"));

// Mokup UI Link start
const Accordion = lazy(() => import("./components/refui/accordion"));
const ServiceRequest = lazy(() => import('./components/refui/serviceRequest/form'));
const CommunicationResponse = lazy(() => import("./components/refui/communication-response/form"));
const Amount = lazy(() => import('./components/refui/amount'));
const PaymentUI = lazy(() => import("./components/mockup-ui/payment/form"));
const ScheduleUI = lazy(() => import("./components/refui/schedule/form"));
const Features = lazy(() => import('./components/refui/features'));
const Addons = lazy(() => import('./components/refui/addons'));

// Mokup UI Link start
const NewServiceUI = lazy(() => import('./containers/mockup-ui/new-service/index'));
const BidViewUI = lazy(() => import('./containers/mockup-ui/bid/index'));
// const BidViewUI2 = lazy(() => import('./containers/mockup-ui/bid2/index'));
const ReviewPaymentUI = lazy(() => import('./containers/mockup-ui/bid/payment'));
const ServiceRequestCreate = lazy(() => import('./containers/mockup-ui/serviceRequest/index'));
const ServiceRequestSuccess = lazy(() => import('./containers/mockup-ui/serviceRequest/serviceRequestSuccess'))
const AddContactUI = lazy(() => import('./containers/mockup-ui/serviceRequest/addContact'))
// const BidViewUI = lazy(() => import('./components/refui/bid/index'));
const JourneyTemplateRJSFView = lazy(() => import('./components/journey-template-widget/journey-template-rjsf-view'));
//Live chat page UI
const LiveChatUI = lazy(() => import('./containers/mockup-ui/chat/chat'));


const Routes = () => {
  console.log('Routes called');
  return (
    <React.Fragment>
      <Suspense fallback={<LoaderAnimation />}>
        <Switch>
          <Route exact path="/" component={ServiceSearch} />
          <Route exact path="/search/:searchTerms" component={ServiceSearch} />
          <Route exact path="/widget/:widgetId" component={ServiceSearch} />
          <Route exact path="/bid/:id/:action" component={BidView} />
          <Route exact path="/bid/bid-success" component={BidSuccess} />
          <Route exact path="/bid/bid-fail" component={BidFail} />

          <Route exact path="/quote" component={Quote} />
          {/* <Route exact path="/quoteV2" component={QuoteV2} /> */}
          <Route exact path="/quotev2" component={Quotev2} />
          <Route exact path="/quote/:quoteId" component={Quote} />
          {/* <Route exact path="/quoteV2/:quoteId" component={QuoteV2} /> */}
          <Route exact path="/quotev2/:quoteId" component={Quotev2} />
          <Route exact path="/pdf" component={PdfPage} />

          <Route exact path="/communication" component={CommunicationList} />
          <Route exact path="/communication/:communicationId/:action" component={CommunicationList} />

          <Route exact path="/chat/:widgetId" component={Chat} />
          <Route exact path="/contact/edit/:id" component={EditContact}/>
          <Route exact path="/contact/view/:id" component={ViewContact}/>
          <Route exact path="/contact/addbidadditionalcontact" component={AddBidAdditionalContact}/>

          <Route exact path="/contact/notfoundcontact" component={NotFoundContact}/>

          <Route exact path="/job/successJob/:bidId/:jourId/:quoteId" component={JobSuccess} />
          <Route exact path="/job/alreadyPresent/:bidId/:jourId/:quoteId" component={JobPresent} />

          <Route exact path="/job/failJob/:reason" component={JobFail} />

          <Route exact path="/job/viewBid/:bidId/:jourId" component={BidView} />

          <Route exact path="/job/viewQuoteReservation/:quoteId" component={QuoteView} />

          <Route exact path="/payments/step1/form" component={PaymentStep1} />

          <Route exact path="/chatimage/image-annotation" component={ChatImage} />

          <Route exact path="/opt-in/:email" component={OptIn} />
          <Route exact path="/opt-out/:email" component={OptOut} />

          <Route exact path="/payment/:quoteId/:jobId" component={Payment} />
          <Route exact path="/payment/:quoteId/:jobId/:deductibleAmount" component={Payment} />
          
          <Route exact path="/paymentsuccess/:quoteId" component={Paymentsuccess} />
          <Route exact path="/paymentfail/:quoteId" component={Paymentfail} />
          <Route exact path="/journey-template-view" component={JourneyTemplateRJSFView} />

          <Route exact path="/refui/schedule/form" component={ScheduleUI} />
          <Route exact path="/refui/accordion" component={Accordion} />
          <Route exact path="/mockup-ui/payment/form" component={PaymentUI} />
          <Route exact path="/refui/communication-response/form" component={CommunicationResponse} />
          <Route exact path="/refui/amount" component={Amount} />
          <Route exact path="/refui/serviceRequest/form" component={ServiceRequest} />
          <Route exact path="/refui/features" component={Features} />
          <Route exact path="/refui/addons" component={Addons} />
          {/* Mockup UI  */}
          <Route exact path="/mockup-ui/new-service/index" component={NewServiceUI} />
          <Route exact path="/mockup-ui/bid/index" component={BidViewUI} />
          {/* <Route exact path="/mockup-ui/bid2/index" component={BidViewUI2} /> */}
          <Route exact path="/mockup-ui/bid/payment" component={ReviewPaymentUI} />
          <Route exact path="/mockup-ui/service-request-create/:action" component={ServiceRequestCreate} />
          <Route exact path="/mockup-ui/service-request-success" component={ServiceRequestSuccess} />
          <Route exact path="/mockup-ui/contact/:action" component={AddContactUI} />
          <Route exact path="/mockup-ui/contact/:action/:id" component={AddContactUI} />
          <Route exact path="/mockup-ui/chat/chat" component={LiveChatUI} />
          {/* always keep at bottom */}
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </React.Fragment>
  );
};
export default withRouter(Routes);
