import { combineReducers, createReducer } from '../utils';
import {
    BID_GET_SUCCESS,
    BID_GET_ERRORED,
    BID_RECORD_DELET,
    BID_PATCH_STATUS,
    BID_GET_CONTACT,
    BID_GET_ADDITIONAL_CONTACT
  } from '../action-types';

  const data = createReducer( {}, {
    [BID_GET_ERRORED ]: ( state, action  ) => {},
    [BID_GET_SUCCESS]: (state,{data}) => data,  
    [BID_RECORD_DELET]: () => {return{}},
  });
  const myContact = createReducer({},
    {
      [BID_GET_CONTACT]: (state,{contact}) => contact
    }
  )
  const additional_contacts = createReducer({},
    {
      [BID_GET_ADDITIONAL_CONTACT] : (state,{additional_contacts}) => additional_contacts
    })
 const bidPatchInProgress = createReducer(false, {
   [BID_PATCH_STATUS] :(state,{status}) => status,
   [BID_RECORD_DELET]: () => false,
 }) 
  const record = combineReducers({
    data,
    myContact,
    additional_contacts,
    bidPatchInProgress
  });
  
  export default combineReducers( {
    record,
  } );