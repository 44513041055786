import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Icon = (props) => {
  return(
    <FontAwesomeIcon icon={[props.variant, props.icon]} {...props} />
  )
}

export default Icon
