import React from 'react'

import Box from '../box/box'

export const Loader = (props) => {
  const { children } = props
  return(
    <Box className="text-loader" {...props}>
      {children}
      <span>.</span><span>.</span><span>.</span>
    </Box>
  )
}

export default Loader