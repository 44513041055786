import React from 'react'
import styled from "styled-components";
import _includes from 'lodash/includes'
import _isString from 'lodash/isString'

import Theme from '../../../themes/widgetTheme'

const Box = styled("div")({
  display: props => props.display && `${props.display}`,
  justifyContent: props => props.justifyContent && `${props.justifyContent}`,
  alignItems: props => props.alignItems && `${props.alignItems}`,
  flexGrow: props => props.flexGrow && `${props.flexGrow}`,
  flexShrink: props => props.flexShrink && `${props.flexShrink}`,
  flexDirection: props => props.flexDirection && props.flexShrink,
  
  margin: props => props.m && `${props.m}px`,
  marginRight: props => (props.mr || props.mx) && `${props.mr || props.mx}px`,
  marginLeft: props => (props.ml || props.mx) && `${props.ml || props.mx}px`,
  marginTop: props => (props.mt || props.my) && `${props.mt || props.my}px`,
  marginBottom: props => (props.mb || props.my) && `${props.mb || props.my}px`,
  
  padding: props => props.p && `${props.p}px`,
  paddingRight: props => (props.pr || props.px) && `${props.pr || props.px }px`,
  paddingLeft: props => (props.pl || props.px) && `${props.pl || props.px }px`,
  paddingTop: props => (props.pt || props.py) && `${props.pt || props.py }px`,
  paddingBottom: props => (props.pb || props.py) && `${props.pb || props.py }px`,

  height: props => props.h && `${_isString(props.h) ? props.h : `${props.h}px`}`,
  maxHeight: props => props.maxh && `${props.maxh}px`,
  minHeight: props => props.minh && `${props.minh}px`,
  width: props => (props.width || props.w) && `${props.width || props.w }px`,
  maxWidth: props => props.maxw && `${props.maxw}px`,
  minWidth: props => props.minw && `${props.minw}px`,
  border: props => props.border && `${props.border}px solid ${Theme.pallete.grey[400]}`,
  borderLeft: props => props.borderLeft && `${props.borderLeft}px`,
  borderRight: props => props.borderRight && `${props.borderRight}px`,
  borderTop: props => props.borderTop && `${props.borderTop}px`,
  borderBottom: props => props.borderBottom && `${props.borderBottom}px`,
  borderColor: props => props.borderColor && `${props.borderColor}`,
  borderRadius: props => props.borderRadius && `${props.borderRadius}px`,
  overflow: props => props.overflow && props.overflow,
  overflowX: props => props.overflowX && props.overflowX,
  overflowY: props => props.overflowY && props.overflowY,
  textOverflow: props => props.textOverflow && props.textOverflow,
  whiteSpace: props => props.whiteSpace && props.whiteSpace,
  
  textAlign: props => props.textAlign && `${props.textAlign}`,
  fontWeight: props => props.fontWeight && props.fontWeight,
  fontSize: props => props.fontSize && (_includes(props.fontSize, "rem") ? props.fontSize : `${props.fontSize}px`),
  fontStyle: props => props.fontStyle,
  fontFamily: props => props.fontFamily,
  wordBreak: props => props.wordBreak && props.wordBreak,

  backgroundColor: props => props.bgColor && `${props.bgColor}`,
  color: props => props.color && `${props.color}`,
  position: props => props.position && `${props.position}`,
  top: props => props.top && `${props.top}px`,
  right: props => props.right && `${props.right}px`,
  left: props => props.left && `${props.left}px`,
  bottom: props => props.bottom && `${props.bottom}px`,
  boxShadow: props => props.shadow && `${Theme.shadow[props.shadow]}`,
});
      
export const Boxed = (props) => {
  const { variant, children, className, ...rest } = props
  // const Box = variant || "div"
  return (
    <Box className={className} {...rest}>
      {children}
    </Box>
  )
}

export default Boxed
