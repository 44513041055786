import axios from "axios";

window.axios = axios;

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log('response :: ', response);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.group("axios response error handler");
  console.log('error :: ', {...error});
  console.log('status:: ', error.response.status);
  console.log('process.env.NODE_ENV :: ', process.env.NODE_ENV, process.env.NODE_ENV === "development");

  console.groupEnd();

  if(error.response.status >=500){
    if(process.env.NODE_ENV==="development"){
      return Promise.reject(error);
    }else{
      return Promise.reject(new Error(`Server Response : ${error.response.status}`));
    }
  }else{
    return Promise.reject(error);
  }
});


export default axios;


// axios({
//   method: 'get',
//   url: 'https://httpstat.us/502',
//   responseType: 'stream'
// })
//   .then(function (response) {
//       console.log('success response :: ', response);
//     }) 
