/**
 * External dependencies
 *
 * @format
 */
import { get } from 'lodash';

export function getChatToken( state ) {
	return get(state, 'chat.token',null);
}

export function getChatTokenTTL( state ) {
	return get(state, 'chat.ttl',null);
}

export function getFromIdentity( state ) {
	return get(state, 'chat.fromIdentity',null);
}

export function getToIdentity( state ) {
	return get(state, 'chat.toIdentity',null);
}

export function getChatMessages( state ) {
	return get(state, 'chat.chatMessages',[]);
}
