/**
 * External dependencies
 *
 * @format
 */


/**
 * Internal dependencies
 */
import { combineReducers, createReducer } from '../utils';
import {
  CHAT_TOKEN_REQUESTED,
  CHAT_TOKEN_ERRORED,
  CHAT_TOKEN_SUCCESS,
  CREATE_USER_SUCCESS,
  CHAT_CRUD_REQUESTED,
  CHAT_CRUD_ERRORED,
  FIND_ONLINE_PROVIDER_SUCCESS,
  ADD_CHAT_MESSAGE_SUCCESS,
  COMPLETE_CHAT_SUCCESS,
  UPDATE_CUSTOMER_CHAT_SUCCESS,
  PROVIDER_USER_HEARTBIT_LOST,
  PROVIDER_USER_HEARTBIT_SUCCESS,
  CHAT_STATUS_MESSAGE,
  AWSS3_UPLOAD_CHAT_API_SUCCESS,
  SET_CHANNEL_ID,
  SET_CURRENT_CHANNEL,SET_CHAT_CLIENT,
  SET_MESSAGE_INPUT_SUCCESS,
}
from '../action-types';

export const tokenErrorMessage = createReducer( null,
	{
    [CHAT_TOKEN_REQUESTED]:() => null,
    [CHAT_TOKEN_SUCCESS]:() => null,
    [CHAT_TOKEN_ERRORED] : ( state, {errorMessage} ) => errorMessage,
	}
);

export const token = createReducer( null,
	{
        [CHAT_TOKEN_REQUESTED] : () => null,
        [CHAT_TOKEN_SUCCESS] : (state, {token}) => token,
        [CHAT_TOKEN_ERRORED] : () => null,
        [COMPLETE_CHAT_SUCCESS]:() => null,
	}
);

export const ttl = createReducer( null,
	{
        [CHAT_TOKEN_REQUESTED] : () => null,
        [CHAT_TOKEN_SUCCESS] : (state, {ttl}) => ttl,
        [CHAT_TOKEN_ERRORED] : () => null,
        [COMPLETE_CHAT_SUCCESS]:() => null,
	}
);

export const fromIdentity = createReducer( null,
	{
        [CREATE_USER_SUCCESS] : (state, {fromIdentity}) =>{
                return fromIdentity},
        [COMPLETE_CHAT_SUCCESS]:() => null,
	}
);

export const chatClient = createReducer({},{
     [SET_CHAT_CLIENT] : (state,{chatClient}) => {
        console.log("c hat client in reucer.js :: ", chatClient)
             return chatClient;
     }   
})

export const toIdentity = createReducer( null,
	{
        [FIND_ONLINE_PROVIDER_SUCCESS] : (state, {toIdentity}) => toIdentity,
        [COMPLETE_CHAT_SUCCESS]:() => null,
	}
);

export const toUser = createReducer( '',
	{
        [FIND_ONLINE_PROVIDER_SUCCESS] : (state, {toUser}) => toUser,
        [COMPLETE_CHAT_SUCCESS]:() => '',
	}
);

export const chatErrorMessage = createReducer( null,
	{
        [CHAT_CRUD_REQUESTED]:() => null,
        [CREATE_USER_SUCCESS]:() => null,
        [COMPLETE_CHAT_SUCCESS]:() => null,
        [UPDATE_CUSTOMER_CHAT_SUCCESS]:() => null,
        [CHAT_CRUD_ERRORED] : (state, {errorMessage}) => errorMessage,
	}
);

export const chatStatusMessage = createReducer( "",
        {
        [CHAT_STATUS_MESSAGE] : (state, {statusMessage}) => statusMessage,
        [PROVIDER_USER_HEARTBIT_LOST] : (state, {heartbitFailMsg}) => heartbitFailMsg,
	}
);

export const heartbitLost = createReducer ( false, 
        {
                [PROVIDER_USER_HEARTBIT_LOST] : () => true,
                [PROVIDER_USER_HEARTBIT_SUCCESS] : () => false
        }
);

export const chatMessages = createReducer( [],
	{
        [ADD_CHAT_MESSAGE_SUCCESS] : (state, {chatMessages}) => chatMessages,
        [COMPLETE_CHAT_SUCCESS]:() => [],
	}
);

export const uploadImageURL = createReducer( "",
	{
        [AWSS3_UPLOAD_CHAT_API_SUCCESS] : (state, {path}) => path,
        //[COMPLETE_CHAT_SUCCESS]:() => "",
	}
);

export const channelId = createReducer( null, {
        [SET_CHANNEL_ID] : (state, {channelId}) => channelId,
        [SET_CURRENT_CHANNEL] : (state, {currentChannel}) => currentChannel.sid,
        [COMPLETE_CHAT_SUCCESS]: () => null
});

export const currentChannel = createReducer( null,
{
        [SET_CURRENT_CHANNEL] : (state, {currentChannel}) => currentChannel,
        [COMPLETE_CHAT_SUCCESS]:() => null
});

export const messageInput = createReducer([],
        {
               [SET_MESSAGE_INPUT_SUCCESS] : (state, {input}) => {
                console.log("inside reducer :: chat :: messageInput", input);
                       return input;
               }
        }
);


export default combineReducers( {
    tokenErrorMessage,
    token,
    ttl,
    fromIdentity,
    toIdentity,
    toUser,
    chatErrorMessage,
    chatMessages,
    chatStatusMessage,
    heartbitLost,
    uploadImageURL,
    channelId,
    currentChannel,
    chatClient,
    messageInput
} );
