import React from 'react'

import InputGroup from 'react-bootstrap/InputGroup'

export const InputGroupsAppend = (props) => {
  const { children } = props
  return(
    <InputGroup.Append {...props}>
      {children}
    </InputGroup.Append>
  )
}

export default InputGroupsAppend
