import React from 'react'
import Nav from 'react-bootstrap/Nav'

export const NavItem = (props) => {
  const { children } = props
  return(
    <Nav.Item {...props} >
      {children}
    </Nav.Item>
  )
}

export default NavItem