import React from 'react'

import Nav from 'react-bootstrap/Nav'

export const Navigation = (props) => {
  const { children } = props
  return(
    <Nav {...props}>
      {children}
    </Nav>
  )
}

export default Navigation