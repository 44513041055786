import axios from "./axios";
import { getToken } from "../../index";
//import { getIdToken } from '../../state/login/selectors';

let apiDomain = "";
export let cloudFrontEndPoint = '/gets3content'

if (process.env.NODE_ENV === "development") {
  apiDomain = "https://widget.bidclips.dev";
} 

const AttributeUrl = `${apiDomain}/api/bidclips/attribute`;
const WidgetActionTrackingUrl = `${apiDomain}/api/bidclips/widget_action_tracking`;
const itemCategoryurl = `${apiDomain}/api/bidclips/item_category`;
const AddOnsUrl = `${apiDomain}/api/bidclips/addons`;
const DiscountUrl = `${apiDomain}/api/bidclips/discount`;
const WidgetConfigurationUrl = `${apiDomain}/api/bidclips/widget_configuration`;
const ProviderUrl = `${apiDomain}/api/bidclips/provider`;
const ProvideruserUrl = `${apiDomain}/api/bidclips/provider_user`;
const BidUrl = `${apiDomain}/api/bidclips/bid`;
const QuoteUrl = `${apiDomain}/api/bidclips/quote`;
const ShopUrl = `${apiDomain}/api/bidclips/shop`;
const JobUrl = `${apiDomain}/api/bidclips/job`;
const JourneyTemplateUrl = `${apiDomain}/api/bidclips/journey_template`;
const JourneyTemplateVersionUrl = `${apiDomain}/api/bidclips/journey_template_version`;
const CustomerChatUrl = `${apiDomain}/api/bidclips/customer_chat`;
const AWSS3BidAttachmentUrl = `${apiDomain}/api/aws/s3/bid_attachment`;
const AWS_LAMBDA_URL = `${apiDomain}/api/aws/lambda`;
//const AWS_LAMBDA_URL = `http://localhost:8081/api/aws/lambda`;
const AWSS3UploadUrl = `${apiDomain}/api/aws/s3/upload`;
const AWSS3UploadUsingPresignedUrl = `${apiDomain}/api/aws/s3/getUrlForS3FileUpload`;
const AWSS3uploadchatAPI = `${apiDomain}/api/aws/s3/chat_image`;
const ChatTokenApiUrl = `${apiDomain}/api/twilio/chat/widget/authenticate`;
const CustomerChatApiUrl = `${apiDomain}/api/twilio/chat/widget/customer_chat`;
const onlineUserApiUrl = `${apiDomain}/api/twilio/chat/widget/online_provider`;
const userChatInfoApiUrl = `${apiDomain}/api/bidclips/user_chat_info`;
const ScheduleApiUrl = `${apiDomain}/api/shop/scheduling`;
const BidCustomerMessageUrl = `${apiDomain}/api/bidclips/bid_customer_message`;
const BidCustomerCommunicationUrl = `${apiDomain}/api/bidclips/bid_customer_communication`;
const ShopServiceUrl = `${apiDomain}/api/bidclips/shop_service`;
const serviceUrl = `${apiDomain}/api/bidclips/service`;
const ShopSlotReservationUrl = `${apiDomain}/api/bidclips/shop_slot_reservation`;
const EmailOptInPreferanceUrl = `${apiDomain}/api/bidclips/email_optin_preferance`;
const PaymentUrl = `${apiDomain}/api/payment`;
const AuthUrl = `${apiDomain}/api/authenticate/widget`;
//const taJarTaxCategoryUrl = `http://localhost:8085/api/taxjar/taxes`;
const taJarTaxCategoryUrl = `${apiDomain}/api/taxjar/taxes`;
// const PaymentUrl = `http://localhost:8081/api/payment`
const contactUrl = `${apiDomain}/api/bidclips/contact`;
const contactLinkUrl = `${apiDomain}/api/bidclips/contact_link`;

const getHeaders = () => {
  const token = getToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "No-Auth-Challenge": true
    },
  };
}
const bcApi = {
  bidclips: {
    authentication:{
      getJwt: credentials => axios.post(AuthUrl,credentials) 
    },
    widget_configuration: {
      getById: id => axios.get(`${WidgetConfigurationUrl}/${id}`,getHeaders()),

      getByServiceName: (widgetId, serviceName) => {
        const url2Call = `${WidgetConfigurationUrl}/_aggrs/journey_template_for_a_widget?avars={"varWidget_id":{$oid:"${widgetId}"},"varService_name":"${serviceName}"}`;
        return axios.get(url2Call,getHeaders()).then(res => res);
      },

      getPromotedServiceByWidgetId:(widgetId)=>{
        const searchServiceUrl = `${WidgetConfigurationUrl}/_aggrs/search_promoted_services?avars={"varWidgetId" : { "$oid" : "${widgetId}" }}`;
        return axios.get(searchServiceUrl,getHeaders());
      },

      getPromotedShopByWidgetId:(widgetId)=>{
        const searchServiceUrl = `${WidgetConfigurationUrl}/_aggrs/search_promoted_shops?avars={"varWidgetId" : { "$oid" : "${widgetId}" }}`;
        return axios.get(searchServiceUrl,getHeaders());
      },

      searchService: (widgetId, searchTerm, arrayofString) => {
        const tempsplitarray = arrayofString.join("|");
        const searchServiceUrl = `${WidgetConfigurationUrl}/_aggrs/search_services_v3?avars={ "varWidgetId" : { "$oid" : "${widgetId}" },"varsplitSearchTerm":"${tempsplitarray}"}`;
        return axios.get(searchServiceUrl,getHeaders());
      }
    },
    bid: {
      getBidStatus: id => {
        const config = getHeaders();
        config.params = {
          keys: JSON.stringify({status:1}),
        };
        return axios.get(`${BidUrl}/${id}`, config)
      },
      create: bidModel => {
        console.log("bid.create ", bidModel);
        return axios.post(BidUrl, bidModel,getHeaders()).then(res => res);
      },
      getByBRRN: brrn => {
        const config = getHeaders();
        var selector = { request_ref: brrn };
        config.params = { filter: JSON.stringify(selector), count: true };
        return axios.get(BidUrl, config).then(res => res);
      },
      patch: (id, patchContent) => {
          const headerObj = {
            headers : {
              ...getHeaders().headers,
            }
          }
          return axios.patch(`${BidUrl}/${id}`, patchContent,headerObj);
      },

      getById: id => axios.get(`${BidUrl}/${id}`,getHeaders())
    },
    provider: {
      getById: id => axios.get(`${ProviderUrl}/${id}`,getHeaders())
    },

    provider_user: {
      getById: oid =>
        axios.get(
          `${ProvideruserUrl}/${oid}?keys={image_url:1,first_name:1,last_name:1}`,getHeaders()
        )
    },

    aws: {
      cloudFront :apiDomain + cloudFrontEndPoint,
      lambda: {
        rotateImage: fileKey => {
          return axios.get(
            `${AWS_LAMBDA_URL}/rotate?key=${encodeURIComponent(fileKey)}`,getHeaders()
          );
        }
      },
      s3: {
        bidAttachment: (widgetId, bidId, fileBinary, progress) => {
          return axios
            .post(`${AWSS3BidAttachmentUrl}/${widgetId}/${bidId}`, fileBinary, getHeaders(),{
              onUploadProgress: progresses => {
                progress((progresses.loaded / progresses.total) * 100);
                console.log(
                  "progrsses in index.js",
                  (progresses.loaded / progresses.total) * 100
                );
              }
            })
        },

        upload: fileBinary => {
          console.log("aws s3 upload ", fileBinary);
          return axios.post(AWSS3UploadUrl, fileBinary,getHeaders());
        },

        /**
         * 
         * @deprecated no more used in project
         */
        getUrlForS3FileUpload: (widget_id, bid_id, objectKey) => {
          return axios.get(AWSS3UploadUsingPresignedUrl, getHeaders(),{
            params: {
              widget_id,
              bid_id,
              objectKey,
            }
          });
        },

        uploadChatImage: (fileBinary, Id) => {
          console.log("aws s3 upload ", fileBinary);
          return axios.post(AWSS3uploadchatAPI + "/" + Id, fileBinary,getHeaders());
        },
        
        AWSS3UploadUrlForPartialUpload: () => {
         return `${apiDomain}/api/aws`
        //  return `http://localhost:8081/api/aws`
        }
        
      }
    },

    chat: {
      getToken: (widgetId, identity) =>
        axios.get(`${ChatTokenApiUrl}/${widgetId}/${identity}`,getHeaders())
    },

    customer_chat: {
      post: (customerChatObj, widgetId) => axios.post(`${CustomerChatApiUrl}/${widgetId}`, customerChatObj,getHeaders()),
      patch: (id, patchContent) => axios.patch(`${CustomerChatUrl}/${id}`, patchContent,getHeaders()),
      getById: id => axios.get(`${CustomerChatUrl}/${id}`,getHeaders())
    },

    user_chat_info: {
      getById: id => axios.get(`${userChatInfoApiUrl}/${id}?keys={heartbit:1}`,getHeaders()),
      findOnlineProvider: widgetId =>  axios.get(`${onlineUserApiUrl}/${widgetId}`,getHeaders())
    },

    bid_customer_communication: {
      getAllByBidId: bidId => {
        const config = getHeaders();

        var selector = { "bid._id": bidId };
        config.params = {
          filter: JSON.stringify(selector),
          sort: "communication_time",
          count: true,
          time:new Date().toISOString()
        };
        return axios.get(BidCustomerCommunicationUrl, config);
      },

      patch: (identifier, patchContent) => axios.patch(`${BidCustomerCommunicationUrl}/*?filter=${JSON.stringify(identifier )}`,
          patchContent,
          getHeaders()
        )
    },

    bid_customer_message: {
      getById: id => axios.get(`${BidCustomerMessageUrl}/${id}`,getHeaders()),

      getByBidId: bidId => {
        const config = getHeaders();
        var selector = { "bid._id": bidId };
        config.params = {
          filter: JSON.stringify(selector),
          sort: "update_info.date_time",
          count: true,
          time:new Date().toISOString()
        };
        return axios.get(BidCustomerMessageUrl, config);
      },

      post: messageObj => axios.post(BidCustomerMessageUrl, messageObj,getHeaders()),

      patch: (identifier, patchContent) => axios.patch(`${BidCustomerMessageUrl}/*?filter=${JSON.stringify(identifier)}`,patchContent,getHeaders())
    },
    contact: {
      getbyId: id => {
        const config = getHeaders()
        return axios.get(`${contactUrl}/${id}`, config)
      } ,
      get: queryString => {
        const config = getHeaders()
        return axios.get(`${contactUrl}?${queryString}`, config)
      },
      add: values => {
        const config = getHeaders()
        return axios.post(`${contactUrl}`, values, config)
      },
      update: (id, values) => {
        const config = getHeaders()
        return axios.patch(`${contactUrl}/${id}`, values, config)
      },
      patchByFilter: (filter, patchContent) => axios.patch(`${contactUrl}/*?filter=${JSON.stringify(filter)}`,patchContent,getHeaders()),
    },
    shop: {
      getById: id => axios.get(`${ShopUrl}/${id}?time=${new Date().toISOString()}`,getHeaders())
    },

    quote: {
      getById: id => axios.get(`${QuoteUrl}/${id}`,getHeaders()),

      patch: (identifier, patchContent) => axios.patch(`${QuoteUrl}/*?filter=${JSON.stringify(identifier)}`,patchContent,getHeaders()),
    
      // http://localhost:8081/api/shop/scheduling/slots_matrix
      getSlotsMatrix: requestModel => axios.post(`${ScheduleApiUrl}/slots_matrix_v2`, requestModel,getHeaders()),
      updateMonthlySlotsData : (schedulingRequest,slotArray) => {
        
        const obj = {};
        obj.schedulingRequest  = schedulingRequest;
        obj.slotArray = slotArray;
        
        return axios.post(`${ScheduleApiUrl}/monthly_slots`, obj)
        // return axios.post(`http://localhost:8081/api/shop/scheduling/monthly_slots`,obj)
      },
      getMonthlySlots: (shopId,month,year) => axios.get(`${ShopSlotReservationUrl}/_aggrs/monthly_reservation_uri?avars={"varShopId":${JSON.stringify(shopId)},"varFromDate":{ "$date" : ${JSON.stringify(month)}},"varToDate":{ "$date" : ${JSON.stringify(year)}}}`, getHeaders()),
      getSlotDatabetWeenTwoDates : (shopId,from_date,toDate) => {
        const config = getHeaders();
        let selector = {
          $and : [
            { "slot_start_date_time":
              {
                  $gte: {$date : from_date},
                  $lt: {$date : toDate},
              }
            },{
              "shop._id" : {$oid : shopId},
            },
          ]
        }
        config.params = { filter: selector };
        return axios.get(ShopSlotReservationUrl,config)
      },
      getAllQuoteByBidRequestId: queryString => axios.get(`${QuoteUrl}?${queryString}`,getHeaders()),
      patchQuote : (id, data) => axios.patch(`${QuoteUrl}/${id}`,data,getHeaders())
    },

    payment: {
      getPaymentLog : (jobID) => {
        const config = getHeaders();
        return axios.get(`${JobUrl}/_aggrs/job_paid_amount?avars={"varJobId" : {"$oid" : "${jobID}"}}`, config);
      },
      getPaymentLogV2 : (jobID) => {
        const config = getHeaders();
        return axios.get(`${JobUrl}/_aggrs/job_paid_amount_v2?avars={"varJobId" : {"$oid" : "${jobID}"}}`, config);
      }
    },
    job: {
      put: (jobObj,id) => axios.put(`${JobUrl}/${id}`, jobObj,getHeaders()),
      reserve: jobObj => axios.post(`${ScheduleApiUrl}/reserve_slot`, jobObj),
      getById: id => axios.get(`${JobUrl}/${id}?time=${new Date().toISOString()}`,getHeaders()),
      reservation: jobReservationObj => axios.post(`${ScheduleApiUrl}/reserve_slot_v4`, jobReservationObj,getHeaders()),
      // axios.post(`http://localhost:8081/api/shop/scheduling/reserve_slot_v3`, jobReservationObj),

      getByQuoteId: quoteId => {
        const config = getHeaders();
        var selector = {
          "quote._id": { $oid: quoteId }
        };
        config.params = { filter: JSON.stringify(selector), count: true, time:new Date().toISOString() };
        return axios.get(JobUrl, config);
      },

      getByBidId: bidId => {
        const config = getHeaders();
        var selector = {
          "bid._id" : bidId,
          
        }
        config.params = { filter: JSON.stringify(selector), count: true, time:new Date().toISOString() };
        return axios.get(JobUrl, config);
      },
      patch: (id, patchContent) => axios.patch(`${JobUrl}/${id}`, patchContent,getHeaders()),
      searchInJob: queryString => axios.get(`${JobUrl}?${queryString}`,getHeaders())
    },

    journey_template: {
      getById: id => axios.get(`${JourneyTemplateUrl}/${id}`,getHeaders()),
      getJourneyTemplates: queryString => axios.get(`${JourneyTemplateUrl}?${queryString}`,getHeaders()),
    },

    journey_template_version: {
      findByVersion: (id, version) => {
        const config = getHeaders();
        var selector = {
          journey_template_id: id,
          template_version: version
        };

        config.params = { filter: selector };
        return axios.get(JourneyTemplateVersionUrl, config);
      }
    },

    attribute: {
      get: queryString => axios.get(`${AttributeUrl}?${queryString}`, getHeaders()),
    },
    widgetActionTracking : {
      post: widgetActionTrackingObj => {
        return axios.post(WidgetActionTrackingUrl, widgetActionTrackingObj,getHeaders())},  
    },

    item_category: {
      get: queryString => {
        return axios.get(`${itemCategoryurl}?${queryString}`, getHeaders())
      },
    },

    addons: {
      getByIds: ids => {
        const config = getHeaders();
        let selector = {
          _id: { $in: ids }
        };
        config.params = {
          filter: JSON.stringify(selector)
        };
        return axios.get(AddOnsUrl, config);
      }
    },

    discount: {
      getById: id => axios.get(`${DiscountUrl}/${id}`,getHeaders()),
      get: queryString => axios.get(`${DiscountUrl}?${queryString}`, getHeaders()),
    },

    shop_service: {
      getById: id => axios.get(`${ShopServiceUrl}/${id}`,getHeaders()),
      
      getShopServiceById: shopServiceId => {
        let id = { $oid: shopServiceId };
        const url2Call = `${ShopServiceUrl}/_aggrs/get_shop_service_by_id?avars={"shop_service_id":${JSON.stringify(
          id
        )}}`;
        return axios.get(url2Call,getHeaders());
      }
    },
    service:{
      getById: id => axios.get(`${serviceUrl}/${id}`,getHeaders()),
    },

    email_optin_preferance: {
      getById: filter => {
        const config = getHeaders();
        config.params = { filter: JSON.stringify(filter) };
        return axios.get(EmailOptInPreferanceUrl, config);
      },
      patchById: (id, patchContent) => {
        const config = getHeaders();        
        return axios.patch(`${EmailOptInPreferanceUrl}/${id}`, patchContent, config);
      },
      patch: (filter, patchContent) => {
        const config = getHeaders();
        config.params = { filter: JSON.stringify(filter) };
        return axios.patch(`${EmailOptInPreferanceUrl}/*`, patchContent, config);
      }      
    },

    payments: {
      payment: jobPaymentInfo => axios.post(`${PaymentUrl}/job_payment`, jobPaymentInfo,getHeaders())
    },
    tax_jar_tax_category: {
      getCalculatedTax:taxCategories => {
        return axios.post(taJarTaxCategoryUrl,taxCategories, getHeaders())
      },
    },
    contact_link: {
      getById: id => {        
        return axios.get(`${contactLinkUrl}/${id}`,getHeaders());
      },
      
    },
  }
};

export default bcApi;

//TODO : Munjal - move toward middleware https://github.com/svrcekmichal/redux-axios-middleware
