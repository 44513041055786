import{
  get as _get,
  has as _has,
}from 'lodash';

/**
 * Internal dependencies
 */
import {
  SLOTMATRIX_GET_SUCCESS,
  SLOTMATRIX_GET_ERRORED,
  SHOP_DATA_GET_SUCCESS,
  SLOT_AVIALIBILITY_FOR_MONTH
} from '../action-types';
import bcApi from '../../lib/bidclips/index';
import moment from "moment-timezone";

// import { getMenuSuccess } from '../menu/actions';

const getSlotMatrixSuccess = (response) => ({
    type : SLOTMATRIX_GET_SUCCESS,
    data : response,
  });

const getSlotMatrixErrored = (errorMessage) => ({
  type : SLOTMATRIX_GET_ERRORED,
  errorMessage,
});

const getByShopIdSuccess = (response) => ({
  type : SHOP_DATA_GET_SUCCESS,
  data : response,
});

export const getSlotsMatrix = (shopId, serviceLocation, fromDD, fromMMM, fromYYYY, forDays) => {
  return (dispatch) => {
    let shopIdToUse;
    if(_has(shopId, '$oid')){
      shopIdToUse = shopId.$oid;
    } else {
      shopIdToUse = shopId;
    }
    console.log("getSlotsMatrix: ", shopIdToUse, serviceLocation, fromDD, fromMMM, fromYYYY, forDays);
    let requestModel = {
      "shop":{
        "_id": shopIdToUse,
        "_ref": "shop"
      },
      "location": serviceLocation,
      "fromDD": fromDD,
      "fromMMM": fromMMM,
      "fromYYYY": fromYYYY,
      "forDays": forDays
    };
    console.log("requestModel: ", requestModel);
      return bcApi.bidclips.quote.getSlotsMatrix(requestModel)
      .then(response => {
          console.log("getSlotsMatrix response: ", response);
          dispatch(getSlotMatrixSuccess(_get(response, 'data', {})));
          return response.data;
      }).catch(error => {
        console.warn("Failed to getSlotsMatrix ", error);
        dispatch(getSlotMatrixErrored("Failed to getSlotsMatrix"));
    });
  }  
}

export const getById = (shopId) => {
  return (dispatch) => {
    return bcApi.bidclips.shop.getById(shopId)
      .then(response => {
        dispatch(getByShopIdSuccess(response));
      })
  };
}

export const getMonthlyScheduleInfo = (shopId,month,year) => {
  return (dispatch) => {
    
    const fromDate = new Date(year, month, 1);
    const toDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 1, 1);

    const fromDD = parseInt(moment(fromDate, "MM/DD/YYYY").format("DD"), 10);
    const fromMMM = moment(fromDate, "MM/DD/YYYY").format("MMM");
    const fromYYYY = parseInt(moment(fromDate, "MM/DD/YYYY").format("YYYY"), 10);

    console.log("getSlotsMatrix: temp",  fromDD, fromMMM, fromYYYY , fromDate, toDate);
    let requestModel = {
      shop: {
        _id: shopId,
        _ref: "shop",
      },
      fromDD: fromDD,
      fromMMM: fromMMM,
      fromYYYY: fromYYYY,
      location:"Shop",
      forDays:28,
    };
    console.log("getMonthlySlots :::",{$oid : shopId},fromDate,toDate);
    return bcApi.bidclips.quote.getMonthlySlots({$oid : shopId},fromDate,toDate)
        .then(res => {
          console.log("res.data._embedded :: ",res.data._embedded);
    return bcApi.bidclips.quote
    .updateMonthlySlotsData(requestModel,res.data._embedded)
    .then(data => {
        
        return bcApi.bidclips.quote.getMonthlySlots({$oid : shopId},fromDate,toDate)
        .then(response => {
          console.log("data on filter data :: ",response);
          dispatch(updateSlotAvailibility(_get(response,"data._embedded",[])));
          return _get(response,"data._embedded",[]);
        })
      })
    })
      .catch(error => {
        console.warn("Failed to getSlotsMatrix ", error);
      });
  };
    
  
}

export const updateSlotAvailibility = slotAvailibility => ({
  type: SLOT_AVIALIBILITY_FOR_MONTH,
  slotAvailibility,
});