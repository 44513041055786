/**
 * External dependencies
 *
 * @format
 */

import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import history from "../history"
import { connectRouter, routerMiddleware } from 'connected-react-router'

/**
 * Internal dependencies
 */
import { combineReducers } from './utils';
import widget from './widget/reducer';
import addons from './addons/reducer';
import quoteV2 from './quoteV2/reducer';
import chat from './chat/reducer';
import bidCustomerCommunication from './communication/reducer';
import quote from './quote/reducer';
import bid from './bid/reducer';
import journeyTemplate from './journeyTemplate/reducer';
import menu from './menu/reducer';
import shop from './shop/reducer';
import job from './job/reducer';
import discount from './discount/reducer';
import emailOptInPreferance from './email-opt-in-preferance/reducer';
import contactLink from './contact_link/reducer';

const reducers = {
  contactLink,
  widget,
  addons,
  quoteV2,
  chat,
  bidCustomerCommunication,
  quote,
  bid,
  journeyTemplate,
  menu,
  shop,
  job,
  discount,
  emailOptInPreferance,
};

export const reducer = combineReducers( reducers );

export function realodState( store ) {
	// if (localStorage.id_token) {
	// 	store.dispatch(loginRequestSuccess(true, localStorage.id_token));
	// 	store.dispatch(getAccountDetails());
	// }
}

/**
 * @typedef {Object} ReduxStore
 * @property {!Function} dispatch dispatches actions
 * @property {!Function} getState returns the current state tree
 * @property {Function} replaceReducers replaces the state reducers
 * @property {Function} subscribe attaches an event listener to state changes
 */

export function createReduxStore( initialState = {} ) {
	// const isBrowser = typeof window === 'object';
	// const isAudioSupported = typeof window === 'object' && typeof window.Audio === 'function';

	const middlewares = [
		thunkMiddleware,
		routerMiddleware(history),
		// We need the data layer middleware to be used as early
		// as possible, before any side effects.
		// The data layer dispatches actions on network events
		// including success, failure, and progress updates
		// Its way of issuing these is to wrap the originating action
		// with special meta and dispatch it again each time.
		// If another middleware jumps in before the data layer
		// then it could mistakenly trigger on those network
		// responses. Therefore we need to inject the data layer
		// as early as possible into the middleware chain.
		// noticesMiddleware,
	].filter( Boolean );

	const enhancers = [
		applyMiddleware( ...middlewares ),
	].filter( Boolean );

	const store = compose( ...enhancers )( createStore )( connectRouter(history)(reducer),initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() );

	realodState(store);
    
	return store;
	
}
