import React from 'react'
// import Image from 'react-bootstrap/Image'
import Media from '../media/media'
import Box from '../box/box'
import Theme from '../../../themes/widgetTheme'

// const avatarSize = {
//   small : 30,
//   medium: 40,
//   large: 50
//   // size === "small" ? 30 : size === "large" ? 50 : 40
// }
export const Avatar = (props) => {
  const { children, src, size, theme  } = props
  
  return (
    <Box overflow="hidden" display="flex" alignItems={src ? "stretch" : "center"}flexShrink={0} justifyContent="center"
      h={size === "small" ? 30 : size === "large" ? 50 : 40 } 
      w={size === "small" ? 30 : size === "large" ? 50 : 40} 
      fontSize={size === "small" ? 14 : size === "large" ? 24 : 20}
      borderRadius={size === "small" ? 30 : size === "large" ? 50 : 40 } 
      bgColor={theme === "primary" ? Theme.main.primary() : theme === "secondary" ? Theme.main.secondary() : Theme.pallete.white}
      >
      {
        src ?
        <Media src={src} fluid />
        :
        children
      }
    </Box>
  )
}

export default Avatar
