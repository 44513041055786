import React, { Component } from "react";
// import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// import { FormGroup, FormControl, InputGroup } from "react-bootstrap";
import FormControl from '../ui-component/form/formControl'
// import FormGroup from '../ui-component/form/formControl'
import InputGroup from '../ui-component/inputgroup/inputGroup'
import InputGroupAppend from '../ui-component/inputgroup/inputGroupAppend'
import Button from '../ui-component/button/button'

import Icon from '../ui-component/icon/icon'

class DevURLBar extends Component {
  constructor(props) {
    super(props);
    console.log("DevURLBar :: props :: ", props);
    this.state = {
      goPath: props.location.pathname
    };
    props.history.listen((location, action) => {
      this.setState({ action, goPath: location.pathname });
    });
  }

  onGoWithUrl = () => {
    this.props.history.push(this.state.goPath);
  };

  onRefresh = () => {
    window.location.reload();
  };

  onGoBack = () => {
    this.props.history.goBack();
  };
  onGoForward = () => {
    this.props.history.goForward();
  };

  render() {
    const { goPath } = this.state;
    const { history } = this.props;
    console.log("DevURLBar :: history.lenght :: ", history.length);
    return (
      <InputGroup>
        <InputGroupAppend>
          <Button variant="outline-secondary">
            <Icon variant="fas" icon="arrow-left" onClick={this.onGoBack} />  
          </Button>
        </InputGroupAppend>
        <InputGroupAppend>
          <Button variant="outline-secondary" onClick={this.onGoForward}>
            <Icon variant="fas" icon="arrow-right" />  
          </Button>
        </InputGroupAppend>
        <InputGroupAppend>
          <Button variant="outline-secondary">
            <Icon variant="fas" icon="globe-europe" />  
          </Button>
        </InputGroupAppend>
        <FormControl aria-describedby="basic-addon1" 
          value={goPath}
          onChange={e => this.setState({ goPath: e.target.value })}
          on
        />
        <InputGroupAppend>
          <Button variant="outline-secondary" onClick={this.onGoWithUrl}>
            <Icon variant="fas" icon="angle-right" />  
          </Button>
        </InputGroupAppend>
        <InputGroupAppend>
          <Button variant="outline-secondary" onClick={this.onRefresh}>
            <Icon variant="fas" icon="sync" />  
          </Button>
        </InputGroupAppend>
      </InputGroup>
    );
  }
}

export default withRouter(DevURLBar);
