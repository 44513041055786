/**
 * Internal dependencies
 */
import { combineReducers, createReducer } from '../utils';
import {
    QUOTE_GET_SUCCESS,
    QUOTE_GET_ERRORED,
    SET_SLOT_PREFERENCE_SUCCESS,
    GET_ADDITIONAL_QUOTE_SUCCESS,
    GET_RESERVED_QUOTE_SUCCESS,
    PROVIDER_GET_SUCCESS,
    PROVIDER_USER_SUCCESS,
    TAX_TOTAL,
    QUOTE_RECORD_CLEAR,
    GET_TAXJAR_CALCULATION,
    CLEAR_TAXJAR_CALCULATION,
    GET_TAXJAR_CALCULATION_MOBILE,
    TERMS_CONDITIONS_OLD,
  } from '../action-types';

  const data = createReducer( {}, {
    [ QUOTE_GET_ERRORED ]: ( state, action  ) => {},
    [ QUOTE_GET_SUCCESS ]: ( state, {items}  ) => items,
    [QUOTE_RECORD_CLEAR]: (state, { data }) => data,
  } );
  const providerdata = createReducer( {}, {
    [ PROVIDER_GET_SUCCESS ]: (state, {data}) => data,
} );
const provideruserdata = createReducer(null , {
  [ PROVIDER_USER_SUCCESS ]: (state, {data}) => data,
} );
  const additionalQuotes = createReducer( [], {
    [ GET_ADDITIONAL_QUOTE_SUCCESS ]: ( state, {data} ) => data,
  } );

  const reservedQuotes = createReducer( [], {
    [ GET_RESERVED_QUOTE_SUCCESS ]: ( state, {data} ) => data,
  } );

  const serviceLocation = createReducer( "", {
    [ SET_SLOT_PREFERENCE_SUCCESS ]: ( state, {serviceLocation}  ) => serviceLocation,
  } );

  const waitPreference = createReducer( "", {
    [ SET_SLOT_PREFERENCE_SUCCESS ]: ( state, {waitPreference}  ) => waitPreference,
  } );

  const selectedSlot = createReducer( {}, {
    [ SET_SLOT_PREFERENCE_SUCCESS ]: ( state, {selectedSlot}  ) => selectedSlot,
  } );

  const selectedIndex = createReducer( {}, {
    [ SET_SLOT_PREFERENCE_SUCCESS ]: ( state, {selectedIndex}  ) => selectedIndex ||-1,
  } );

  export const taxTotal = createReducer( 0, {
    [ TAX_TOTAL ]: ( state, {taxTotal}  ) => taxTotal,
  } );

  const taxjarCalculation = createReducer({},{
    [GET_TAXJAR_CALCULATION]: (state, {taxjarCalculation}) => taxjarCalculation,
    [CLEAR_TAXJAR_CALCULATION]: state => ({}),
  });

  const taxjarCalculationMobile = createReducer({},{
    [GET_TAXJAR_CALCULATION_MOBILE]: (state, {taxjarCalculationMobile}) => taxjarCalculationMobile,
    [CLEAR_TAXJAR_CALCULATION]: state => ({}),
  });

  export const updateTermsConditions = createReducer(false, {
    [TERMS_CONDITIONS_OLD]: (state, { termsConditions }) => termsConditions,
    [QUOTE_RECORD_CLEAR]: state => (false),
  });

  const record = combineReducers({
    data,
    additionalQuotes,
    reservedQuotes,
  });
   
  const slotPrefence = combineReducers({
    serviceLocation,
    waitPreference,
    selectedSlot,
    selectedIndex
  });

  export default combineReducers( {
    record,
    providerdata,
    provideruserdata,
    slotPrefence,
    taxTotal,
    taxjarCalculation,
    taxjarCalculationMobile,
    updateTermsConditions,
  } );