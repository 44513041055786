import React from 'react'
import styled from "styled-components";


const Button = styled('button')({
  background: 'transparent',
  border:'none',
  width:'100%',
  padding:0,
  color:'inherit',
  margin:0,
  outline:0,
  display:'inherit',
  borderRadius:0,
  justifyContent: props => props.justifyContent && props.justifyContent,
  textDecoration:'none',
  position: 'relative',
  webkitTapHighlightColor: 'transparent',  
    '&:hover' :{ opacity: 0.8 },
    '&:focus': { outline: 'none'},
    '&:disabled': { opacity: 0.35 }
})

export const BaseButton = (props) => {
  const { children } = props
  return (
    <Button {...props}>
      {children}
    </Button>
  )
}

export default BaseButton
