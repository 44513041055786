import { combineReducers, createReducer } from '../utils';
import {
    CHANGE_EMAIL_OPT_IN_PREFERANCE_REQUESTED,
    CHANGE_EMAIL_OPT_IN_PREFERANCE_SUCCESS,
    EMAIL_OPT_IN_PREFERANCE_CRUD_ERRORED,
}
from '../action-types';

export const emailOptInPrefErrorMessage = createReducer( '',
	{
    [CHANGE_EMAIL_OPT_IN_PREFERANCE_REQUESTED] : () => '',
    [CHANGE_EMAIL_OPT_IN_PREFERANCE_SUCCESS] : () => '',
    [EMAIL_OPT_IN_PREFERANCE_CRUD_ERRORED] : ( state, {errorMessage} ) => errorMessage,
	}
);

export default combineReducers( {
    emailOptInPrefErrorMessage,
} );