import React from 'react'

import Button from 'react-bootstrap/Button'

import Box from '../box/box'
import Icon from '../icon/icon'

const Buttons = props => {
  const { children, prepand, append, icon } = props
  return (
    <Button {...props}>
      <Box display="flex">
        {
          prepand &&
          <Box flexShrink={0} mr={5}>
            <Icon variant="fas" icon={icon} />
          </Box>
        }
        <Box flexGrow={1}>
          {children}
        </Box>
        {
          append &&
          <Box flexShrink={0} ml={5}>
           <Icon variant="fas" icon={icon} />
          </Box>
        }
      </Box>
    </Button>
  )
}

export default Buttons
