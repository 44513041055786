import React, { Component } from 'react';
import { render } from "react-dom";

import { Provider } from "react-redux";

import {createReduxStore} from './state';
// import { includes as _includes } from "lodash";
/**
 * Internal dependencies
 */
import App from './App.js';
import { ConnectedRouter } from 'connected-react-router'
import {
  get as _get,
  eq as _eq,
  isEmpty as _isEmpty,
  forEach as _forEach,
  split as _split,
} from "lodash";
import { processInitRequest } from './state/menu/actions'
import history from "./history"
import { 
  setNativeMode, 
  setShowWindowSizeButton,
  storeCallHref,
  storeDevicePlatform, 
  getAuthenticationToken,
  doWidgetOperation,
  setWindowState, 
  getById,
  postWidgetEvent,
  localStorageReadResponse
} from './state/widget/actions';
import dsBirdge from 'dsbridge';
// import hideConsole from './utils/consoleUtils';

// hideConsole();

export const store = createReduxStore();

export let widgetId = null;
let callerHref = null;
let initDone = false;

class WidgetApp extends Component {

  constructor(props){
    super(props);
    window.parent.postMessage({ type:"bci_getWidgetId"}, '*');
  }

  render() {
    // const basePath = window.location.pathname.includes("widget.html")? "/widget.html" : "/";
    // console.log("Inside WidgetApp js basePath:: ", basePath);
    // console.log("Inside WidgetApp js", this.props.context);
    // const widgetId = window.location.pathname.split("/")[1];
    // console.log(":: index.js :: widgetId found :: ", widgetId);
    return(
      <Provider store={store}>
        <ConnectedRouter basename={`/${widgetId}`} history={history}>
          <App context={this.props.context} widgetId={widgetId} />
        </ConnectedRouter>
      </Provider>
    );
  }
}

console.log("===========================");
console.log("index.js :: bidclips_widget :: ",document.getElementById("bidclips_widget"));
console.log("===========================");

const dispatchDoOperation = (opConfig) => {
  store.dispatch(doWidgetOperation(opConfig));
}

function initApp(){
  console.log("initApp.. ", initDone, widgetId, callerHref)
  if(initDone || widgetId === null || callerHref === null) {
    return;
  }
  render(<WidgetApp />, document.getElementById("bidclips_widget"));
  store.dispatch(storeCallHref(callerHref));
  store.dispatch(getAuthenticationToken(widgetId))
  .then(()=>{
     store.dispatch(processInitRequest(callerHref,widgetId));
     store.dispatch(getById(widgetId));
     initDone = true;
  })
}

function nativeDetation () {  
  const userAgentParams = _split(navigator.userAgent, ' ');

  const platformString = userAgentParams[userAgentParams.length - 2];
  const deviceIdParenth = userAgentParams[userAgentParams.length - 1];
  const deviceId = deviceIdParenth.substring(1, deviceIdParenth.length - 1);  
  const platform = platformString.match('android')
    ? 'android'
    : platformString.match('ios')
      ? 'ios'
      : 'web';
  
  console.log('platform :', platform);  
  console.log('deviceIdParenth :', deviceIdParenth);  
  console.log('deviceId in ServiceSelectionPage :', deviceId);

  const fromMobile = platform !== 'web';
  
  store.dispatch(setNativeMode(fromMobile));
  store.dispatch(setShowWindowSizeButton(!fromMobile));
  store.dispatch(storeDevicePlatform(platform));
  //bind to dsBridge
  dsBirdge.register('doWidgetOperation', dispatchDoOperation);
}

export const getToken = () => {
  const token = _get(store.getState(),'widget.authToken','');
  return token;
}

function iframeMessageProcessor(msg) {
  console.log("iframeMessageProcessor got message",msg,store.getState());  

  if("bc_getHref" === _get(msg, "data.type", "")){
    callerHref = _get(msg, "data.value", "");
    initApp();
  }
  if("bc_getWidgetId" === _get(msg, "data.type", "")){
    widgetId = _get(msg, "data.value", "");
    widgetId = widgetId.split("/")[3];
    console.log("widgetId id from index.js",widgetId);
    initApp();
  }

  //if widget is embedded in to application / as standalone window
  //not as iframe 
  const widgetIdx = window.location.href.indexOf("/widget/");
  if(widgetIdx > 0 && !initDone){
    console.log("Running widget for provider ",window.location.href);
    widgetId = window.location.href.substring(widgetIdx + "/widget/".length);
    // callerHref ="no_one";
    callerHref = window.location.href;
 // console.log("callerHref :: ", callerHref);
    initApp();
    store.dispatch(setWindowState("open"));
    nativeDetation();
  }

  if("bci_getOperations" === _get(msg, "data.type", "")){
    const indexof_bcwidget = _isEmpty(callerHref) ? -1 : callerHref.indexOf("#bcwidget=");
    const callerHref_after_bcwidget = indexof_bcwidget === -1 ? undefined : callerHref.substring( indexof_bcwidget + "#bcwidget=".length);
    if(!callerHref_after_bcwidget){
      const iframeWidgetOperationsString = _get(msg, "data.value", "");
      if(!_isEmpty(iframeWidgetOperationsString)){
        try {
          const operations=JSON.parse(iframeWidgetOperationsString);
          _forEach(operations, dispatchDoOperation);
        } catch (error) {
          console.error('iframe widget operations params has INVALID JSON :', error);
        }
      }
    } else {
      // not needed navigation in customStyle
      // adjust default setHeader, setFooter
      const iframeWidgetOperationsString = _get(msg, "data.value", "");
      if(!_isEmpty(iframeWidgetOperationsString)){
        try {
          const operations=JSON.parse(iframeWidgetOperationsString);
          _forEach(operations, (op) => {
            if(op.operation === "widget" && op.config.event === "customStyle") {
              dispatchDoOperation(op);
            } else if(op.operation === "setFooter") {
              dispatchDoOperation(op);
            } else if(op.operation === "setHeader") {
              dispatchDoOperation(op);
            } else if(op.operation === "setMenu") {
              dispatchDoOperation(op);
            }
          });
        } catch (error) {
          console.error('iframe widget operations params has INVALID JSON :', error);
        }
      }
    }
    postWidgetEvent("widgetReadyState", true);
  }

  if(initDone && widgetId !== null && callerHref !== null) {
    //TODO: Stop/Hide animation
    // eslint-disable-next-line no-undef
    $('#loader').hide();
  }
  if(_eq(_get(msg,"data.type"),'operation')){
    console.log('firing data.operation :', _get(msg,'data'));
    dispatchDoOperation(_get(msg,'data'));
  }

  if(_get(msg,"data.type") === "bc_read_localstorage"){
    store.dispatch(localStorageReadResponse(msg));
  }
}

window.addEventListener('message', iframeMessageProcessor);
//window.parent.postMessage({type:"bci_init"}, "*");  // moved bci_init call to index.html
window.parent.postMessage({type:"bci_getHref"}, "*");
window.parent.postMessage({type:"bci_getWidgetId"}, "*");
window.parent.postMessage({type:"bci_getOperations"}, "*");
console.log("Sent message to parent window...");