const ch = {
  menu: {
    dashboard: '仪表板',
    dashboard1: '仪表板一',
    dashboard2: '仪表板二',
    dashboard3: '仪表板三',
    uikit: '用户界面',
    typography: '活版印刷',
    buttons: '纽扣',
    icons: '圣像',
    cards: '牌',
    menus: '菜单',
    lists: '名单',
    boxes: '盒子',
    grid: '格',
    colors: '颜色',
    messages: '消息',
    scripts: '脚本',
    modal: '语气',
    alert: '警报',
    notification: '通知',
    popover: '酥料饼',
    badge: '徽章',
    tabs: '标签',
    toolbar: '工具栏',
    chip: '芯片',
    slider: '滑块',
    stepper: '步进',
    subheader: '子标题',
    datepicker: '日期选择器',
    forms: '形式',
    basicForm: '基本形式',
    formValidate: '表单验证',
    formElement: '形式元素',
    tables: '表',
    basicTable: '基本表',
    advancedTable: '高级表',
    dataTable: '数据表',
    charts: '图表',
    chart1: '图一',
    chart2: '图二',
    chart3: '图三',
    pages: '网页',
    profile: '简介',
    price: '价钱',
    products: '产品',
    extraPages: '额外页数',
    Login: '登录',
    lockScreen: '锁屏',
    error: '错误',
    menuLevel: '菜单级别',
    level1: '一级',
    level2: '二级',
    documents: '文件',
    autocomplete: '自动完成',
    progressbar: '进度条',
  },
  options: {
    headerText: '选项',
    themes: '主题',
    langauages: '语言',
    layout: '布局',
  },
};
export default ch;
