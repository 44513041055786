import React from 'react'

import InputGroup from 'react-bootstrap/InputGroup'

export const InputGroups = (props) => {
  const { children } = props
  return(
    <InputGroup {...props}>
      {children}
    </InputGroup>
  )
}

export default InputGroups
