import React, { Component } from "react";
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual,toNumber as _toNumber, isNil as _isNil} from "lodash";
import { connect } from "react-redux";
import asyncPoll from "react-async-poll";

import "react-bootstrap-typeahead/css/Typeahead.css";
import { withRouter } from "react-router-dom";
//import HumburgerMenu from "./components/humbermenu/menu";
import Navigation from "./components/nav/form";
import DevURLBar from "./components/DevURLBar";
import Avatar from "./components/ui-component/avatar/avatar";
import Box from "./components/ui-component/box/box";
import Media from "./components/ui-component/media/media"; 
import ButtonBase from './components/ui-component/buttonbase/baseButton'
import Icon from './components/ui-component/icon/icon'

import MinButton from './components/mockup-ui/main/min-button'
// import Title from "./components/ui-component/typography/title";
/**
 * Internal dependencies
 */

import Routes from "./routes";
import {
  getServiceByName,
  setWindowState,
  setWidgetId,
  setSelectedServiceName,
  onOpenWidgetOperation,
  handleCustomWidgetArgs,
  widgetActionTracking
} from "./state/widget/actions";

import {
  updateCustomerHeartbit,
} from './state/chat/actions.js';

import strings from "./lang/lang";

//import { ReactComponent as Logo } from './logo.svg'
// import "../node_modules/bootstrap/dist/js/bootstrap";
// import "bootstrap/dist/js/bootstrap";
// import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.css";
// import Glyphicon from 'react-bootstrap/Glyphicon';
import { getEnableUIorDisableUI } from "./state/utils";
// import Logo from './bidclips-widget-logo.png';
import Logo from './bidclips-logo.svg';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { cloudFrontURIImageURI } from "../src/utils/getCloudFrontUrl";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Theme from './themes/widgetTheme'
import {getYIQColor} from './themes/colorFunction'
// import themeDefault from "./theme-default";


library.add(fab, fas,)

function contentClass(isShow) {
  console.log("Test Content", isShow)
  if (isShow === "customStyle") {
    return ""
  }
  else if (isShow === "open" || isShow === "expand") {
    return "bc-chatWindow";
  }
  return "bc-hide";
}

function customContentClass(widgetState) {
  if(widgetState === "customHeight") {
    return "custom-height-widget";
  } else if (widgetState === "customStyle") {     
    return "test";
  }
  return "widget-wrapper";
}

function showMessangerIconClass(isShow) {
  if (isShow === "open" || isShow === "expand") {
    return "bc-hide";
  }
  return "";
  // return "bc-chatIcon1";
}
//var styles = getComputedStyle(document.documentElement);
//var colorValue = styles.getPropertyValue('--color');
//alert(colorValue);


function updateTimeStamp()
{
 localStorage.setItem("Time", new Date());
}
class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      toolbarOption: "journey_template", // TODO default should be: journey_template,
      headerHeight: 60,
      navHeight: 36,
      footerHeight: 24,
    };
    this.showMessangerClick = this.showMessangerClick.bind(this);
    this.hideMessangerClick = this.hideMessangerClick.bind(this);
    this.expandClick = this.expandClick.bind(this);
    this.onMenuSelection = this.onMenuSelection.bind(this);
    this.showChat = this.showChat.bind(this);

    console.log("----- udit :: widgetId :: ", this.props.widgetId);

    this.props.setWindowState(this.props.widgetWindowStatus);
    
    setTimeout(updateTimeStamp, 30000);  // 30 seconds after reload we will update time

   // this.registerServiceWorker();
  }

  // registerServiceWorker = () => {
  //    if(this.hasServerHost()){
  //    if ('serviceWorker' in navigator) {
  //      console.log('Service Worker is supported');
  //      navigator.serviceWorker.register('/serviceWorker.js', { scope: '/' })
  //        .then(swReg => {
  //          console.log("serviceWorker Register...", swReg);
  //        })
  //     }   
  //   }
  // }

  // hasServerHost = () => {
  //   if(window.location.hostname === "localhost" && window.location.protocol === "http:") {
  //      return false;
  //   }   
  //      return true;
  // }


  componentDidUpdate(prevProps,prevState){
    const currGtmID=_get(this.props,"data.shop_google_tag_manager_id",null);
    const prevGtmID=_get(prevProps,"data.shop_google_tag_manager_id",null);
    const currAuthId=_get(this.props,"authToken",null);
    const prevAuthId = _get(prevProps,"authToken",null)
    if(currGtmID !== prevGtmID){
      const gtmConfigurationElement = document.createElement("script");
      gtmConfigurationElement.async = true;
      gtmConfigurationElement.src = `https://www.googletagmanager.com/gtm.js?id=${currGtmID}`
      
      console.log('gtmConfigurationElement :: checking :', gtmConfigurationElement);
      document.head.append(gtmConfigurationElement);

      const gtmConfigurationElementNoScript = document.createElement("noscript");
      const gtmConfigurationElementIframe = document.createElement("iframe");
      gtmConfigurationElementIframe.async = true;
      gtmConfigurationElementIframe.src = `https://www.googletagmanager.com/ns.html?id=${currGtmID}`
      gtmConfigurationElementIframe.height = '0'
      gtmConfigurationElementIframe.width = '0'
      gtmConfigurationElementIframe.style = 'display:none;visibility:hidden'
      gtmConfigurationElementNoScript.appendChild(gtmConfigurationElementIframe)
      document.body.append(gtmConfigurationElementNoScript)
    }
    else if(currAuthId !== prevAuthId){
      
      const decodedUrl = _get(this.props,"callerHref");
      console.log('decodedUrl :: checking :', decodedUrl,this.props)
      if(decodedUrl.includes("#bcwidget=")){
        let splitedUrl = decodedUrl.split("#bcwidget=")
        const splitedEncodedUrl = btoa(splitedUrl[1])
        const encodedUrl =splitedUrl[0] + "#bcwidget=" + splitedEncodedUrl
        console.log("url:::",decodedUrl,encodedUrl,splitedUrl)
        const widgetActionObj = {
          encode_url : encodedUrl,
          decode_url : decodedUrl
        }
        widgetActionTracking(widgetActionObj)
      }
    }
    
    this.inActivityTime();
  }


  componentWillMount() {
    this.initializeWidgetConfig();
    // if(window.parent){
    //   let urlParameters = window.parent.window.location.href;
    //   if (urlParameters.includes("#bcwidget")) {
    //     this.setState({ toolbarOption: "communication" });
    //   }
    // }
  }

  inActivityTime = () => {
    // 1000 milliseconds = 1 second
  var time;
  var timeStamp;

  // DOM Events
  window.onload = resetTimer;
  document.onmousemove = resetTimer;   // on any activity we will call resetTimer and will clear the interval or timeout
  document.onkeydown = resetTimer;
  document.ontouchmove = resetTimer;	
  document.ontouchstart = resetTimer;
  document.ontouchend = resetTimer;

  function resetTimer(){
    clearInterval(time);
    clearTimeout(timeStamp);

  time = setInterval(activateChunk, !_isNil(localStorage.getItem('time_for_activate_chunk')) ? _toNumber(localStorage.getItem('time_for_activate_chunk')) : 3600000);  //1 hour
  timeStamp = setTimeout(updateTimeStamp, !_isNil(localStorage.getItem('time_for_updating_time_stamp_while_user_stays_inactive_for'))? _toNumber(localStorage.getItem('time_for_updating_time_stamp_while_user_stays_inactive_for')) : 3000); 

  }

  // storage event listner if any changes in locatstorage for specific key which are defined inside function
  window.addEventListener('storage', function(event){
    if (event.key === "reload_All_Tabs_To_Check_For_New_Chunk" || event.key === "reload_All_Tabs_After_SkipWaiting" ) { 
      window.location.reload(true);     
    }
  });

  /* here we will call activateChunk function on defined interval time to frequently check for new chunk */

  function activateChunk()  
  { 
      let time_At_X_Minute_Interval = new Date();
      console.log("time_At_X_Minute_Interval",time_At_X_Minute_Interval);
      let stored_Time_Of_Local_Storage = Date.parse(localStorage.getItem("Time"));
      console.log("stored_Time_Of_Local_Storage",stored_Time_Of_Local_Storage);
      let diffrence=  time_At_X_Minute_Interval - stored_Time_Of_Local_Storage;
      console.log("diffrence",diffrence);

        /* here this condition will prevent reloading tabs if user interacted recently and if no interaction till
           defined time then it will go towards activating new chunk */
        if(diffrence>= !_isNil(localStorage.getItem('time_for_checking_time_difference')) ? _toNumber(localStorage.getItem('time_for_checking_time_difference')) : 3480000)  // 58 minute
        {
          console.log("came here");
           /* also storage event listner will trigger and reload all
            tabs except current tab on set of localStorage Item */
          localStorage.setItem("reload_All_Tabs_To_Check_For_New_Chunk",Math.random());

          // specifically for only 1 tab which does not listen to the storage event call
          window.location.reload(true); 
          console.log("reloaded")     

          if ('serviceWorker' in navigator) {
          console.log("inside service worker container")
          navigator.serviceWorker.register('/serviceWorker.js', { scope: '/' })
          .then(swReg => {
            console.log('checking swReg',swReg)
              var newWorker = swReg.waiting ;
              console.log('checking new worker',newWorker)
              if(newWorker)
              {
                console.log("new worker is waiting you can merge now")
                /*self.skipWaiting(); not working that's why sending the postmessage of skipWaiting and 
                  will be listned to message event listner in serviceWorker.js file   */
                swReg.waiting.postMessage('SKIP_WAITING');
                /* set flag to reload all tabs except current tab after skipWaiting */ 
                localStorage.setItem("reload_All_Tabs_After_SkipWaiting",Math.random()); 
                window.location.reload(true); //for exception tab
                console.log("reloaded0")
              }
              else{
                console.log("No new Worker Found");
              }
           }) 
           console.log("not gone inside Registering new service worker");
         }
    }  
    else{
      console.log("Time is not enough to reload the tab");
    }
  }    
  };



  initializeWidgetConfig = () => {
    const { setWidgetId, widgetId } = this.props;

    setWidgetId(widgetId);
  };

  //  componentWillReceiveProps(nextProps){
  //   const urlParams=_get(nextProps, 'urlParameters' ,'');
  //   console.log("cwrp :: App.js form ::  received :: ",urlParams);
  //   this.setState(
  //     {
  //       urlParameters: urlParams,
  //     }
  //   ,()=> this.forceUpdate());
  // }

  onMenuSelection(selection) {
    //console.log("onMenuSelection selection: ", selection);
    this.setState({
      toolbarOption: selection
    });
  }

  showMessangerClick = () => {
    console.log("calling open");
    this.props.setWindowState("open");
  }

  showChat() {
    this.props.history.push(`/chat/${this.props.widgetId}`);
    // this.onMenuSelection("chat");
  }

  hideMessangerClick = () => {
    console.log("calling minimized");
    this.setState({ expanded: false }, () => {
      this.props.setWindowState("minimized");
    });
  }

  expandClick = (widgetActualStatus) => {
    console.log("calling expand :: state was :: ", this.state.expanded);

    let newExpandStatus =  this.state.expanded;
    if(widgetActualStatus === "customHeight") {
      newExpandStatus = false;
    }

    this.setState({ expanded: !newExpandStatus }, () => {
      this.props.setWindowState(this.state.expanded ? "expand" : "open");
    });
  }

  contentHeightCalculate = (isMenuVisible, isHeaderVisible, isFooterVisible) => {
    let heightRemove = 28;
    if(isMenuVisible) heightRemove = heightRemove + this.state.navHeight;
    if(isHeaderVisible) heightRemove = heightRemove + this.state.headerHeight;
    if(isFooterVisible) heightRemove = heightRemove + this.state.footerHeight;
    return `calc(100vh - ${heightRemove}px)`
  }

  removeIFrameFromProvider = () => {
    const objectTosend = {
      type:"remove-iframe-from-provider",
      value : {"removeFrame" : true}
    };
    window.parent && window.parent.postMessage(JSON.stringify(objectTosend), "*");
  }

  render() {

    const { devModeConfig: { urlBar }, isHeaderVisible, isMenuVisible, isFooterVisible } = this.props;
    const enableUI = getEnableUIorDisableUI();
    console.log("Rendering App js ....",this.props);
    console.log("Got devModeConfig : urlBar :: ", urlBar);
    console.log("Got widgetid: ", this.props.widgetId);
    console.log("Got servicename: ", this.props.serviceName);
    console.log(
      "this.props.widgetWindowStatus: ",
      this.props.widgetWindowStatus
    );
    console.log("after getById widgetTitleText: ", this.props.data);
    console.log("serviceDetails: ", this.props.serviceDetails, enableUI);
    // console.log("got url parameters in app.js render: ", this.props.urlParameters);

    const widgetMinimizeText = _get(this.props, "data.widgetMinimizeText");
    const defaultText = "Click here to get a bid";
    const isJourneyTEmplateViewPage = _isEqual(_get(this.props,"location.pathname"),"/journey-template-view");

    document.documentElement.style.setProperty("--background", this.props.data.headerBgColor);
    document.documentElement.style.setProperty("--secondarybg", this.props.data.subtitleTextColor);
    document.documentElement.style.setProperty("--color", this.props.data.titleTextColor);

    const {footerHeight, headerHeight} = this.state

    if (enableUI) {
      return (
        <MuiThemeProvider muiTheme={Theme}>
          <Box onClick={() => {
            if(!_isEmpty(this.props.onOpenWidgetOperation)) {
              this.props.handleCustomWidgetArgs(this.props.onOpenWidgetOperation);
              this.props.setOnOpenWidgetOperation();
              this.setState({ expanded: false }, () => {
                this.props.setWindowState("open");
              });
            }
          }}> 
            <Box bgColor={Theme.main.primary()} borderRadius={30} p={3}
              className={showMessangerIconClass(this.props.widgetWindowStatus)}
            >
              <ButtonBase onClick={this.showMessangerClick} >
                <Box display="flex" alignItems="center" fontSize={16} color={getYIQColor(Theme.main.primary())} >
                  <Box flexGrow={1} textAlign="center" >
                    {widgetMinimizeText !== '' ?
                      (widgetMinimizeText) : (defaultText)}
                  </Box>
                  <Box flexShrink={0} >
                    {this.props.data.minimizedWidgetLogo !== "" &&
                      undefined !== this.props.data.minimizedWidgetLogo ? (
                      <Avatar size="large" src={this.props.data.minimizedWidgetLogo} />
                    ) : (
                      <Avatar size="large">
                        <Icon variant="fas" icon="comment-alt" />
                      </Avatar>
                    )}
                  </Box>
                </Box>
              </ButtonBase>
            </Box>

            <Box shadow={25} overflow="hidden" borderRadius={8} bgColor={Theme.pallete.white} border={1} m={10} borderColor={Theme.main.primary()} className={contentClass(this.props.widgetWindowStatus)}>
              {urlBar && <DevURLBar />}
              {
                isHeaderVisible &&
                <Box display="flex" overflow="hidden" position="relative" bgColor={Theme.main.primary()} >
                  <Box display="flex" flexGrow={1} alignItems="center" h={headerHeight} px={10} >
                    <Box flexShrink={0} mr={10}>
                      <Avatar src={this.props.data.minimizedWidgetLogo} />
                    </Box>
                    <Box flexGrow={1} fontSize={16} fontWeight="bold" color={getYIQColor(Theme.main.primary())} fontFamily={_get(this.props,"data.font_family","")} >
                      {_get(this.props,"data.widgetSubTitleText")}
                    </Box>
                  </Box>
                  {/* only show sizing button when enabled for display */}
                  {this.props.showWindowSizeButtons &&
                    <Box flexShrink={0} px={5} >
                      <MinButton icon="minus" onClick={this.hideMessangerClick} />
                      <MinButton icon="expand-alt" onClick={() => this.expandClick()} />
                    </Box>
                  }
                </Box>
              }
              {
                isMenuVisible &&
                <Box>
                  <Navigation widgetId={this.props.widgetId} />
                </Box>
              }
              <Box className={customContentClass(this.props.widgetActualStatus)} id="wrap" 
                // h={isMenuVisible ? "calc(100vh - 162px)" : "calc(100vh - 126px)"}
                h={this.contentHeightCalculate(isMenuVisible, isHeaderVisible, isFooterVisible)}
              >
                {!_isEmpty(this.props.authToken) && <Routes />}
              </Box>
              {
                isFooterVisible &&
                <Box fontSize={10} h={footerHeight} border={1} borderLeft={0} borderRight={0} borderBottom={0} borderColor={Theme.pallete.grey[300]} display="flex" alignItems="center" justifyContent="center">
                  <Box>
                    {strings.common.poweredBy}
                  </Box>
                  <Box w={85}>
                    <a href="https://www.bidclips.com" rel="noopener noreferrer" target="_blank">
                      <Media src={Logo} />
                    </a>
                  </Box>
                </Box>
              }
            </Box>
          </Box>
        </MuiThemeProvider>
      );
    } else {
      return (
        <MuiThemeProvider muiTheme={Theme}>
          <Box onClick={() => {
            if(!_isEmpty(this.props.onOpenWidgetOperation)) {
              this.props.handleCustomWidgetArgs(this.props.onOpenWidgetOperation);
              this.props.setOnOpenWidgetOperation();
              this.setState({ expanded: false }, () => {
                this.props.setWindowState("open");
              });
            }
          }}> 
          <Box bgColor={Theme.main.primary()} borderRadius={30} p={3} className={showMessangerIconClass(this.props.widgetWindowStatus)} >
            <ButtonBase onClick={this.showMessangerClick} >
              <Box display="flex" alignItems="center" fontSize={16} color={getYIQColor(Theme.main.primary())} >
                <Box flexGrow={1} textAlign="center" fontFamily={_get(this.props,"data.font_family","")} fontWeight={_get(this.props,"data.font_weight","")} >
                  {widgetMinimizeText !== '' ?
                    (widgetMinimizeText) : (defaultText)}
                </Box>
                <Box flexShrink={0}>
                  {this.props.data.minimizedWidgetLogo !== "" &&
                    undefined !== this.props.data.minimizedWidgetLogo ? (
                    // <img src={getThumbnail(this.props.data.minimizedWidgetLogo)} className="" alt="" />
                    <Avatar size="large" src={this.props.data.minimizedWidgetLogo} />
                  ) : (
                    <Avatar size="large" >
                      <Icon variant="fas" icon="comment-alt" />
                    </Avatar>
                  )}
                </Box>
              </Box>
            </ButtonBase>
          </Box>

          <Box shadow={25} overflow="hidden" borderRadius={8} bgColor={Theme.pallete.white} border={1} m={10} borderColor={Theme.main.primary()}
            className={contentClass(this.props.widgetWindowStatus)}
          >
            {urlBar && <DevURLBar />}
            {isHeaderVisible &&
                <Box display="flex" overflow="hidden" position="relative" bgColor={Theme.main.primary()}  >
                  <Box display="flex" flexGrow={1} alignItems="center" h={headerHeight} px={10} color={getYIQColor(Theme.main.primary())} >
                    {this.props.data.expandedWidgetHeaderLogo !== "" &&
                      undefined !== this.props.data.expandedWidgetHeaderLogo ? (
                        <React.Fragment>
                          <Box flexShrink={0} mr={10}>
                            <Avatar src={cloudFrontURIImageURI(this.props.data.minimizedWidgetLogo)} />
                            {/* <img src={cloudFrontURIImageURI(this.props.data.expandedWidgetHeaderLogo)} alt="" />
                            <img src={this.props.data.expandedWidgetHeaderLogo} alt="" /> */}
                          </Box>
                          <Box flexGrow={1} fontSize={16} fontWeight="bold" color={getYIQColor(Theme.main.primary())} fontFamily={_get(this.props,"data.font_family","")}>
                            {_get(this.props,"data.widgetSubTitleText")}
                          </Box>
                        </React.Fragment>
                    ) : (
                      <div className="title-wrapper">
                        <div className="bc-ownerTitle" >
                          <strong>{_get(this.props,"data.widgetSubTitleText")}</strong>
                          <div className="bc-ownerSubject">
                            {_get(this.props,"data.widgetSubTitleText")}
                          </div>
                        </div>
                      </div>
                    )}
                  </Box>
                  {/* only show sizing button when enabled for display */}
                  {this.props.showWindowSizeButtons &&
                    <Box flexShrink={0} px={5}>
                      {!isJourneyTEmplateViewPage && <MinButton icon="minus" onClick={this.hideMessangerClick} />}
		                  {isJourneyTEmplateViewPage && <MinButton icon="times" onClick={this.removeIFrameFromProvider} />}				
                      <MinButton icon="expand-alt" onClick={() => this.expandClick(this.props.widgetActualStatus)} />
                    </Box>
                  }
                </Box>
    }
                {isMenuVisible &&
                  <Box>
                    <Navigation widgetId={this.props.widgetId} />
                  </Box>
                }
                <Box className={customContentClass(this.props.widgetActualStatus)} id="wrap" h={this.contentHeightCalculate(isMenuVisible, isHeaderVisible, isFooterVisible)}>
                  {!_isEmpty(this.props.authToken) && <Routes />}
                </Box>
                {isFooterVisible &&
                  <Box fontSize={10} h={footerHeight} border={1} borderLeft={0} borderRight={0} borderBottom={0} borderColor={Theme.pallete.grey[300]} display="flex" alignItems="center" justifyContent="center">
                    <Box>
                      {strings.common.poweredBy}
                    </Box>
                    <Box w={85}>
                      <a href="https://www.bidclips.com" rel="noopener noreferrer" target="_blank"><Media src={Logo} /></a>
                    </Box>
                  </Box>
                }
          </Box>
          </Box>
        </MuiThemeProvider>
          );
    }

  }
}

const onPollInterval = (props, dispatch) => {
  return props.updateCustomerHeartbit();
};

const mapStateToProps = (state, ownProps) => {
            let widgetWindowStatus = state.widget.widgetWindowStatus;
          const widgetActualStatus = state.widget.widgetWindowStatus;
          let showWindowSizeButtons = state.widget.showWindowSizeButtons;
          if(widgetWindowStatus === "customStyle" || widgetWindowStatus === "customHeight"){
            widgetWindowStatus = "open";
          // showWindowSizeButtons=false;
  }
          const updatedProps = {
            data: _get(state, "widget.widgetConfiguration.items", []),
          widgetWindowStatus,
          widgetActualStatus,          
          devModeConfig: state.widget.widgetConfiguration.devModeConfig,
          // widgetId: state.widget.widgetId,
          serviceName: state.widget.serviceName,
          serviceDetails: state.widget.serviceDetails,
          showWindowSizeButtons,
          authToken: _get(state,'widget.authToken',''),
          //chatCustomerId : state.chat.fromIdentity,
          //urlParameters : _get(state, 'widget.urlParameters', []),
          isHeaderVisible: state.widget.isHeaderVisible,
          isFooterVisible: state.widget.isFooterVisible,
          isMenuVisible: state.widget.isMenuVisible,
          onOpenWidgetOperation: state.widget.onOpenWidgetOperation,
          callerHref : _get(state,"widget.callHref")
  };
          // console.log("mapStateToProps in App.js ", updatedProps, state, ownProps);
          return updatedProps;
};

// Maps actions to props
const mapDispatchToProps = dispatch => {
 console.log("mapDispatchToProps in App.js", dispatch);
    return {
      setWindowState: windowState => dispatch(setWindowState(windowState)),
      getServiceByName: serviceName => dispatch(getServiceByName(serviceName)),
      setWidgetId: widgetId => dispatch(setWidgetId(widgetId)),
      setSelectedServiceName: serviceName =>
      dispatch(setSelectedServiceName(serviceName)),
      updateCustomerHeartbit : () => dispatch(updateCustomerHeartbit()),
      setOnOpenWidgetOperation : () => dispatch(onOpenWidgetOperation({})),
      handleCustomWidgetArgs:(args)=>dispatch(handleCustomWidgetArgs(args)),
    };
  };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  asyncPoll(10 * 1000, onPollInterval)(App)));
