import _split from "lodash/split";
import AwsS3MultiPart from "@uppy/aws-s3-multipart";
import Uppy from "@uppy/core";
import {get as _get, uniqBy as _uniqBy, replace as _replace, camelCase as _camelCase} from "lodash";
import _lowerCase from "lodash/lowerCase";
import bcApi from "../bidclips/index";
import {awsS3UploadError} from "../../state/widget/actions";
import axios from "axios";


const generateBlobWithName = (blobFile, fileKey, fileSuffix) => {


    console.group('generateBlobWithName');
    console.log("blobFile Type :: ", _lowerCase(blobFile.type) , blobFile);
    const name = _get(blobFile,"name","unknown");
    console.log("name ::  ", name);
    const fileExtension = _get(_split(_get(blobFile,"type",""),"/"),"1");
    console.log("fileExtension ::  ", fileExtension);

    const fileExtensionToSet = fileExtension ? `.${fileExtension}` : "";
    console.log("fileExtensionToSet ::  ", fileExtensionToSet);

    let fileName = _replace(`${Date.now()}_${_camelCase(fileSuffix)}_${_camelCase(fileKey)}_${_camelCase(name)}${fileExtensionToSet}`, " ", "_");


    console.log("fileName :: ", fileName);

    console.groupEnd();

    return { fileName, blobFile };
}

export const uploadBidAttachmentToS3PresignedUrl = (callback, dataUri, spotProperty, widgetId, bidId, resolve , dispatch , getStore) => {
    const { fileName, blobFile } = generateBlobWithName(dataUri, spotProperty, "video");

    const store = getStore();
    const uploadUrl = bcApi.bidclips.aws.s3.AWSS3UploadUrlForPartialUpload();
    
    const listParts = (file, object) => {
        const url = uploadUrl + "/s3/multipart/partlist";
        return axios.get(url, {
            params: {
                uploadId : object.uploadId,
                key : object.key,
            }
        }).then(response => {
            console.log("file , object ::: ", file , object , response);
            return response.data;
        });
    }

    const completUpload = (file, object) => {
        const key = object.key,
            uploadId = object.uploadId;
            
        let parts = object.parts;
        parts = _uniqBy(parts, "PartNumber");
        const filename = encodeURIComponent(key);
        const uploadIdEnc = encodeURIComponent(uploadId);
        const url = uploadUrl + "/s3/multipart/" + uploadIdEnc + "/complete?key=" + filename;
        console.log("url  ::: ",url , uploadUrl);
        return axios.post(url, {
                parts: parts
        }).then(response => {
            console.log("complete upload :: ", response , object);
            return response.data;
        })
    }

    const key = `bid-attachments/${widgetId}/${bidId}/${fileName}`;
    let counter = _get(store,"widget.awsS3UploadError.counter",0);
    const awssMultipart = new AwsS3MultiPart();
    const option = {
        limit : 2,
        companionUrl: uploadUrl,
        getChunkSize: awssMultipart.getChunkSize,
        createMultipartUpload: awssMultipart.createMultipartUpload,
        listParts: listParts,
        prepareUploadPart: awssMultipart.prepareUploadPart,
        // abortMultipartUpload: awssMultipart.abortMultipartUpload,
        completeMultipartUpload: completUpload,
    }

    const uppy = Uppy({
        id: 'uppy',
        allowMultipleUploads: true,
        meta: {
            key: key,
        },
        logger: Uppy.debugLogger

    });

    window.uppy = uppy;

    uppy.use(AwsS3MultiPart, option);

    uppy.addFile({
        data: blobFile,
        name: fileName,
        type: blobFile.type,
    });

    uppy.upload().then(result => {
        console.log("upload result :: ", result);
    });

    uppy.on('complete', (result) => {
        console.log("result on upload complete :: ", result);
        resolve(({
            key: spotProperty,
            url: result.successful[0].uploadURL,
        }));
    });

    uppy.on('error', (result) => {
        console.log("error on uploading file...  ", result , counter);
        
    });

    uppy.on('upload-error', (result, error, response) => {
        console.log("there was error on uploading file ", result, error, error.isNetworkError, response, counter);
        counter = _get(store,"widget.awsS3UploadError.counter",counter);
        if (counter === 10) {
            counter++;
            dispatch(awsS3UploadError({ counter, error_retry_failure: "failure" }));
        }
        if (counter < 10) {
            counter++;
            dispatch(awsS3UploadError({ counter, error_retry_failure: "error" }));
            uppy.retryAll()
            setTimeout(() => {
                //retrying false means its already retried so will now show retrying upload
                if(counter <= 10) {
                    dispatch(awsS3UploadError({ counter, error_retry_failure: "retry" }));
                }
            }, 5000)
            // uppy.retryUpload(result.id);
            //its started retrying so will saw upload Error message
        }
    });

    uppy.on('upload-retry', (result, result2) => {
        
        console.log("uupload retry outside of if ", result, result2);
    });

    uppy.on('upload-progress', (result, result2) => {
        callback && callback(bidId, spotProperty, (_get(result2, "bytesUploaded", 0) / _get(result2, "bytesTotal", 0) * 100));
        // console.log("result on upload complete :: ", result, result2, option.listParts);
    });



    // Keeping it there is no need to remove working code so just commenting it
    //     (axios({
    //         url: response.data,
    //         method: 'PUT',
    //         data: blobFile.blob,
    //         headers: { 'Content-Type': blobFile.blob.type },
    //         onUploadProgress: ProgressEvent => {
    //             console.log("progressEvent :: ",ProgressEvent);
    //             callback && callback( bidId,spotProperty, (ProgressEvent.loaded / ProgressEvent.total) * 100);
    //           },
    //     })
    //         .then(() => resolve(({
    //             key: spotProperty,
    //             url: response.data.split("?")[0]
    //         }))));
    // });
}
