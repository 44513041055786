/**
 * Internal dependencies
 */
import { combineReducers, createReducer } from '../utils';
import {
  SLOTMATRIX_GET_SUCCESS,
  SLOTMATRIX_GET_ERRORED,
  SHOP_DATA_GET_SUCCESS,
  SLOT_AVIALIBILITY_FOR_MONTH,
  } from '../action-types';

  import _get from "lodash/get";

  const data = createReducer( [], {
    [ SLOTMATRIX_GET_ERRORED ]: ( state, action  ) => [],
    [ SLOTMATRIX_GET_SUCCESS ]: ( state, {data}  ) => {
      console.log("slots matrix data in reducer: ", data);
      return data
    },
  } );

  const shopData = createReducer( {}, {
    [ SHOP_DATA_GET_SUCCESS ]: ( state, {data} ) => data,
  });

  const slotsMatrix = combineReducers({
    data,
    shopData,
  });

  const slotAvailibitityForMonth = createReducer([],{
    [SLOT_AVIALIBILITY_FOR_MONTH] : (state,{slotAvailibility}) => slotAvailibility
  })
  
const shopInfo = createReducer({}, {
  [SHOP_DATA_GET_SUCCESS]: (state, { data }) => _get(data, "data", {}),
});

  export default combineReducers( {
    slotsMatrix,
    slotAvailibitityForMonth,
    shopInfo
  } );