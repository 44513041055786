/**
 * Internal dependencies
 */
import { combineReducers, createReducer } from '../utils';
import {
    MENU_GET_SUCCESS,
    MENU_ADD_REMOVE_SUCCESS,
} from '../action-types';

const menuItemExists = (menuItems, newMenuItem) => {
    if(menuItems.length === 0) {
        return false
    }
    else {
        return menuItems.some((e) => e.description === newMenuItem.description);    
    }    
}

const list = createReducer([], {
    [MENU_GET_SUCCESS]: (state, {items}) => {
        if (menuItemExists(state, items)) {
            return state;
          } else {
            return [...state, items];
          }
    },
    [MENU_ADD_REMOVE_SUCCESS]: (state, {items}) => items,
});

export default combineReducers({
    list,
})