import { combineReducers, createReducer } from "../utils";

import {
    ADDONS_CRUD_ERRORED,
    ADDONS_GET_DETAILS_SUCCESS,
    ADDON_ADD_SUCCESS,
    ADDON_REMOVE_SUCCESS,
    ADDON_UPDATE_DISCOUNT_TOTAL_SUCCESS,
    ADDDON_REQUESTED,
    ADDONS_DISCOUNT_ADDED,
} from "../action-types";


const errorMessage = createReducer(null, {
    [ADDONS_CRUD_ERRORED]: (state, { errorMessage }) => errorMessage,
    [ADDONS_GET_DETAILS_SUCCESS]: () => null,
    [ADDDON_REQUESTED]: () => null,
});

const addOnsData = createReducer([], {
    [ADDONS_CRUD_ERRORED]: (state, action) => [],
    [ADDDON_REQUESTED]: (state, action) => [],
    [ADDONS_GET_DETAILS_SUCCESS]: (state, { addOnsData }) => addOnsData,
});

const addon_count = createReducer(0, {
    [ADDONS_CRUD_ERRORED]: (state, action) => 0,
    [ADDDON_REQUESTED]: (state, action) => 0,
    [ADDON_ADD_SUCCESS]: (state, { addon_count }) =>{
        console.log("addons :: reducer :: count",addon_count)
        return addon_count;
        
    } ,
    [ADDON_REMOVE_SUCCESS]: (state, { addon_count }) => addon_count,
});

const addon_total = createReducer(0, {
    [ADDONS_CRUD_ERRORED]: (state, action) => 0,
    [ADDDON_REQUESTED]: (state, action) => 0,
    [ADDON_ADD_SUCCESS]: (state, { addon_total }) =>{

        console.log("addons :: reducer :: total",addon_total)
        return addon_total
    } ,
    [ADDON_REMOVE_SUCCESS]: (state, { addon_total }) => addon_total,
});

const addon_discount_total = createReducer(0, {
    [ADDONS_CRUD_ERRORED]: (state, action) => 0,
    [ADDDON_REQUESTED]: (state, action) => 0,
    [ADDON_UPDATE_DISCOUNT_TOTAL_SUCCESS]: (state, { addon_discount_total }) => addon_discount_total,
});

const selected_addons = createReducer([], {
    [ADDONS_CRUD_ERRORED]: (state, action) => [],
    [ADDDON_REQUESTED]: (state, action) => [],
    [ADDON_ADD_SUCCESS]: (state, { selected_addons }) => {
        console.log("addons :: reducer :: selected_addons",selected_addons);
        return selected_addons;
    },
    [ADDON_REMOVE_SUCCESS]: (state, { selected_addons }) => selected_addons,
});

const addonsDiscountAdded = createReducer(false, {
    [ADDONS_DISCOUNT_ADDED]: (state, { addonsDiscountAdded }) => addonsDiscountAdded
});
export default combineReducers({
    errorMessage,
    addOnsData,
    addon_count,
    addon_total,
    addon_discount_total,
    selected_addons,
    addonsDiscountAdded,
});