import React from 'react'

import Image from 'react-bootstrap/Image'

export const Media = (props) => {
  return (
    <Image {...props} />
  )
}

export default Media
