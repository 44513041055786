/**
 * Internal dependencies
 *
 * @format
 */

import createConfig from './create-config';

const configData = {}

//TODO: Uncomment this and bring configuration capabilities back
// /**
//  * Manages config flags for various deployment builds
//  * @module config/index
//  */
// if ( 'undefined' === typeof window || ! window.configData ) {
// 	throw new ReferenceError(
// 		'No configuration was found: please see client/config/README.md for more information'
// 	);
// }

// const configData = window.configData;

// if ( process.env.NODE_ENV === 'development' || configData.env_id === 'stage' ) {
// 	const match =
// 		document.location.search && document.location.search.match( /[?&]flags=([^&]+)(&|$)/ );
// 	if ( match ) {
// 		const flags = match[ 1 ].split( ',' );
// 		flags.forEach( flagRaw => {
// 			const flag = flagRaw.replace( /^[-+]/, '' );
// 			const enabled = ! /^-/.test( flagRaw );
// 			configData.features[ flag ] = enabled;
// 			console.log(
// 				// eslint-disable-line no-console
// 				'%cConfig flag %s via URL: %s',
// 				'font-weight: bold;',
// 				enabled ? 'enabled' : 'disabled',
// 				flag
// 			);
// 		} );
// 	}
// }

export default createConfig( configData );
