/**
 * Internal dependencies
 */
import {
    MENU_GET_SUCCESS,
    MENU_ADD_REMOVE_SUCCESS,
    EMAIL_OPT_IN_PREFERANCE_CRUD_ERRORED,
} from '../action-types';

import {
    transform as _transform,
    split as _split,
    assign as _assign,
    filter as _filter,
    findIndex as _findIndex,
    // map as _map,
    // get as _get,
  } from 'lodash';
import _isEmpty from "lodash/isEmpty";
import _compact from "lodash/compact";
import _map from "lodash/map";
import localString from "../../lang/lang";

import history from '../../history'

import {
    setWindowState,
    setDevMode,
    changeNavIndex,
   } from "../widget/actions";

import { getMenuList as getMenuListFromRedux } from './selectors';
import { getContactLinkById } from '../contact_link/actions';

export const addAndRemoveItemFromMenuSuccess = (items) => ({
    type: MENU_ADD_REMOVE_SUCCESS,
    items
});

export const addAndRemoveItemFromMenu = (objectToAdd, descriptionToRemove) => (dispatch, getState) => {
    const existingList = getMenuListFromRedux(getState());
    let filteredList = _filter(existingList, el => el.description !== descriptionToRemove);
    
    const index = _findIndex(filteredList, fl => fl.description === objectToAdd.description);
    if(index === -1) {
        filteredList.push(objectToAdd);
    } else {
        //TODO: make objectToAdd menu as active
    }
    dispatch(addAndRemoveItemFromMenuSuccess(filteredList));
}

const crudErrored = (errorMessage) => ({ type: EMAIL_OPT_IN_PREFERANCE_CRUD_ERRORED, errorMessage })

export const getMenuSuccess = (response) => ({
    type: MENU_GET_SUCCESS,
    items: response,
});

const compactParams = (paramsObj) => {
    if( _isEmpty(paramsObj) ){
        return paramsObj;
    }
    return _compact(  _map(paramsObj, (key, value)=>{
        if(!_isEmpty( key)){
            return value + ":" + key;
        }
        return undefined;

    })).join(";");
}

const expandParams = (urlParameters) => {
    console.log("expandParams urlParameters: ", urlParameters);
    let expandedURLParameters = {};

    if(undefined !== urlParameters && null !== urlParameters){

      let key = urlParameters.substring(urlParameters.lastIndexOf("#")+1,urlParameters.lastIndexOf("="));
      console.log("key: ", key);

      if(key === "bcwidget"){
        let onlyParams = urlParameters.substring(urlParameters.lastIndexOf("=") + 1)
        console.log("onlyParams: ", onlyParams);

        expandedURLParameters = _transform(_split(onlyParams,";"), (r,v) => {
          //console.log(v);
          const p=_split(v,":");
          //console.log("p : ", p, {[p[0]]: p[1]});
          _assign(r,{[p[0]]: p[1]});
        } , {})

      }
    }

    console.log("expandedURLParameters: ", expandedURLParameters);
    return expandedURLParameters;
 };


/**
 * Widget URL Processing - see 
 * https://thecaseygroup.atlassian.net/wiki/spaces/BID/pages/101417126/Widget+Tech+Documentation
 * @param {*} 
 */
export const processInitRequest = (callerHref,widgetId, isProcess=true) => {
    return (dispatch) => {
        console.log("callerHref", callerHref);
        const indexof_bcwidget = callerHref.indexOf("#bcwidget=");
        let windowSize = "";
        if(indexof_bcwidget === -1) {
            return;
        }
        const callerHref_after_bcwidget = callerHref.substring( indexof_bcwidget + "#bcwidget=".length);
        
        if(callerHref_after_bcwidget.includes("s:e")) {
            windowSize="expand"
        }else if(callerHref_after_bcwidget.includes("s:m")) {
            windowSize="minimize"
        }else{
            windowSize="open"
        }
        console.log("Window Size ::",windowSize);
        if(callerHref_after_bcwidget.includes("v:q2")) {
            console.log("going to quote...", history)
            let paramsObj = expandParams(callerHref)
            
            let menuDetails = {
                description: "My Bids",
                path: `/quoteV2/${paramsObj.e}`
            };
            dispatch(changeNavIndex(2));
            dispatch(getMenuSuccess(menuDetails));
            dispatch(setWindowState(windowSize));
            //Ref : https://github.com/ReactTraining/react-router/issues/3498
            history.push(`/quoteV2/${paramsObj.e}`);
        } else if(callerHref_after_bcwidget.includes("v:q")) {
            //then goto quote
            console.log("going to quote...", history)
            let paramsObj = expandParams(callerHref)
            
            let menuDetails = {
                description: "My Bids",
                path: `/quote/${paramsObj.e}`
            };
            dispatch(changeNavIndex(2));
            dispatch(getMenuSuccess(menuDetails));
            dispatch(setWindowState(windowSize));
            //Ref : https://github.com/ReactTraining/react-router/issues/3498
            history.push(`/quote/${paramsObj.e}`);
        }
        if(callerHref_after_bcwidget.includes("dev:urlBar")) {
          dispatch(setDevMode({urlBar: true}));
          dispatch(setWindowState(windowSize));
        }
        if(callerHref_after_bcwidget.includes("v:c")) {
            //then goto communication
            console.log("going to communication...", history)
            let paramsObj = expandParams(callerHref)
            console.log("paramsObj",paramsObj);
            let menuDetails = {
                description: "Messages",
                path: `/communication/${paramsObj.e}/bidMessageId`
            };
            dispatch(changeNavIndex(2));
            dispatch(getMenuSuccess(menuDetails));
            dispatch(setWindowState(windowSize));
            //Ref : https://github.com/ReactTraining/react-router/issues/3498
            history.push(`/communication/${paramsObj.e}/bidMessageId`);
        }
        if (callerHref_after_bcwidget.includes("v:m")) {
            //then goto communication
            let paramsObj = expandParams(callerHref)
            console.log("navigation of communication from bidId", history, paramsObj)
            const navigate = `/communication/${paramsObj.e}/bidId`;
            let menuDetails = {
                description: "Messages",
                path: navigate
            };
            dispatch(changeNavIndex(2));
            dispatch(getMenuSuccess(menuDetails));
            dispatch(setWindowState(windowSize));
            history.push(navigate);
        }
        if(callerHref_after_bcwidget.includes("v:k") && isProcess) {
            let paramsObj = expandParams(callerHref)
            const contactLinkId = paramsObj.e;
            if(!_isEmpty(contactLinkId)) {
                dispatch(getContactLinkById(contactLinkId))
                .then((data) => {
                    if(_isEmpty(data)){
                        return null;
                    }
                    const { entity, entity_id } = data;
                    
                    paramsObj.e = entity_id;
                    paramsObj.v = entity;

                    let originalWidgetLink = "#bcwidget=" + compactParams(paramsObj);                    
                    console.log("content link information", paramsObj, data, entity, entity_id, originalWidgetLink);
                    return dispatch(processInitRequest(originalWidgetLink, widgetId, false));
                });
            }
        }

        if(callerHref_after_bcwidget.includes("v:b")) {
            //then goto bid
            console.log("going to bid request...", history)
            let paramsObj = expandParams(callerHref)

            let menuDetails = {
                description: "View Service Request",
                path: `/bid/${paramsObj.e}/bidId`
            };
            dispatch(changeNavIndex(2));
            dispatch(getMenuSuccess(menuDetails));
            dispatch(setWindowState(windowSize));
            //Ref : https://github.com/ReactTraining/react-router/issues/3498
            history.push(`/bid/${paramsObj.e}/bidId`);
        }
        if(callerHref_after_bcwidget.includes("v:i")) {
            //then goto opt-in
            console.log("going to opt-in...", history)
            let paramsObj = expandParams(callerHref)
            
            let menuDetails = {
                description: "Subscription",
                path: `/opt-in/${paramsObj.e}`
            };
            dispatch(changeNavIndex(2));
            dispatch(getMenuSuccess(menuDetails));
            dispatch(setWindowState(windowSize));

            const contactLinkId = paramsObj.k;
            if (!_isEmpty(contactLinkId)) {
                dispatch(getContactLinkById(contactLinkId))
                    .then(() => {
                        //Ref : https://github.com/ReactTraining/react-router/issues/3498
                        history.push(`/opt-in/${paramsObj.e}`);
                    }).catch(()=>{
                        dispatch(crudErrored(localString.emailOptInPreferance.changeEmailOptInPreferanceFailure));
                        history.push(`/opt-in/${paramsObj.e}`);
                    });
            } else {
                dispatch(crudErrored(localString.emailOptInPreferance.changeEmailOptInPreferanceFailure));
                history.push(`/opt-in/${paramsObj.e}`);
            }

        }
        if(callerHref_after_bcwidget.includes("v:o")) {
            //then goto opt-out
            console.log("going to opt-out...", history)
            let paramsObj = expandParams(callerHref)
            
            let menuDetails = {
                description: "Unsubscribe",
                path: `/opt-out/${paramsObj.e}`
            };
            dispatch(changeNavIndex(2));
            dispatch(getMenuSuccess(menuDetails));
            dispatch(setWindowState(windowSize));

            const contactLinkId = paramsObj.k;
            if (!_isEmpty(contactLinkId)) {
                dispatch(getContactLinkById(contactLinkId))
                    .then(() => {
                        //Ref : https://github.com/ReactTraining/react-router/issues/3498
                        history.push(`/opt-out/${paramsObj.e}`);
                    }).catch(()=>{
                        dispatch(crudErrored(localString.emailOptInPreferance.changeEmailOptInPreferanceFailure));
                        history.push(`/opt-out/${paramsObj.e}`);
                    });
            } else {
                dispatch(crudErrored(localString.emailOptInPreferance.changeEmailOptInPreferanceFailure));
                history.push(`/opt-out/${paramsObj.e}`);
            }
        }
        if(callerHref_after_bcwidget.includes("v:l")) {
            // then goto live-chat
            //  console.log("going to live-chat...",history)
            //  console.log("widgetId in action menu",widgetId);
             dispatch(setWindowState(windowSize));
             history.push(`/chat/${widgetId}`);
        }
        if(callerHref_after_bcwidget.includes("v:r")){
            let paramsObj = expandParams(callerHref)
            // console.log("paramsObj",paramsObj);
            dispatch(setWindowState(windowSize));
            history.push(`/bid/${paramsObj.e}/shopService`);
        }
        if(callerHref_after_bcwidget.includes("v:s")){
            let paramsObj = expandParams(callerHref)
            // console.log("paramsObj",paramsObj);
            dispatch(setWindowState(windowSize));
            history.push(`/search/${paramsObj.e}`);
        }
    }
  };