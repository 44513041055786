import {
  findIndex as _findIndex,
  isEmpty as _isEmpty,
  update as _update
} from "lodash";
/**
 * Internal dependencies
 */
import { omit as _omit } from "lodash";
import { combineReducers, createReducer } from "../utils";
import {
  SET_HEADER_VISIBLE,
  SET_FOOTER_VISIBLE,
  SET_MENU_VISIBLE,
  JTWIDGET_CLEAR_RECORD,
  JTWIDGET_GET_SUCCESS,
  JTWIDGET_GET_ERRORED,
  WINDOW_STATE_CHANGE,
  WIDGET_ID_CHANGE,
  SERVICE_NAME_CHANGE,
  SERVICE_ID_CHANGE,
  FORM_DATA_CHANGED,
  WIDGET_OPERATION,
  FORM_SET_STEP_INDEX,
  FORM_SET_SAVE_ERROR,
  FORM_SET_BID_REQUEST_REFERENCE,
  FORM_ERRORS_CHANGED,
  SEARCH_SERVICE_SUCCESS,
  PROMOTE_SERVICE_SUCCESS,
  WIDGET_CRUD_ERRORED,
  WIDGET_CRUD_REQUESTED,
  JOURNEY_TEMPLATE_GET_SUCCESS,
  // URL_PARAMETERS_CHANGE,
  BID_CREATED_SUCCESS,
  BID_UPDATE_S3_UPLOAD_SUCCESS,
  BID_UPLOAD_PROGRESS,
  BID_PENDING_PROMISES,
  BID_UPLOAD_PROGRESSES_DATA,
  DEV_MODE_CHANGE,
  SET_SHOW_WINDOW_SIZE_BUTTON,
  SET_NATIVE_MODE,
  SET_CUSTOMER_GEO_LOCATION,
  SET_MULTIPLE_SERVICES,
  SET_CALL_HREF,
  STORE_DEVICE_PLATFORM,
  WIDGET_ENABLE_UI,
  CLEAR_JT_ELEMENTS,
  GET_JOURNEY_TEMPLATE_UI_SCHEMA_ELEMENTS_SUCCESS,
  AWSS3_UPLOAD_ERROR,
  STORE_AUTH_TOKEN,
  SET_LOCALSTORAGE_READ_4_PROPERTY,
  CLEAR_WIDGET_ELEMENTS,
  SHOP_SERVICE_DATA,
  SERVICE_DATA,
  HANDLE_NAVIGATION_INDEX,
  SET_SHOW_HOW_TO,
  SET_SHOW_SERVICE_AREA_SEARCH,
  SET_MAIN_SEARCH_AREA,
  SET_PROMOTED_SERVICE_AREA,
  SET_WIDGET_CUSTOM_MESSAGE_VISIBLE,
  SET_WIDGET_CUSTOM_MESSAGE,
  SET_WIDGET_PROMOTED_SERVICE_VIEW,
  SET_WIDGET_PROMOTED_SERVICE_TITLE_VISIBLE,
  SET_WIDGET_MAIN_SEARCH_TITLE_VISIBLE,
  SET_WIDGET_ON_OPEN_OPERATIONS,
  SET_WIDGET_PROMOTED_SHOP_TITLE_VISIBLE,
  SET_WIDGET_PROMOTED_SHOP_AREA_VISIBLE,
  SET_WIDGET_CAROSEL_SPEED_IN_MILLIS,
  BID_PATCH_PROMISES,
  SET_SERVICE_REQUEST_IMAGE_URL_AND_KEY,
  CLEAR_SERVICE_REQUEST_IMAGE_URL_AND_KEY,
  //DATA_READY_FOR_UPDATE
} from "../action-types";

const devModeConfig = createReducer(
  {},
  {
    [DEV_MODE_CHANGE]: (state, { args }) => ({ ...state, ...args })
  }
);

const items = createReducer([], {
  [JTWIDGET_GET_ERRORED]: (state, action) => [],
  [JTWIDGET_GET_SUCCESS]: (state, { items }) => items
  // [ JTWIDGET_CLEAR_RECORD ] : ( state, {items}  ) => [],
});

const recordIsLoading = createReducer(false, {
  [WIDGET_CRUD_REQUESTED]: () => true,
  [SEARCH_SERVICE_SUCCESS]: () => false,
  [PROMOTE_SERVICE_SUCCESS]:() =>false,
  [WIDGET_CRUD_ERRORED]: () => false,
  [JTWIDGET_GET_SUCCESS]: () => false,
  [JTWIDGET_CLEAR_RECORD]: () => false
});

const recordErrorMessage = createReducer(null, {
  [WIDGET_CRUD_ERRORED]: (state, { errorMessage }) => errorMessage,
  [WIDGET_CRUD_REQUESTED]: () => null,
  [SEARCH_SERVICE_SUCCESS]: () => null,
  [PROMOTE_SERVICE_SUCCESS]:() =>null,
  [JTWIDGET_GET_SUCCESS]: () => null
});

const widgetConfiguration = combineReducers({
  items,
  devModeConfig,
  isLoading: recordIsLoading,
  errorMessage: recordErrorMessage
});

const data = createReducer(
  {},
  {
    [JTWIDGET_GET_SUCCESS]: (state, { data }) => data,
    [JTWIDGET_CLEAR_RECORD]: (state, { data }) => null
  }
);

const widgetWindowStatus = createReducer("minimized", {
  [WINDOW_STATE_CHANGE]: (state, { windowState }) => windowState
});

const widgetEnableUI = createReducer(false, {
  [WIDGET_ENABLE_UI]: (state, { value }) => value,
})

const widgetId = createReducer(null, {
  [WIDGET_ID_CHANGE]: (state, { widgetId }) => widgetId
});

const serviceName = createReducer(null, {
  [SERVICE_NAME_CHANGE]: (state, { serviceName }) => serviceName,
  [JTWIDGET_CLEAR_RECORD]: (state, { serviceName }) => null
});

const serviceDetails = createReducer(
  {},
  {
    [SERVICE_ID_CHANGE]: (state, { serviceDetails }) => serviceDetails,
    [JTWIDGET_CLEAR_RECORD]: (state, { serviceDetails }) => null
  }
);

const shopServiceData = createReducer(
  {},
  {
    [SHOP_SERVICE_DATA]: (state, {shopServiceData})=> shopServiceData
  }
);

const formData = createReducer(
  {},
  {
    [FORM_DATA_CHANGED]: (state, { formData }) => formData
  }
);

const navigationIndex = createReducer(
  1,
  {
    [HANDLE_NAVIGATION_INDEX]: (state, { navigationIndex }) => navigationIndex
  }
);

const formErrors = createReducer([], {
  [FORM_ERRORS_CHANGED]: (state, { formErrors }) => formErrors,
  [JTWIDGET_CLEAR_RECORD]: (state, { formErrors }) => []
});

const stepIndex = createReducer(0, {
  [FORM_SET_STEP_INDEX]: (state, { stepIndex }) => stepIndex,
  [JTWIDGET_CLEAR_RECORD]: (state, { stepIndex }) => 0
});

const errorWhileSave = createReducer(null, {
  [FORM_SET_SAVE_ERROR]: (state, { errorMessage }) => errorMessage
});

const bidRequestReferenceNumber = createReducer(null, {
  [FORM_SET_BID_REQUEST_REFERENCE]: (state, { bidRequestReferenceNumber }) =>
    bidRequestReferenceNumber,
  [JTWIDGET_CLEAR_RECORD]: (state, { bidRequestReferenceNumber }) => null,
  [CLEAR_WIDGET_ELEMENTS]: (state, { bidRequestReferenceNumber }) => null
});

const matchedServices = createReducer([], {
  [SEARCH_SERVICE_SUCCESS]: (state, { matchedServices }) => matchedServices,
  [WIDGET_CRUD_ERRORED]: () => []
});

const promotedServices = createReducer([], {
  [PROMOTE_SERVICE_SUCCESS]: (state, { promotedServices }) => promotedServices,
  [WIDGET_CRUD_ERRORED]: () => []
}); 

const duplicatServices = createReducer([], {
  [SET_MULTIPLE_SERVICES]: (state, { services })=> services,
  [WIDGET_CRUD_ERRORED]: () => [],
  [CLEAR_WIDGET_ELEMENTS]: (state, { duplicatServices }) => []
});
// const urlParameters = createReducer( null, {
//   [ URL_PARAMETERS_CHANGE ]: ( state, {urlParameters} ) => urlParameters,
// } );

const journeyTemplateUiSchema = createReducer([], {
  [JOURNEY_TEMPLATE_GET_SUCCESS]: (state, { uiSchema }) => uiSchema,
  [CLEAR_JT_ELEMENTS]: (state, { data }) => null,
  [CLEAR_WIDGET_ELEMENTS]: (state, { journeyTemplateUiSchema }) => []
});

const journeyTemplateUiSchemaElements = createReducer([], {
  [GET_JOURNEY_TEMPLATE_UI_SCHEMA_ELEMENTS_SUCCESS]: (state, {journeyTemplateUISchemaElements}) => journeyTemplateUISchemaElements,
  [CLEAR_JT_ELEMENTS]: (state, { data }) => null,
  [CLEAR_WIDGET_ELEMENTS]: (state, { journeyTemplateUiSchemaElements }) => []
});

const journeyTemplateId = createReducer([], {
  [JOURNEY_TEMPLATE_GET_SUCCESS]: (state, { id }) => id,
  [CLEAR_WIDGET_ELEMENTS]: (state, { journeyTemplateId }) => []
});

const journeyTemplateVersion = createReducer([], {
  [JOURNEY_TEMPLATE_GET_SUCCESS]: (state, { version }) => version,
  [CLEAR_WIDGET_ELEMENTS]: (state, { journeyTemplateVersion }) => []
});

const bidId = createReducer(null, {
  [BID_CREATED_SUCCESS]: (state, { bidId }) => bidId,
  [CLEAR_WIDGET_ELEMENTS]: (state, { bidId }) => null
});

const operation = createReducer(null, {
  [WIDGET_OPERATION]: (state, config) => _omit(config, "type")
});

const s3UploadedData = createReducer([], {
  [BID_UPDATE_S3_UPLOAD_SUCCESS]: (state, { bidData }) => [...state, ...bidData],
  [CLEAR_WIDGET_ELEMENTS]: (state, { s3UploadedData }) => []
});

const s3UploadCounter = createReducer([], {
  [BID_UPLOAD_PROGRESS]: (state, { values, reset }) => {
    const keyValues = reset ? [] : [...state, ...values];
    return keyValues;
  },
  [CLEAR_WIDGET_ELEMENTS]: (state, { s3UploadCounter }) => []
});

const pendingPromises = createReducer([], {
  [BID_PENDING_PROMISES]: (state, { promises }) => promises,
  [CLEAR_WIDGET_ELEMENTS]: (state, { pendingPromises }) => []
});

const showWindowSizeButtons = createReducer(true, {
  [SET_SHOW_WINDOW_SIZE_BUTTON]: (state, { isShowButton }) => isShowButton
});

const isNativeModeOn = createReducer(false, {
  [SET_NATIVE_MODE] : (state, { isNativeModeOn }) => isNativeModeOn
});
 
const showHowTo = createReducer(true, {
  [SET_SHOW_HOW_TO]: (state, { isShowHowTo }) => isShowHowTo
});
const showServiceAreaSearch = createReducer(true, {
  [SET_SHOW_SERVICE_AREA_SEARCH]: (state, { showServiceAreaSearch }) => showServiceAreaSearch
});

const showMainSearchArea = createReducer(true, {
  [SET_MAIN_SEARCH_AREA]: (state, { showMainSearchArea }) => showMainSearchArea
});

const showPromotedServiceArea = createReducer(true, {
  [SET_PROMOTED_SERVICE_AREA]: (state, { showPromotedServiceArea }) => showPromotedServiceArea
});

const callHref = createReducer(null,{
[SET_CALL_HREF] : (state, { callHref }) => callHref
});

const devicePlatform = createReducer(null,{
  [STORE_DEVICE_PLATFORM] : (state, { platform }) => platform
});

const customerGeoLocation = createReducer( null, {
  [SET_CUSTOMER_GEO_LOCATION] : (state, { geoLocation }) => geoLocation
});

const uploadProgressesData = createReducer([], {
  [BID_UPLOAD_PROGRESSES_DATA]: (state, { name, value }) => {
    const foundIndex = _findIndex(state, v => v.name === name);
    const progresses =
      foundIndex >= 0 && !_isEmpty(state)
        ? _update(state, `${foundIndex}.value`, () => value)
        : [
            ...(_isEmpty(state) || (!name && !value) ? [] : state),
            { name, value }
          ];
    return !_isEmpty(progresses) && (!name && !value)
      ? []
      : !_isEmpty(progresses)
      ? [...progresses]
      : [...state];
  },
  [CLEAR_WIDGET_ELEMENTS]: (state, { uploadProgressesData }) => []
});

const awsS3UploadError = createReducer({},{
  [AWSS3_UPLOAD_ERROR] : (state,{uploadError}) => uploadError
})

const authToken = createReducer(null,{
  [STORE_AUTH_TOKEN]: (state,{authToken}) => authToken
})

const localStoragePendingReadMap = createReducer({},{
  [SET_LOCALSTORAGE_READ_4_PROPERTY] : (state, {localStoragePendingReadMap}) => localStoragePendingReadMap
})

const isHeaderVisible = createReducer(true, {
  [SET_HEADER_VISIBLE] : (state, { isHeaderVisible }) => isHeaderVisible
});

const customMessage = createReducer("", {
  [SET_WIDGET_CUSTOM_MESSAGE] : (state, { customMessage }) => customMessage
});

const isCustomMessageVisible = createReducer(false, {
  [SET_WIDGET_CUSTOM_MESSAGE_VISIBLE] : (state, { isCustomMessageVisible }) => isCustomMessageVisible
});

const promotedServiceView = createReducer("grid", {
  [SET_WIDGET_PROMOTED_SERVICE_VIEW] : (state, { promotedServiceView }) => promotedServiceView
});

const isPromotedServiceTitleVisible = createReducer(true, {
  [SET_WIDGET_PROMOTED_SERVICE_TITLE_VISIBLE] : (state, { isPromotedServiceTitleVisible }) => isPromotedServiceTitleVisible
});

const isPromotedShopTitleVisible = createReducer(true, {
  [SET_WIDGET_PROMOTED_SHOP_TITLE_VISIBLE] : (state, { isPromotedShopTitleVisible }) => isPromotedShopTitleVisible
});

const isPromotedShopAreaVisible = createReducer(true, {
  [SET_WIDGET_PROMOTED_SHOP_AREA_VISIBLE] : (state, { isPromotedShopAreaVisible }) => isPromotedShopAreaVisible
});

const isMainSearchAreaTitleVisible = createReducer(true, {
  [SET_WIDGET_MAIN_SEARCH_TITLE_VISIBLE] : (state, { isMainSearchAreaTitleVisible }) => isMainSearchAreaTitleVisible
});

const onOpenWidgetOperation = createReducer({}, {
  [SET_WIDGET_ON_OPEN_OPERATIONS] : (state, { onOpenWidgetOperation }) => onOpenWidgetOperation
});

const isFooterVisible = createReducer(true, {
  [SET_FOOTER_VISIBLE] : (state, { isFooterVisible }) => isFooterVisible
});

const isMenuVisible = createReducer(true, {
  [SET_MENU_VISIBLE] : (state, { isMenuVisible }) => isMenuVisible
});

const serviceInfo = createReducer(
  {},
  {
    [SERVICE_DATA]: (state, {serviceInfo})=> serviceInfo
  }
);
const bidPatchPromises = createReducer([],{
  [BID_PATCH_PROMISES] : (state,{patchPromises}) => patchPromises,
  [CLEAR_WIDGET_ELEMENTS]: (state, { patchPromises }) => []
})

const record = combineReducers({
  data,
  matchedServices,
  promotedServices,
  duplicatServices,
  isLoading: recordIsLoading,
  errorMessage: recordErrorMessage
});

const caroselSpeedInMillis = createReducer(2000, {
  [SET_WIDGET_CAROSEL_SPEED_IN_MILLIS] : (state, { caroselSpeedInMillis }) => caroselSpeedInMillis
});

const setImageAndKeyURL = createReducer([],{
  [SET_SERVICE_REQUEST_IMAGE_URL_AND_KEY] : (state, { response }) => [...state, ...response],
  [CLEAR_SERVICE_REQUEST_IMAGE_URL_AND_KEY] : ()=> []
})

export default combineReducers({
  operation,
  widgetConfiguration,
  navigationIndex,
  record,
  widgetWindowStatus,
  widgetId,
  serviceName,
  serviceDetails,
  formData,
  stepIndex,
  errorWhileSave,
  bidRequestReferenceNumber,
  formErrors,
  journeyTemplateId,
  journeyTemplateVersion,
  journeyTemplateUiSchema,
  journeyTemplateUiSchemaElements,
  bidId,
  s3UploadedData,
  s3UploadCounter,
  pendingPromises,
  uploadProgressesData,
  showWindowSizeButtons,
  showHowTo,
  showServiceAreaSearch,
  showMainSearchArea,
  showPromotedServiceArea,
  isNativeModeOn,
  callHref,
  devicePlatform,
  customerGeoLocation,
  widgetEnableUI,
  //  urlParameters,
  awsS3UploadError,
  authToken,
  customMessage,
  promotedServiceView,
  isCustomMessageVisible,
  isPromotedServiceTitleVisible,
  isMainSearchAreaTitleVisible,
  isPromotedShopAreaVisible,
  isPromotedShopTitleVisible,
  onOpenWidgetOperation,
  localStoragePendingReadMap,
  isHeaderVisible,
  isFooterVisible,
  isMenuVisible,
  serviceInfo,
  shopServiceData,
  caroselSpeedInMillis,
  bidPatchPromises,
  setImageAndKeyURL,
});
