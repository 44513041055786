import _map from "lodash/map";
import _compact from "lodash/compact"
import bcApi from '../lib/bidclips/index';

export const getCloudFrontUrl = (quillContent,width,height) => {
  console.log("current getCloudFrontUrl:::1",quillContent)
    if(quillContent && quillContent.ops){
    console.log("current getCloudFrontUrl:::2",quillContent.ops)
    quillContent.ops = _map(quillContent.ops,((ops) => {
      console.log("current getCloudFrontUrl:::3",ops)
      if(ops && ops.insert &&  ops.insert.imageannotation && ops.insert.imageannotation.image){

        const imagePath = ops.insert.imageannotation.image.replace('//','').match(/\/(.*)/g)
        if(imagePath !== null){
          if(width && height){
           ops.insert.imageannotation.image = bcApi.bidclips.aws.cloudFront + imagePath[0] + `_thumbnail_${width}x${height}.jpg`
          }
          else{
           ops.insert.imageannotation.image = bcApi.bidclips.aws.cloudFront + imagePath[0]
          }
        }
        

        return ops
      }else if(ops && ops.insert && ops.insert.image){
        console.log("current getCloudFrontUrl:::4",ops.insert.image)
              const imagePath = _compact(ops.insert.image.replace('//','').replace('/gets3content','').match(/\/(.*)/g))
              if(imagePath.length !== 0){
                if( width && height ){
                  ops.insert.image =  bcApi.bidclips.aws.cloudFront + imagePath[0] + `_thumbnail_${width}x${height}.jpg`
                }else{
                  ops.insert.image = bcApi.bidclips.aws.cloudFront + imagePath[0]
                }
                
              }
              return ops
    
      }else{
            return ops;
        }
    }))
  }
  console.log("Quill content after URI replace::",quillContent)
  return quillContent
}
export const cloudFrontURIImageURI = (imageURI,width,height)=> {
  const thumbnailAttributes = imageURI.match(/(?=_thumbnail_)(.*jpg)/g)
  const imagePath = imageURI.replace(thumbnailAttributes,"").replace('//','').match(/\/(.*)/g)
  if(imagePath !== null){
    if(width && height){
      imageURI = bcApi.bidclips.aws.cloudFront + imagePath[0] + `_thumbnail_${width}x${height}.jpg`

    }else{
      imageURI = bcApi.bidclips.aws.cloudFront + imagePath[0]

    }
  }
  return imageURI
}
  