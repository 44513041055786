/**
 * Internal dependencies
 */
import { combineReducers, createReducer } from '../utils';
import {
    JOB_GET_SUCCESS,
  } from '../action-types';

  const data = createReducer( {}, {
    [ JOB_GET_SUCCESS ]: ( state, {data}  ) => data,
  } );

  const record = combineReducers({
    data,
  });
   
  export default combineReducers( {
    record
  } );