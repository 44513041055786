import React, { Component } from "react";
import { get as _get } from "lodash";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { widgetClearRecord, changeNavIndex } from "../../state/widget/actions.js";
import {
  // take as _take,
  isEmpty as _isEmpty,
  map as _map,
  filter as _filter,
  eq as _eq,
  find as _find,
  isEqual as _isEqual,
  split as _split,
  // findIndex as _findIndex,  
} from "lodash";
import dsBridge from 'dsbridge';
import { clearRecord } from '../../state/bid/actions'
import Nav from '../ui-component/nav/nav'
import NavItem from '../ui-component/nav/navItem'
import NavLink2 from '../ui-component/nav/navLink'
import {NavDropdowns, NavDropdownItems} from '../ui-component/nav/navDropdown'
class Navigation extends Component {

  getActiveMenu = () =>
    _find(_get(this.props, "menuList", []), m =>
      _eq(_split(m.path,"/",2)[1], _split(window.location.pathname,"/",2)[1])
    );

  getOptionMenus = () =>
    _filter(
      _get(this.props, "menuList", []),
      m => !_eq(_split(m.path,"/",2)[1], _split(window.location.pathname,"/",2)[1])
    );

  onMeasureClick = (e) => {
    console.log("action_measure called");
    this.props.changeNavIndex(3)
    dsBridge.call("action_measure", {}, response => {
      console.log("action_measure testing ", response);
    });
    e.preventDefault();
  };

  render() {
    // const menu_list = _get(this.props, "menuList", []);
    const activeMenu = this.getActiveMenu();
    const moreMenuOptions = this.getOptionMenus();
    // const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
    // console.group("nav-form render");
    // console.log("Menu Props Values", this.props);
    // console.log('window.location.pathname :', window.location.pathname);
    // console.log('activeMenu :', activeMenu);
    // console.log('moreMenuOptions :', moreMenuOptions);
    !_isEmpty(activeMenu) && _isEqual(this.props.chatfeature,true) && moreMenuOptions.push({
      description: "Live Chat",
      path: `/chat/${_get(this.props,"widgetId")}`
    })
    // console.groupEnd("nav-form render");
    console.log(`this.props...`, this.props)
    if(_isEqual(_get(this.props,"enableUI",false),true)){
      return(
        <Nav className="justify-content-center" 
        activeKey="1" >
          <NavItem>
            <NavLink
              exact
              to="/mockup-ui/new-service/index"
              activeClassName="active"
              className="nav-link"
            >
              New Service
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              exact
              to="/mockup-ui/bid/index"
              activeClassName="active"
              className="nav-link"
            >
              Bid
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              exact
              to="/mockup-ui/chat/chat"
              activeClassName="active"
              className="nav-link"
            >
              Live Chat
            </NavLink>
          </NavItem>
          {/* <li>
            <NavLink
              exact
              to="/mockup-ui/bid2/index"
              activeClassName="active"
            >
              Bid2
            </NavLink>
          </li> */}
          {/* <li className="dropdown">
            <a
              href="/"
              className="dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              MockupUI <span className="caret" />
            </a>
            <ul
              className="dropdown-menu pull-right"
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                <NavLink
                  exact
                  to="/mockup-ui/payment/form"
                  activeClassName="active"
                >
                  Payment
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/refui/accordion" activeClassName="active">
                  Accordion Refrence
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/refui/communication-response/form">
                  Messages Response
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/refui/amount">
                  Amount Insurance
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/refui/serviceRequest/form">
                  Service Request
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/refui/schedule/form">
                  Schedule
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/refui/features">
                  Features
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/refui/addons">
                  Addons
                </NavLink>
              </li>
            </ul>
          </li> */}
        </Nav>
      );
    }else{
      return (
        <Nav className="justify-content-center" activeKey={_get(this.props,"navigationIndex")} 
        // onSelect={(selectedKey) => alert(`selected ${selectedKey}`)} 
        >
          {/* <NavItem>
            <NavLink2 eventKey="1" href="/">
              New Service
            </NavLink2>
          </NavItem>
          <NavItem>
            <NavLink2 eventKey="2" title="Item" href={`/chat/${this.props.widgetId}`}>
              Live Chat
            </NavLink2>
          </NavItem> */}
          <NavItem>
            <NavLink2 eventKey="1"
              // href="/"
              onSelect={()=> {
                this.props.widgetClearRecord();
                this.props.clearRecord();
                this.props.changeNavIndex(1)
                this.props.history.push(`/`)
              }}
            >
              New Service
            </NavLink2>
          </NavItem>
          {activeMenu && (
            <NavItem>
              <NavLink2 eventKey="2" onClick={()=>this.props.changeNavIndex(2)} to={activeMenu.path}>
                {activeMenu.description}
              </NavLink2>
            </NavItem>
          )}
          {(_isEmpty(activeMenu) && _isEqual(this.props.chatfeature,true)) &&
          <NavItem>
            <NavLink2 eventKey="2" title="Item" 
              // href={`/chat/${this.props.widgetId}`}
              onSelect={()=> {
                this.props.changeNavIndex(2)
                this.props.history.push(`/chat/${this.props.widgetId}`)
                }}
            >
              Live Chat
            </NavLink2>
          </NavItem>
          }
          { this.props.native && 
            <NavItem>
              <NavLink2 eventKey="3" onClick={this.onMeasureClick}>
                Measure
              </NavLink2>
            </NavItem>
          }
          {/* active menu link */}
          
          {!_isEmpty(moreMenuOptions) && (
            <NavDropdowns title="More" id="nav-dropdown">
              {_map(moreMenuOptions, (menuOption, idx) => (
                <NavDropdownItems key={idx} eventKey={`4.${idx}`}  
                onSelect={()=> {
                  this.props.changeNavIndex(2)
                  this.props.history.push(`${menuOption.path}`)
                  }}
                  >
                  {menuOption.description}
                </NavDropdownItems>
              ))}
            </NavDropdowns>
          )}
        </Nav>
        // <ul className="nav nav-tabs">
        //   <li>
        //     <NavLink exact to="/" activeClassName="active">
        //       New Service
        //     </NavLink>
        //   </li>
        //   <li>
        //     <NavLink
        //       exact
        //       to={`/chat/${this.props.widgetId}`}
        //       activeClassName="active"
        //     >
        //       Live Chat
        //     </NavLink>
        //   </li>
        //   { this.props.native && 
        //     <li>
        //       <span 
        //         className="span_like_a"
        //         onClick={this.onMeasureClick}>
        //           Measure
        //       </span>
        //     </li>
        //   }
        //   {activeMenu && (
        //     <li>
        //       <NavLink
        //         exact
        //         to={activeMenu.path}
        //         activeClassName="active"
        //       >
        //         {activeMenu.description}
        //       </NavLink>
        //     </li>
        //   )}
        //   {!_isEmpty(moreMenuOptions) ? (
        //     <li className="dropdown">
        //       <a
        //         href="/"
        //         className="dropdown-toggle"
        //         data-toggle="dropdown"
        //         aria-haspopup="true"
        //         aria-expanded="false"
        //       >
        //         More <span className="caret" />
        //       </a>
        //       <ul
        //         className="dropdown-menu pull-right"
        //         aria-labelledby="dropdownMenuButton"
        //       >
        //         {_map(moreMenuOptions, (menuOption, idx) => (
        //           <li key={idx}>
        //             <NavLink
        //               exact
        //               to={menuOption.path}
        //               activeClassName="active"
        //             >
        //               {menuOption.description}
        //             </NavLink>
        //           </li>
        //         ))}
        //       </ul>
        //     </li>
        //   ) : (
        //     ''
        //   )}
        // </ul>
      );
    }
  }
}

const mapStateToProps = (reduxState, ownProps) => {
  const native= _get(reduxState,"widget.isNativeModeOn",false)
  const newState = {
    enableUI: _get(reduxState,"widget.widgetEnableUI",false),
    menuList: _get(reduxState, "menu.list", {}),
    navigationIndex :_get(reduxState,"widget.navigationIndex",1),
    chatfeature: _get(reduxState, "widget.widgetConfiguration.items.chatfeature",false),
    widgetId: ownProps.widgetId,
    native
  };
  console.log("In menu", reduxState, newState, ownProps);
  return newState;
};

const mapDispatchToProps = dispatch => {
  return {
    widgetClearRecord: () => dispatch(widgetClearRecord()),
    changeNavIndex: (index) => dispatch(changeNavIndex(index)),
    clearRecord: () => dispatch(clearRecord()),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Navigation)
);
